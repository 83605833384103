import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { generateHSL } from "@/composable/generateColor"

@Module
export default class PartnersModule extends VuexModule {


  partnersPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  partnersFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  get getPartnersPagination() {
    // //console.log("getter 2", this.partnersPagination)
    return this.partnersPagination;
}

  partner = null
  partners = null
  allPartners = null

  actualPartner = {
    id: null
  }

  /* --- GETTERS --- */

 
  get getPartners() {
    // //console.log("getter partners", this.partners)
    return this.partners;
}
 
 
  get getAllPartners() {
    // //console.log("getter partners", this.partners)
    return this.allPartners;
}
 
  get getPartner() {
    // //console.log("getter partners", this.partners)
    return this.partner;
}

get getPartnersFilters() {
  return this.partnersFilters;
}
get getActualPartner() {
  // //console.log('ACTUALPARTNER',this.actualPartner)
  return this.actualPartner;
}

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_PARTNERS](payload) {
    try {
      // //console.log('partners mutation',payload);
      // const dataWithColors = payload.data.map(partner => {
      //   // partner.departments.forEach(department => {
      //   //   const color = generateHSL(department.name);
      //   //   department.color = color;
      //   // });
      //   return partner
      // })
      // //console.log('dataqieh',dataWithColors);
      // this.partners = {
      //   ...payload,
      //  // data: dataWithColors,
      // }
      this.partners = payload

    } catch (error) {
      //console.log(error);
      
    }
    
  }

  @Mutation
  [Mutations.SET_ALL_PARTNERS](payload) {
    try {
      this.allPartners = payload

    } catch (error) {
      //console.log(error);
      
    }
    
  }

  @Mutation
  [Mutations.SET_PARTNERS_PAGINATION]({pagination, loading, error}) {
    // //console.log('mutation_pagination',payload);

    // const dataWithColors = pagination.data.map(partner => {
    //   partner.departments.forEach(department => {
    //     const color = generateHSL(department.name);
    //     department.color = color;
    //   });
    //   return partner
    // })
    // const newPagination = {
    //   ...pagination,
    //   data: dataWithColors,
    // };


    this.partnersPagination = {
      pagination: pagination,
      loading,
      error
    };
  }
  
  @Mutation
  [Mutations.SET_PARTNER_BY_ID](payload) {
    // //console.log('mutation',payload);
     try {
    //   const departmentsWithColors = payload.departments.map(department => {
    //     const color = generateHSL(department.name);
    //     department.color = color;
    //     return department
    //   });
      
    //   //console.log('departmetnssssss',departmentsWithColors)
    
    this.partner = payload
    // this.partner = {
    //   ...payload,
    //   departments: departmentsWithColors
    
    } catch (error) {
      //console.log(error);
      
    }
    
  }

  @Mutation
  [Mutations.FILTER_PARTNERS](payload) {
    //console.log('FILTERmutation',payload);
    this.partnersFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_PARTNERS_FILTERS]() {
    this.partnersFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Mutation
  [Mutations.SET_LOADING_TRUE_PARTNERS](payload) {
    //console.log('mutation',payload);
    this.partnersPagination.loading = true;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_PARTNERS](payload) {
    this.partnersFilters.page = payload;
  }



  
  /* --- ACTIONS --- */



  @Action
  [Actions.GET_PARTNERS]() {
    return ApiService.get(
      `partners?page=${this.partnersFilters.page}&search=${this.partnersFilters.search}${this.partnersFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('partnersACTION',data);
        this.context.commit(Mutations.SET_PARTNERS, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }


  @Action
  [Actions.GET_PARTNERS_WITH_PAGINATION]() {
    return ApiService.get(
      `partners?page=${this.partnersFilters.page}&search=${this.partnersFilters.search}${this.partnersFilters.searchString}`
    )
    .then(({ data }) => {
      // //console.log(data);
      this.context.commit(Mutations.SET_PARTNERS_PAGINATION, {
        pagination: data,
        loading: false,
        error: null,
      })
    })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ALL_PARTNERS]() {
    return ApiService.get(
      `allpartners`
    )
    .then(({ data }) => {
      // //console.log(data);
      this.context.commit(Mutations.SET_ALL_PARTNERS, {data})
    })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_PARTNERS_TO_DOWNLOAD]() {
    return new Promise((resolve, reject) => {
    return ApiService.get(
      `allpartners?search=${this.partnersFilters.search}${this.partnersFilters.searchString}`
    )
    .then(({ data }) => {
      // //console.log(data);
      resolve(data)
    })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  
  @Action
  [Actions.GET_PARTNER_BY_ID](payload) {
    return ApiService.get(`partners/${payload}`)
      .then(({ data }) => {
        //console.log(data);
        this.context.commit(Mutations.SET_PARTNER_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_OBSERVATION_PARTNER](payload) {
    const {id, observation} = payload;
    //console.log('payloadI',id)
    //console.log('payloadO',observation)
    return new Promise((resolve, reject) => {
      ApiService.put(`/partners/observation/${id}`, payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.DELETE_OBSERVATION_PARTNER](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/partners/deleteobservation/${id}`,payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_EXPEDIENT_PARTNERS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/partners/allcustomerfiles/${payload}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action
  [Actions.CREATE_PARTNER](payload) {

    //console.log(payload);
    return new Promise((resolve, reject) => {
     ApiService.post(`partners`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_PARTNERS_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }


  
  @Action({ rawError: true })
  [Actions.FILTER_PARTNERS](payload) {
    this.context.commit(Mutations.FILTER_PARTNERS, payload);
    this.context.dispatch(Actions.GET_PARTNERS_WITH_PAGINATION)
  }

  @Action
  [Actions.CLEAR_PARTNERS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_PARTNERS_FILTERS, payload);
    this.context.dispatch(Actions.GET_PARTNERS_WITH_PAGINATION) //;
  }

  @Action
  [Actions.SET_LOADING_TRUE_PARTNERS]() {
    this.context.commit(Mutations.SET_LOADING_TRUE_PARTNERS);
  }

  
  @Action
  [Actions.DELETE_PARTNER](payload) {
    return ApiService.delete(`partners/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_PARTNERS_WITH_PAGINATION);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_PARTNERS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_PARTNERS, payload.page);
    this.context.dispatch(payload.getAction);
  }

  
  @Action({ rawError: true })
  [Actions.PUT_PARTNER](payload) {
    const {id, partner} = payload;
    //console.log(partner);
    return new Promise((resolve, reject) => {
      return ApiService.put(`partners/${id}`, partner)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_PARTNERS_WITH_PAGINATION)
          resolve(data)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response)
        });
    })
  }
  

}