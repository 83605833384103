import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class TaskTypeModule extends VuexModule implements StoreInfo {

  tasksPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  tasksFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  tasks = null
  tag = null
  allTags = null

  /* --- GETTERS --- */

  get getAllTasksType() {
    // //console.log("getter tasks", this.tasks)
    return this.allTags;
  }


  get getTasksType() {
    // //console.log("getter tasks", this.tasks)
    return this.tasks;
  }


  get getTaskType() {
    ////console.log("TASKSTYPE", this.tag)
    return this.tag;
  }

  get getTasksTypePagination() {
      // //console.log("getter 2", this.tasksPagination)
      return this.tasksPagination;
  }

  get getTasksTypeFilters() {
    return this.tasksFilters;
  }

  get getPageTasksType() {
    return this.tasksFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_TASKSTYPE_PAGINATION]({pagination, loading, error}) {
    // //console.log('departments mutation',payload);
    this.tasksPagination = {
      pagination: pagination,
      loading,
      error
    };
  }


  @Mutation
  [Mutations.SET_PAGE_PAGINATION_TASKSTYPE](payload) {
    this.tasksFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_TASKSTYPE](payload) {
    ////console.log('mutation',payload);
    this.tasks = payload;
  }

  @Mutation
  [Mutations.SET_TASKSTYPE_BY_ID](payload) {
    ////console.log('mutation',payload);
    this.tag = payload;
  }

  @Mutation
  [Mutations.SET_ALL_TASKSTYPE](payload) {
    ////console.log('mutation',payload);
    this.allTags = payload;
  }

  @Mutation
  [Mutations.SEARCH_TASKSTYPE]({payload, pagination, loading, error}) {
    ////console.log('mutation',payload);
    ////console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((tag: any) => tag.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.tasksPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_TASKSTYPE](payload) {
    this.tasksFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_TASKSTYPE_FILTERS](payload) {
    this.tasksFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }



  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_TASKSTYPE](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_TASKSTYPE, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.SEARCH_TASKSTYPE](payload) {
    ApiService.get(`tasktype`)
      .then(({ data }) => {
        this.context.commit(Mutations.SEARCH_TASKSTYPE, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_ALL_TASKSTYPE](payload) {
    ApiService.get(`tasktypes/getallrecords`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_TASKSTYPE, 
         data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_TASKSTYPE](payload) {
    this.context.commit(Mutations.FILTER_TASKSTYPE, payload);
    this.context.dispatch(Actions.GET_TASKSTYPE_WITH_PAGINATION)
  }


  @Action
  [Actions.GET_TASKSTYPE]() {
    // //console.log(this.tasksFilters.searchString)
    return ApiService.get(
      `tasktypes`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_TASKSTYPE, data)
        this.context.commit(Mutations.SET_TASKSTYPE, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TASKSTYPE_WITH_PAGINATION]() {
    // //console.log(this.tasksFilters.searchString)
    return ApiService.get(
      `tasktypes?page=${this.tasksFilters.page}&search=${this.tasksFilters.search}${this.tasksFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_TASKSTYPE, data)
        this.context.commit(Mutations.SET_TASKSTYPE_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TASKSTYPE_BY_ID](payload) {
    //console.log(this.tasksFilters.searchString)
    return ApiService.get(`tasks/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TASKSTYPE_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_TASKSTYPE](payload) {
    const {id, tag} = payload;

    return ApiService.put(`tasktypes/${id}`, tag)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_TASKSTYPE_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_TASKSTYPE](payload) {

    return new Promise((resolve, reject) => {
      //console.log('crear tag', payload)
     ApiService.post(`tasktypes`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_TASKSTYPE_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_TASKSTYPE](payload) {
    return ApiService.delete(`tasktypes/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_TASKSTYPE_WITH_PAGINATION);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_TASKSTYPE_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_TASKSTYPE_FILTERS, payload);
    this.context.dispatch(Actions.GET_TASKSTYPE);
  }

}
