import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { languages, countries } from "@/data/db.js"


@Module
export default class RolesModule extends VuexModule {

  roles = null
  permissions = {};
  editting = null; 

  rolesPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  rolesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  /* --- GETTERS --- */

  get getRolesPagination() {
    return this.rolesPagination;
  }

  get getPageRoles() {
    return this.rolesFilters.page;
  }

  get getRolesFilters() {
    return this.rolesFilters;
  }
  get getRoles() {
    return this.roles;
  }

  get getPermissions() {
    return this.permissions;
  }

  get getEdittinMode() {
    return this.editting;
  }


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_ROLES](payload) {
    // //console.log('roles mutation',payload);
    this.roles = payload;
  }

  @Mutation
  [Mutations.SET_PERMISSIONS](payload) {

   ////console.log('payload', payload)
    // Estructura en object
    let section = payload.section;
    let panel   = payload.panel; 
    let name    = payload.name;
    let value   = payload.checked;
    
    var size = Object.keys(this.permissions).length;
    ////console.log('size', size)

    if(size == 0){
    this.permissions = payload
    }
    // this.permissions[panel] = {...this.permissions[panel]}
    // this.permissions[panel][section] = {...this.permissions[panel][section]}
    
    // Borro la clave
    // if(value === false){
    //   delete this.permissions[panel][section][name]; 
    // }else{
      this.permissions[panel][section][name] = value

      // //console.log('this.permissions ', this.permissions)

    //}
   
    ////console.log(payload);
    ////console.log(this.permissions);
  }

  @Mutation
  [Mutations.CLONE_PERMISSIONS](payload) {
    this.permissions = payload
    ////console.log('mutation ', payload)
  }

  @Mutation
  [Mutations.CLEAR_ROLES_FILTERS](payload) {
    this.rolesFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Mutation
  [Mutations.CLEAR_ROLES]() {
    this.permissions = {}
    ////console.log('permissions : ',this.permissions)
  }

  @Mutation
  [Mutations.SET_ROLES_PAGINATION]({pagination, loading, error}) {
    this.rolesPagination = {
      pagination: pagination,
      loading,
      error
    };
  }
  
  @Mutation
  [Mutations.SET_PAGE_PAGINATION_ROLES](payload) {
    this.rolesFilters.page = payload;
  }

  @Mutation
  [Mutations.EDITTING_PERMISSIONS](payload) {
    this.editting = payload;
  }
  
  

  @Action
  [Actions.SET_PAGE_PAGINATIONS_ROLES](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_ROLES, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action
  [Actions.CLONE_PERMISSIONS](payload) {
    this.context.commit(Mutations.CLONE_PERMISSIONS, payload)
    //this.permissions = {...payload}
    //this.permissions = payload
    ////console.log('action', payload, this.permissions)

  }

  @Action
  [Actions.PUT_ROL](payload) {
    const {id, rol} = payload;

    return ApiService.put(`roles/${id}`, rol)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_ROLES_WITH_PAGINATION)
        this.context.commit(Mutations.CLEAR_ROLES);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.EDITTING_PERMISSIONS](payload){
    this.context.commit(Mutations.EDITTING_PERMISSIONS, payload);
  }



  @Action
  [Actions.GET_ROLES_WITH_PAGINATION]() {
    // //console.log(this.tagsFilters.searchString)
    return ApiService.get(
      `roles?page=${this.rolesFilters.page}&search=${this.rolesFilters.search}${this.rolesFilters.searchString}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLES_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ROLES]() {
    return ApiService.get(
      `roles`
    )
      .then(({ data }) => {
        // //console.log('rolesACTION',data);
        this.context.commit(Mutations.SET_ROLES, data)
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        ////console.log('error', response)
      });
  }

  @Action
  [Actions.GET_PERMISSIONS]() {
    //this.context.commit(Mutations.SET_PERMISSIONS)
    ////console.log(this.permissions);
    //return this.permissions;

    return ApiService.get(
      `permissions`
    )
      .then(({ data }) => {
         ////console.log('permissions ',data);
        this.context.commit(Mutations.SET_PERMISSIONS, data.data[0])
      })
      .catch(({ response }) => {
        ////console.log('error', response)
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_PERMISSION_BY_ID](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.get(
      `permissions`
    )
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
      });
    });
  }

  @Action
  [Actions.SET_PERMISSIONS](data) {

    //console.log('action',data)
    this.context.commit(Mutations.SET_PERMISSIONS, data)
    // return ApiService.get(
    //   `roles`
    // )
    //   .then(({ data }) => {
    //     // //console.log('rolesACTION',data);
    //     this.context.commit(Mutations.SET_PERMISSIONS, data)
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });
  }

  @Action
  [Actions.CREATE_ROLE](payload) {

    //console.log(payload);
    return new Promise((resolve, reject) => {
      ApiService.post(`roles`, payload)
        .then(({ data }) => {
          resolve(data)
          ////console.log('store gooood', data);
          this.context.dispatch(Actions.GET_ROLES_WITH_PAGINATION)
          this.context.commit(Mutations.CLEAR_ROLES);

        })
        .catch(({ response }) => {
          reject(response.data.errors);
          ////console.log('store error', response);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    });
  }

  @Action
  [Actions.DELETE_ROL](payload) {
    return ApiService.delete(`roles/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_ROLES_WITH_PAGINATION);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_ROLES](payload) {
    this.context.dispatch(Mutations.CLEAR_ROLES);
  }


  @Action
  [Actions.CLEAR_ROLES_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_ROLES_FILTERS, payload);
    this.context.dispatch(Actions.GET_ROLES_WITH_PAGINATION);
  }




}