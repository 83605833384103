import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import { string } from "yup/lib/locale";

interface StoreInfo {}

@Module
export default class CurrenciesModule extends VuexModule implements StoreInfo {
  contacts = null;
  contactsToDownload = null;
  customerfiles = null;

  customer = {};
  customerFile = {};

  lead = {};

  customerFilesPartnerPagination = {
    pagination: null,
    loading: true,
    error: null,
  }
  customerFilesPagination = {
    pagination: null,
    loading: true,
    error: null,
  };



  contactsPagination = {
    pagination: null,
    loading: true,
    error: null,
  };

  clientsPagination = {
    pagination: null,
    loading: true,
    error: null,
  };

  contactsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  customerFilesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  

  clientsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  internUsers = [];

  payments = [];

  allTaskTypes = [];
  taskTypes = [];

  associateds = [];

  /* --- GETTERS --- */

  get getInternUsers() {
    return this.internUsers;
  }


  get getContacts(): any {
    return this.contactsPagination;
  }

  get getContactsToDownload(): any {
    return this.contactsToDownload;
  }

  get getContactsBy(): any {
    return this.contacts;
  }

  get getContactsDownload(): any {
    return this.contacts;
  }

  get getCustomerFilesBy(): any {
    return this.customerfiles;
  }

  get getCustomerFiles(): any {
    return this.customerFilesPagination;
  }

  
  get getClients(): any {
    return this.clientsPagination;
  }

  get getContact(): any {
    return this.lead;
  }

  // get getCustomer(): any {
  //   return this.customer;
  // }

  get getCustomerFile(): any {
    return this.customerFile;
  }

  get getContactsPagination() {
    return this.contactsPagination;
  }

  get getClientsPagination() {
    // //console.log('kk',this.clientsPagination);
    return this.clientsPagination;
  }

  get getCustomerFilesPagination() {
    // //console.log('kk',this.clientsPagination);
    return this.clientsPagination;
  }

  get getContactsFilters() {
    return this.contactsFilters;
  }

  get getCustomerFilesFilters() {
    return this.customerFilesFilters;
  }

  get getPageCustomerFilesFilters() {
    return this.customerFilesFilters.page;
  }

  get getClientsFilters() {
    return this.clientsFilters;
  }

  get getPageContacts() {
    return this.contactsFilters.page;
  }

  get getPageClients() {
    return this.customerFilesFilters.page;
  }

  get getPayments() {
    return this.payments;
  }

  get getTaskTypes() {
    return this.taskTypes;
  }

  get getAllTaskTypes() {
    return this.allTaskTypes;
  }

  get getAssociateds() {
    return this.associateds;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_CONTACTS_PAGINATION](payload) {
    this.contactsPagination = payload;
  }

  @Mutation
  [Mutations.SET_CONTACTS](payload) {
    this.contacts = payload;
  }

  @Mutation
  [Mutations.SET_CONTACTS_TO_DOWNLOAD](payload) {
    this.contactsToDownload = payload;
  }

  @Mutation
  [Mutations.SET_CLIENTS](payload) {
    this.customerfiles = payload;
  }

  @Mutation
  [Mutations.SET_CONTACTS_BY_MANAGERCTI](payload) {
    this.contacts = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERFILES_BY_OWNER](payload) {
    this.customerfiles = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERFILES_PAGINATION](payload) {
    //console.log('SET_CUSTOMERFILES_PAGINATION', payload)
    this.customerFilesPagination = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERFILES_PARTNER_PAGINATION](payload) {
    //console.log('SET_CUSTOMERFILES_PARTNER_PAGINATION', payload)
    this.customerFilesPagination = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERFILES_MEDICAL_CENTER_PAGINATION](payload) {
    //console.log('SET_CUSTOMERFILES_PARTNER_PAGINATION', payload)
    this.customerFilesPagination = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERFILES_COLLABORATOR_PAGINATION](payload) {
    //console.log('SET_CUSTOMERFILES_PARTNER_PAGINATION', payload)
    this.customerFilesPagination = payload;
  }

  @Mutation
  [Mutations.SET_CLIENTS_PAGINATION](payload) {
    this.clientsPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_CONTACTS](payload) {
    this.contactsFilters.page = payload;
  }

  

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_PARTNER](payload) {
    this.customerFilesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_MEDICAL_CENTER](payload) {
    this.customerFilesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_COLLABORATOR](payload) {
    this.customerFilesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_CLIENTS](payload) {
    this.customerFilesFilters.page = payload;
  }

  

  // @Mutation
  // [Mutations.SET_CUSTOMER](payload) {
  //   // //console.log('module',payload)
  //   this.customer = payload;
  // }

  @Mutation
  [Mutations.SET_CUSTOMERFILE](payload) {
    // //console.log('module',payload)
    this.customerFile = payload;
  }

  @Mutation
  [Mutations.SET_LEAD](payload) {
    // //console.log('module',payload)
    this.lead = payload;
  }

  @Mutation
  [Mutations.SET_ASSOCIATEDS](payload) {
    // //console.log('module',payload)
    this.associateds = payload;
  }

  @Mutation
  [Mutations.SET_INTERN_USERS](payload) {
    // //console.log(payload);
    this.internUsers = payload;
  }

  @Mutation 
  [Mutations.SET_CUSTOMERFILES](payload) {
    this.customerFilesPagination = payload
  }

  @Mutation
  [Mutations.SET_PAYMENTS](payload) {
    this.payments = payload;
  }

  @Mutation
  [Mutations.SET_TASKTYPES](payload) {
    this.taskTypes = payload;
  }

  @Mutation
  [Mutations.SET_ALL_TASKTYPES](payload) {
    this.allTaskTypes = payload;
  }

  @Mutation
  [Mutations.CLEAR_CONTACTS_FILTERS](payload) {
    this.contactsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Mutation
  [Mutations.SET_LOADING_TRUE_CONTACTS](payload) {
    // //console.log('mutation',payload);
    this.contactsPagination.loading = true;
  }

  @Mutation
  [Mutations.SET_LOADING_TRUE_CUSTOMERFILES](payload) {
    // //console.log('mutation',payload);
    this.customerFilesPagination.loading = true;
  }

  @Mutation
  [Mutations.FILTER_CONTACTS](payload) {
    // //console.log('FILTERmutation',payload);
    this.contactsFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_CUSTOMERFILES](payload) {
    // //console.log('FILTERmutation',payload);
    this.customerFilesFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_CUSTOMERFILES_PARTNER](payload) {
    // //console.log('FILTERmutation',payload);
    this.customerFilesFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_CUSTOMERFILES_MEDICAL_CENTER](payload) {
    // //console.log('FILTERmutation',payload);
    this.customerFilesFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_CUSTOMERFILES_COLLABORATOR](payload) {
    // //console.log('FILTERmutation',payload);
    this.customerFilesFilters.searchString = payload
  }

 

  @Mutation
  [Mutations.CLEAR_CLIENTS_FILTERS](payload) {
    this.clientsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  /* --- ACTIONS --- */

  @Action({ rawError: true })
  [Actions.GET_CONTACTS](payload) {
    return new Promise((resolve, reject) => {
    this.contactsPagination.loading = true;
     return ApiService.get(
      `leads?page=${this.contactsFilters.page}&search=${this.contactsFilters.search}${this.contactsFilters.searchString}`
      // `leads`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        this.context.commit(Mutations.SET_CONTACTS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_CONTACTS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    this.contactsPagination.loading = true;
     return ApiService.get(
      `leads/getallrecords?search=${this.contactsFilters.search}${this.contactsFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        this.context.commit(Mutations.SET_CONTACTS_TO_DOWNLOAD, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_CLIENTS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    this.clientsPagination.loading = true;
     return ApiService.get(
      `customerfiles/getallrecords?search=${this.clientsFilters.search}${this.clientsFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_CLIENTS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_CONTACTS_BY_MANAGERCTI](payload) {
    return new Promise((resolve, reject) => {
    this.contactsPagination.loading = true;
     return ApiService.get(
      `leads/managercti/${payload}?search=${this.contactsFilters.search}${this.contactsFilters.searchString}`
      // `leads`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        this.context.commit(Mutations.SET_CONTACTS_BY_MANAGERCTI, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_CUSTOMERFILES_BY_OWNER](payload) {
    return new Promise((resolve, reject) => {
    this.clientsPagination.loading = true;
     return ApiService.get(
      `customerfiles/owner/${payload}?search=${this.customerFilesFilters.search}${this.customerFilesFilters.searchString}`
      // `leads`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        this.context.commit(Mutations.SET_CUSTOMERFILES_BY_OWNER, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action({ rawError: true })
  [Actions.GET_CUSTOMERFILES](payload) {
    this.clientsPagination.loading = true;
    return ApiService.get(
      `customerfiles?limit=10&page=${this.customerFilesFilters.page}&search=${this.customerFilesFilters.search}${this.customerFilesFilters.searchString}`
      // `customerfiles`
    )
      .then(({ data }) => {
      
        this.context.commit(Mutations.SET_CLIENTS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }



  @Action({ rawError: true })
  [Actions.GET_CUSTOMERFILES_PARTNER](payload) {
    this.clientsPagination.loading = true;
    //console.log('payload', payload)
   
    return ApiService.get(
      `partners/customerfiles/${payload.id}?limit=10&page=${this.customerFilesFilters.page}&search=${this.customerFilesFilters.search}${this.customerFilesFilters.searchString}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMERFILES_PARTNER_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });


       
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_CUSTOMERFILES_MEDICAL_CENTER](payload) {
    this.clientsPagination.loading = true;
    //console.log('payload', payload)
   
    return ApiService.get(
      `healthcares/customerfiles/${payload.id}?limit=10&page=${this.customerFilesFilters.page}&search=${this.customerFilesFilters.search}${this.customerFilesFilters.searchString}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMERFILES_MEDICAL_CENTER_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });


       
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_CUSTOMERFILES_COLLABORATOR](payload) {
    this.clientsPagination.loading = true;
    //console.log('payload', payload)
   
    return ApiService.get(
      `collaborators/customerfiles/${payload.id}?limit=10&page=${this.customerFilesFilters.page}&search=${this.customerFilesFilters.search}${this.customerFilesFilters.searchString}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMERFILES_COLLABORATOR_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });


       
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_CLIENTS](payload) {
    this.clientsPagination.loading = true;
    return ApiService.get(
      `customerfiles?limit=10&page=${this.clientsFilters.page}&search=${this.clientsFilters.search}${this.clientsFilters.searchString}`
      // `customerfiles`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_CONTACTS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_CONTACTS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_PARTNER](payload) {
    //console.log('SET_PAGE_PAGINATIONS_PARTNER',payload.page)
    this.context.commit(Mutations.SET_PAGE_PAGINATIONS_PARTNER, payload.page);
   // this.context.dispatch(payload.getAction);
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_MEDICAL_CENTER](payload) {
    //console.log('SET_PAGE_PAGINATIONS_MEDICAL_CENTER',payload.page)
    this.context.commit(Mutations.SET_PAGE_PAGINATIONS_MEDICAL_CENTER, payload.page);
   // this.context.dispatch(payload.getAction);
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_COLLABORATOR](payload) {
    //console.log('SET_PAGE_PAGINATIONS_COLLABORATOR',payload.page)
    this.context.commit(Mutations.SET_PAGE_PAGINATIONS_COLLABORATOR, payload.page);
   // this.context.dispatch(payload.getAction);
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_CLIENTS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_CLIENTS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
    
    //console.log('SET_PAGE_PAGINATIONS_CLIENTS',payload.getAction)
  }



  @Action
  [Actions.SET_PAGE_PAGINATIONS_USERS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_USERS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }

  // pertenece y existe en el modulo customers
  // @Action
  // [Actions.GET_CUSTOMER_BY_ID](payload) {
  //   return ApiService.get(`customers/${payload}`)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.SET_CUSTOMER, data);
  //       return data
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //     });
  // }

  @Action
  [Actions.GET_LEAD_BY_ID](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.get(`contact/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LEAD, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    });
  }

  @Action
  [Actions.GET_ASSOCIATEDS](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.get(`lead/associatedid/${payload}`)
      .then(({ data }) => {
        resolve(data)
        this.context.commit(Mutations.SET_ASSOCIATEDS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  
  @Action({ rawError: true })
  [Actions.FILTER_CONTACTS](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_CONTACTS, payload);
    this.context.dispatch(Actions.GET_CONTACTS)
  }
  
  @Action({ rawError: true })
  [Actions.FILTER_CUSTOMERFILES](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_CUSTOMERFILES, payload);
    this.context.dispatch(Actions.GET_CUSTOMERFILES,payload)
  }

  @Action({ rawError: true })
  [Actions.FILTER_CUSTOMERFILES_PARTNER](payload) {
    //console.log('FILTER_CUSTOMERFILES_PARTNER',payload)
    this.context.commit(Mutations.FILTER_CUSTOMERFILES_PARTNER, payload.string);
    this.context.dispatch(Actions.GET_CUSTOMERFILES_PARTNER, payload)

  }

  @Action({ rawError: true })
  [Actions.FILTER_CUSTOMERFILES_MEDICAL_CENTER](payload) {
    //console.log('FILTER_CUSTOMERFILES_MEDICAL_CENTER',payload)
    this.context.commit(Mutations.FILTER_CUSTOMERFILES_MEDICAL_CENTER, payload.string);
    this.context.dispatch(Actions.GET_CUSTOMERFILES_MEDICAL_CENTER, payload)

  }

  @Action({ rawError: true })
  [Actions.FILTER_CUSTOMERFILES_COLLABORATOR](payload) {
    //console.log('FILTER_CUSTOMERFILES_MEDICAL_CENTER',payload)
    this.context.commit(Mutations.FILTER_CUSTOMERFILES_COLLABORATOR, payload.string);
    this.context.dispatch(Actions.GET_CUSTOMERFILES_COLLABORATOR, payload)

  }

  @Action
  [Actions.SET_LOADING_TRUE_CONTACTS]() {
    this.context.commit(Mutations.SET_LOADING_TRUE_CONTACTS);
  }

  @Action
  [Actions.SET_LOADING_TRUE_CUSTOMERFILES]() {
    this.context.commit(Mutations.SET_LOADING_TRUE_CUSTOMERFILES);
  }

  @Action
  [Actions.GET_CUSTOMERFILES_BY_ID](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.get(`customerfiles/${payload}`)
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_CUSTOMERFILE, data);
        resolve(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_INTERN_USERS](payload) {
    return ApiService.get(`internUsers`)
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_INTERN_USERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  

  @Action({ rawError: true })
  [Actions.UPDATE_STATUS](payload) {
    const {id, status} = payload;
    //console.log('payload',id)
    //console.log('payload',status)
    return new Promise((resolve, reject) => {
      ApiService.put(`/lead/setstatus/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_MASSIVE_LEAD](payload) {
    const {id, ...rest} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/leads/masive/${id}`, rest)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.SIGN_VALIDATE_LEAD](payload) {
    const {id, designa} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/leads/signvalidate/${id}`, designa)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors); 
        });
    });
  }

  
  @Action({ rawError: true })
  [Actions.UPDATE_TAG](payload) {
    const {id, tag} = payload;
    //console.log('payload',id)
    //console.log('payload',tag)
    return new Promise((resolve, reject) => {
      ApiService.put(`/lead/tag/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  

  
  @Action({ rawError: true })
  [Actions.UPDATE_TAG_MASSIVE](payload) {
    const {id, tag} = payload;
    // //console.log('payload',id)
    // //console.log('payload',tag)
    return new Promise((resolve, reject) => {
      ApiService.put(`/lead/tag/${id}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  
  @Action({ rawError: true })
  [Actions.UPDATE_OBSERVATION_LEAD](payload) {
    const {id, observation} = payload;
    //console.log('payload',id)
    return new Promise((resolve, reject) => {
      ApiService.put(`/lead/observation/${id}`, payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  
  @Action({ rawError: true })
  [Actions.DELETE_OBSERVATION_LEAD](payload) {
    const {id} = payload;
    //console.log('payload',id)
    return new Promise((resolve, reject) => {
      ApiService.put(`/lead/deleteobservation/${id}`,payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  
  @Action({ rawError: true })
  [Actions.UPDATE_OBSERVATION_CUSTOMERFILES](payload) {
    const {id, observation} = payload;
    //console.log('payload',id)
    //console.log('payload',observation)
    return new Promise((resolve, reject) => {
      ApiService.put(`/customerfiles/observation/${id}`, payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

    
  @Action({ rawError: true })
  [Actions.DELETE_OBSERVATION_CUSTOMERFILES](payload) {
    const {id} = payload;
    //console.log('payload',id)
    return new Promise((resolve, reject) => {
      ApiService.put(`/customerfiles/deleteobservation/${id}`,payload)
        .then(({ data }) => {
          // this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action({ rawError: true })
  [Actions.UPDATE_CUSTOMERFILE](payload) {
    const {id, status} = payload
    // //console.log(payload)
    return new Promise((resolve, reject) => {
      ApiService.put(`customerfiles/${id}`, payload )
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CUSTOMERFILES);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_STATUS_LEGAL](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/legalStatus`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_LEAD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`leads/newlead`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.UPDATE_OWNER](payload) {
    return ApiService.put(`customerfiles/owner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CLIENTS);
        //this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COLLABORATOR](payload) {
    return ApiService.put(`customerfiles/collaborator`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.customerFileId);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.ADD_PAYMENT](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.put(`customerfiles/paymentonaccount/${payload.id}`,payload  )
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

  @Action
  [Actions.UPDATE_LEAD_OWNER](payload) {
    return ApiService.put(`lead/owner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_LEAD_MANAGER_CTI](payload) {
    return ApiService.put(`lead/managercti`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_LEAD_MANAGER_CTI_MASSIVE](payload) {
    return ApiService.put(`lead/managercti`, payload)
      .then(({ data }) => {
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_OWNER_PARTNER](payload) {
    return ApiService.post(`lead/ownerPartner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_LEAD_PARTNER](payload) {
    //console.log('update',payload)
    return ApiService.put(`lead/partner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PARTNER](payload) {
    //console.log('update',payload)
    return ApiService.put(`customerfiles/partner`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CONTACTS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SEND_CUSTOM_SMS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`sms/custom`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.SEND_SIGN_TRAFICO](payload, _id) {
    return new Promise((resolve, reject) => {
      ApiService.post(`customerfiles/sendsigntrafico`, payload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.SEND_DESIGNA_SMS](payload, _id) {
    const { customerId, ...restPayload } = payload
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/designaSMS`, restPayload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_CUSTOMER_BY_ID, customerId);
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.SEND_SEPA](payload, _id) {
    const { customerId, ...restPayload } = payload
    return new Promise((resolve, reject) => {
      ApiService.post(`customerfiles/sendsepa`, restPayload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_CUSTOMER_BY_ID, customerId);
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  
  @Action({ rawError: true })
  [Actions.GET_DESIGNA_CONTACT](payload) {
    return new Promise((resolve, reject) => {
     return ApiService.get(
      `leads/designa-document/${payload}`
      // `leads`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }


  @Action
  [Actions.UPDATE_CONTACT](payload) {
    const { id, customer } = payload
    return new Promise((resolve, reject) => {
      ApiService.put(`customers/${id}`, customer)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CUSTOMERS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.UPDATE_AUDIT_DATA](payload) {
    const { id, data } = payload
    return new Promise((resolve, reject) => {
      ApiService.put(`customerfiles/auditinformation/${id}`, data)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.UPDATE_LEAD_OPPOSITE](payload) {
    const { id, opposite } = payload
    return new Promise((resolve, reject) => {
      ApiService.put(`lead/opposite/${id}`, opposite)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action
  [Actions.UPDATE_CUSTOMERFILES_OPPOSITE](payload) {
    const { id, opposite } = payload
    return new Promise((resolve, reject) => {
      ApiService.put(`customerfiles/opposite/${id}`, opposite)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  
  @Action
  [Actions.UPDATE_LEAD_PROCESS](payload) {
    const { id, process } = payload
    const processToPass = {process: process}
    return new Promise((resolve, reject) => {
      ApiService.put(`lead/process/${id}`, processToPass as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  
  @Action
  [Actions.UPDATE_LEAD_QUIZ](payload) {
    const { id, quiz } = payload
    return new Promise((resolve, reject) => {
      ApiService.put(`leads/quiz/${id}`, quiz )
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_LEAD_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  

  @Action
  [Actions.UPDATE_CUSTOMERFILES_PROCESS](payload) {
    const { id, process } = payload
    const processToPass = {process: process}
    return new Promise((resolve, reject) => {
      ApiService.put(`customerfiles/process/${id}`, processToPass as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
  

  @Action
  [Actions.UPDATE_DATOS_LEAD](payload) {
    // const processToPass = {process: process}
    return new Promise((resolve, reject) => {
      ApiService.put(`leads/data/${payload.id}`, {data :payload.data})
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_LEAD_BY_ID, payload.id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action
  [Actions.CREATE_ORDER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/create`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.GET_PAYMENTS_BY_ID](payload) {
    return ApiService.get(`payments`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PAYMENTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CUSTOMERFILE_BY_LEAD_ID](payload) {
    //console.log('payload',payload);
    return new Promise((resolve, reject) => {
    return ApiService.get(`/customerfiles/byleadid/${payload}`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
      });
    })
  }

  @Action
  [Actions.GET_TASKTYPES](payload) {
    return ApiService.get(`tasktypes`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TASKTYPES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ALL_TASKTYPES](payload) {
    return ApiService.get(`tasktypes/getallrecords`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_TASKTYPES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }


  @Action({ rawError: true })
  [Actions.SEND_AUDIT_DERIVATION](payload) {
    return new Promise((resolve, reject) => {

    ApiService.post(`customerfiles/sendaudit/${payload.id}`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        reject(response)
      });
    })
  }


  @Action({ rawError: true })
  [Actions.SEND_RP](payload) {

    console.log(payload)
    return new Promise((resolve, reject) => {


    ApiService.post(`customerfiles/sendrp/${payload.id}`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        reject(response)
      });
    })
  }

  //PUT /customerfiles/reiterationrp/{id} <------ _ID del expediente

  @Action({ rawError: true })
  [Actions.SEND_RRP](payload) {

    console.log(payload)
    return new Promise((resolve, reject) => {


    ApiService.put(`customerfiles/reiterationrp/${payload.id}`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.id);
        reject(response)
      });
    })
  }


  @Action({ rawError: true })
  [Actions.CANCEL_AUDIT](payload) {
    ApiService.put(`/customerfiles/cancelaudit/${payload}`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.SET_AUDIT_DATE](payload) {
    ApiService.put(`customerfiles/dateaudit/${payload.id}`, payload)
      .then(({ data }) => {
        //console.log(data);
        this.context.dispatch(Actions.GET_CUSTOMERFILES);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_CONTACTS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_CONTACTS_FILTERS, payload);
    // this.context.dispatch(Actions.GET_CONTACTS);
  }

  @Action
  [Actions.CLEAR_CLIENTS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_CLIENTS_FILTERS, payload);
  }

  @Action
  [Actions.CREATE_CONTACT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.CREATE_ASSOCIATED_CONTACT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`lead/associatelead/${payload.associedId}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_ASSOCIATEDS, payload.numId);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.DELETE_LEAD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`lead/delete/${payload}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CONTACTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.DELETE_CUSTOMERFILES](payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`customerfiles/delete/${payload}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_CLIENTS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.RESEND_PAYLINK](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/resendLink`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }
}
