import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class CustomersModule extends VuexModule implements StoreInfo {

  customersPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  customersFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  filters ={
    filter: "",
    word:""
  }
  
  customers = null
  customer = null


  /* --- GETTERS --- */


  get getCustomers() {
    return this.customers;
  }


  // get getCustomer() {
  //   //console.log("USSSSERRRR GETTTERR", this.customer)
  //   return this.customer;
  // }

  get getCustomersPagination() {
      // //console.log("getter 2", this.customersPagination)
      return this.customersPagination;
  }

  get getCustomersFilters() {
    return this.customersFilters;
  }
  get getCustomerFilters() {
    return this.filters;
  }

  get getPageCustomers() {
    return this.customersFilters.page;
  }

  get getCustomer(): any {
    return this.customer;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_CUSTOMERS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.customersPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_CUSTOMERS](payload) {
    this.customersFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](payload) {
    // //console.log('mutation',payload);
    this.customers = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_BY_ID](payload) {
    // //console.log('mutation',payload);
    this.customer = payload;
  }


  @Mutation
  [Mutations.SEARCH_CUSTOMERS]({payload, pagination, loading, error}) {
    //console.log('mutation',payload);
    //console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((customer: any) => customer.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.customersPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_CUSTOMERS](payload) {
    // //console.log('FILTERmutation',payload);
    this.customersFilters.searchString = payload
  }

  @Mutation
  [Mutations.SET_CUSTOMER](payload) {
    // //console.log('module',payload)
    this.customer = payload;
  }

  @Mutation
  [Mutations.CLEAR_CUSTOMERS_FILTERS](payload) {
    this.customersFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  // @Mutation
  // [Mutations.DELETE_CUSTOMER](payload) {
  //   this.customersPagination.pagination = this.customersPagination.pagination.filter((customer: any) => customer._id !== payload)
  //   //console.log('deletemutation', payload)
  // }


  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_CUSTOMERS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_CUSTOMERS, payload.page);
    this.context.dispatch(payload.getAction);
  }



  @Action({ rawError: true })
  [Actions.SEARCH_CUSTOMERS](payload) {
    ApiService.get(`customers`)
      .then(({ data }) => {
        //console.log(data);
        this.context.commit(Mutations.SEARCH_CUSTOMERS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_CUSTOMERS](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_CUSTOMERS, payload);
    this.context.dispatch(Actions.GET_CUSTOMERS)
  }



  @Action
  [Actions.GET_CUSTOMERS]() {
    // //console.log(this.customersFilters.searchString)
    return ApiService.get(
      `customers?page=${this.customersFilters.page}&search=${this.customersFilters.search}${this.customersFilters.searchString}`
      // `customers`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_CUSTOMERS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_CUSTOMER_BY_ID](payload) {
    return new Promise((resolve, reject) => {
    return ApiService.get(`customers/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_BY_ID, data)
        this.context.commit(Mutations.SET_CUSTOMER, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

  @Action
  [Actions.PUT_CUSTOMER](payload) {
    const {id, customer} = payload;
    //console.log(customer);

    return ApiService.put(`customers/${id}`, customer)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CUSTOMERS)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_CUSTOMER](payload) {

    //console.log(payload);
    return new Promise((resolve, reject) => {
     ApiService.post(`customers`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_CUSTOMERS)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_CUSTOMER](payload) {
    return ApiService.delete(`customers/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_CUSTOMERS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_CUSTOMERS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_CUSTOMERS_FILTERS, payload);
    this.context.dispatch(Actions.GET_CUSTOMERS);
  }

  
  @Action
  [Actions.SET_LOADING_TRUE_CUSTOMERS]() {
    this.context.commit(Mutations.SET_LOADING_TRUE_CUSTOMERS);
  }

  // @Action
  // [Actions.CREATE_CONTACT](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`lead`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }

  // @Action
  // [Actions.RESEND_PAYLINK](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`orders/resendLink`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }
}
