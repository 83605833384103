import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class BlockedIpsModule extends VuexModule implements StoreInfo {

  blockedIpsPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  blockedIpsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  blockedIps = null

  /* --- GETTERS --- */


  get getBlockedIps() {
    return this.blockedIps;
  }

  get getBlockedIpsPagination() {
      // //console.log("getter 2", this.blockedIpsPagination)
      return this.blockedIpsPagination;
  }

  get getBlockedIpsFilters() {
    return this.blockedIpsFilters;
  }

  get getPageBlockedIps() {
    return this.blockedIpsFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_BLOCKEDIPS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.blockedIpsPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_BLOCKEDIPS](payload) {
    this.blockedIpsFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_BLOCKEDIPS](payload) {
    //console.log('mutation',payload);
    this.blockedIps = payload;
  }

  @Mutation
  [Mutations.SEARCH_BLOCKEDIPS]({payload, pagination, loading, error}) {
    //console.log('mutation',payload);
    //console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((blockedIp: any) => blockedIp.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.blockedIpsPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_BLOCKEDIPS](payload) {
    //console.log('FILTERmutation',payload);
    this.blockedIpsFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_BLOCKEDIPS_FILTERS](payload) {
    this.blockedIpsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  // @Mutation
  // [Mutations.DELETE_BLOCKEDIP](payload) {
  //   this.blockedIpsPagination.pagination = this.blockedIpsPagination.pagination.filter((blockedIp: any) => blockedIp._id !== payload)
  //   //console.log('deletemutation', payload)
  // }


  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_BLOCKEDIPS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_BLOCKEDIPS, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.SEARCH_BLOCKEDIPS](payload) {
    ApiService.get(`blockedIps`)
      .then(({ data }) => {
        //console.log(data);
        this.context.commit(Mutations.SEARCH_BLOCKEDIPS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_BLOCKEDIPS](payload) {
    this.context.commit(Mutations.FILTER_BLOCKEDIPS, payload);
    this.context.dispatch(Actions.GET_BLOCKEDIPS)
  }

//   @Action
//   [Actions.GET_CONTACT_BY_ID](payload) {
//     return ApiService.get(`contact`, payload)
//       .then(({ data }) => {
//         this.context.commit(Mutations.SET_CONTACT, data);
//       })
//       .catch(({ response }) => {
//         this.context.commit(Mutations.SET_ERROR, response.data.errors);
//       });
//   }

  @Action
  [Actions.GET_BLOCKEDIPS]() {
    // //console.log(this.blockedIpsFilters.searchString)
    return ApiService.get(
      `blockedIps?page=${this.blockedIpsFilters.page}&search=${this.blockedIpsFilters.search}${this.blockedIpsFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_BLOCKEDIPS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_BLOCKEDIP](payload) {

    //console.log(payload);
    return new Promise((resolve, reject) => {
     ApiService.post(`blockedIps`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_BLOCKEDIPS)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_BLOCKEDIP](payload) {
    return ApiService.delete(`blockedIps/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_BLOCKEDIPS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_BLOCKEDIPS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_BLOCKEDIPS_FILTERS, payload);
    this.context.dispatch(Actions.GET_BLOCKEDIPS);
  }

}
