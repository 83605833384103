import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { findNested, user } from '@/core/helpers/permission';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },

      {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/views/TableContacts.vue"),
        meta: {
          pageTitle: "Consultas",
          breadcrumbs: ["Consultas"],
        },
      },
      {
        path: "/contacts/:id/",
        name: "contact-profile",
        component: () => import("@/views/ContactProfile.vue"),
        meta: {
          pageTitle: "Contact Profile",
          breadcrumbs: ["Contact Profile"],
        },
      },
      {
        path: "/customerFiles/:id/",
        name: "customerfiles-profile",
        component: () => import("@/views/ContactProfile.vue"),
        meta: {
          pageTitle: "CustomerFileProfile",
          breadcrumbs: ["CustomerFileProfile"],
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/UsersView.vue"),
        meta: {
          pageTitle: "Users",
          breadcrumbs: ["Users"],
        },
      },
      {
        path: "/users/:id/",
        name: "user-profile",
        component: () => import("@/views/UserProfile.vue"),
        meta: {
          pageTitle: "User Profile",
          breadcrumbs: ["Users", "User Profile"],
        },
      },
      {
        path: "/partners",
        name: "partners",
        component: () => import("@/views/PartnersView.vue"),
        meta: {
          pageTitle: "Partners",
          breadcrumbs: ["Partners"],
        },
      },
      {
        path: "/partners/:id",
        name: "partnersProfile",
        component: () => import("@/views/PartnersProfileView.vue"),
        meta: {
          pageTitle: "Partner profile",
          breadcrumbs: ["Partners", "Partner Profile"],
        },

      },


      {
        path: "/medical-centers",
        name: "medical-centers",
        component: () => import("@/views/MedicalCentersView.vue"),
        meta: {
          pageTitle: "Medical Centers",
          breadcrumbs: ["Medical Centers"],
        },
      },
      {
        path: "/medical-centers/:id/",
        name: "medical-center-profile",
        component: () => import("@/views/MedicalCenterProfile.vue"),
        meta: {
          pageTitle: "Medical Center Profile",
          breadcrumbs: ["Medical Centers", "Medical Center Profile"],
        },
      },
      // Desguaces
      {
        path: "/collaborators",
        name: "collaborators",
        component: () => import("@/views/CollaboratorsView.vue"),
        meta: {
          pageTitle: "collaborators",
          breadcrumbs: ["collaborators"],
        },
      },
      {
        path: "/collaborators/:name/:id/",
        name: "collaborators-profile",
        component: () => import("@/views/CollaboratorProfile.vue"),
        meta: {
          pageTitle: "Colaborators Profile",
          breadcrumbs: ["Colaborators", "Colaborator Profile"],
        },
      },
      {
        path: "/collaborators/name/:name",
        name: "collaborators-list",
        component: () => import("@/views/CollaboratorsView.vue"),
        meta: {
          pageTitle: "collaborators",
          breadcrumbs: ["collaborators"],
        },
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/CustomersView.vue"),
        meta: {
          pageTitle: "Customers",
          breadcrumbs: ["Customers"],
        },
      },
      {
        path: "/expedients",
        name: "expedients",
        component: () => import("@/views/TableClients.vue"),
        meta: {
          pageTitle: "Expedients",
          breadcrumbs: ["Expedients"],
        },
      },
      // {
      //   path: "/expedients/:id/",
      //   name: "expedient-profile",
      //   component: () => import("@/views/ClientProfile.vue"),
      //   meta: {
      //     pageTitle: "Expedient Profile",
      //     breadcrumbs: ["Expedients", "Expedient Profile"],
      //   },
      // },
      {
        path: "/tasks",
        name: "tasks",
        component: () => import("@/views/TableTasks.vue"),
        meta: {
          pageTitle: "Tasks",
          breadcrumbs: ["Tasks"],
        },
      },
    
      {
        path: "/audits",
        name: "audits",
        component: () => import("@/views/TableAudits.vue"),
        meta: {
          pageTitle: "audits",
          breadcrumbs: ["audits"],
        },
      },

      {
        path: "/atestados",
        name: "atestados",
        component: () => import("@/views/TableAtestado.vue"),
        meta: {
          pageTitle: "atestados",
          breadcrumbs: ["atestados"],
        },
      },

      {
        path: "/derivationsst",
        name: "derivacionesst",
        component: () => import("@/views/TableDerivacionesST.vue"),
        meta: {
          pageTitle: "derivacionesst",
          breadcrumbs: ["derivacionesst"],
        },
      },
      {
        path: "/derivationscm",
        name: "derivacionescm",
        component: () => import("@/views/TableDerivacionesCM.vue"),
        meta: {
          pageTitle: "derivacionescm",
          breadcrumbs: ["derivacionescm"],
        },
      },

      {
        path: "/billing",
        name: "billing",
        component: () => import("@/views/TableBilling.vue"),
        meta: {
          pageTitle: "Billing",
          breadcrumbs: ["BillingB2C"],
        },
      },

      {
        path: "/billingb2b",
        name: "billingb2b",
        component: () => import("@/views/TableBillingb2b.vue"),
        meta: {
          pageTitle: "Billing",
          breadcrumbs: ["BillingB2B"],
        },
      },

      // Tags
      {
        path: "/tags",
        name: "tags",
        component: () => import("@/views/TagsView.vue"),
        meta: {
          pageTitle: "Tags",
          breadcrumbs: ["Tags"],
        },
      },
      // TaskType
      {
        path: "/tasktype",
        name: "tasktype",
        component: () => import("@/views/TaskTypeView.vue"),
        meta: {
          pageTitle: "TaskType",
          breadcrumbs: ["TaskType"],
        },
      },

        // ActionType
        {
          path: "/actiontype",
          name: "actiontype",
          component: () => import("@/views/ActionTypeView.vue"),
          meta: {
            pageTitle: "ActionType",
            breadcrumbs: ["ActionType"],
          },
        },
      // Status
      {
        path: "/status",
        name: "status",
        component: () => import("@/views/StatusView.vue"),
        meta: {
          pageTitle: "Status",
          breadcrumbs: ["Status"],
        },
      },
      // Roles table
      {
        path: "/roles",
        name: "roles",
        component: () => import("@/views/RolesView.vue"),
        meta: {
          pageTitle: "Roles",
          breadcrumbs: ["Roles"],
        },
      },

      {
        path: "/departments",
        name: "departments",
        component: () => import("@/views/DepartmentsView.vue"),
        meta: {
          pageTitle: "Departments",
          breadcrumbs: ["Departments"],
        },
      },
      {
        path: "/services",
        name: "services",
        component: () => import("@/views/ServicesView.vue"),
        meta: {
          pageTitle: "Services",
          breadcrumbs: ["Services"],
        },
      },
      // {
      //   path: "/blockedips",
      //   name: "blockedips",
      //   component: () => import("@/views/BlockedIpsView.vue"),
      //   meta: {
      //     pageTitle: "Blocked IPs",
      //     breadcrumbs: ["IPs"],
      //   },
      // },
      // {
      //   path: "/tags/:id/",
      //   name: "tags-profile",
      //   component: () => import("@/views/TagsProfile.vue"),
      //   meta: {
      //     pageTitle: "Tags Profile",
      //     breadcrumbs: ["Tags", "Tags Profile"],
      //   },
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/google-callback",
        name: "google-callback",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/GoogleCallback.vue"
          ),
        meta: {
          pageTitle: "Redirecting...",
        },
      },
      {
        path: "/microsoft-callback",
        name: "microsoft-callback",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/MicrosoftCallback.vue"
          ),
        meta: {
          pageTitle: "Redirecting...",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/change-password/:id/:token",
        name: "change-password",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ChangePassword.vue"),
        meta: {
          pageTitle: "Change Password",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeEach((to, from, next) => {

  // //console.log('here ',  user.value?.role?.modulePermissions?.menu?.rutas, to.name)
  // let res =  findNested(user.value?.role?.modulePermissions?.menu?.rutas, to.name, false)
  // //console.log('res', res)
  // if(res  || user.value?.role?.modulePermissions?.menu?.rutas != to.name  || to.name == 'sign-in' || res == 'undefined'){
  //   next({ name: "sign-in" });
  //   return;
  // }

  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      next();
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
