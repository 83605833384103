import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class StatusModule extends VuexModule implements StoreInfo {

  statusesPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  statusesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  statuses = null
  allStatuses = null
  // scrapyard = null
  status = null


  /* --- GETTERS --- */


  get getStatuses() {
    return this.statuses;
  }

  get getAllStatuses() {
    return this.allStatuses;
  }


  get getStatus() {
    ////console.log("USSSSERRRR GETTTERR", this.status)
    return this.status;
  }

  get getStatusesPagination() {
      // //console.log("getter 2", this.tagsPagination)
      return this.statusesPagination;
  }

  get getStatusesFilters() {
    return this.statusesFilters;
  }

  get getPageStatuses() {
    return this.statusesFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_STATUSES_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.statusesPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_STATUSES](payload) {
    this.statusesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_STATUSES](payload) {
    ////console.log('mutation',payload);
    this.statuses = payload;
  }

  @Mutation
  [Mutations.SET_ALL_STATUSES](payload) {
    ////console.log('mutation',payload);
    this.allStatuses = payload;
  }

  @Mutation
  [Mutations.SET_STATUS_BY_ID](payload) {
    ////console.log('mutation',payload);
    this.status = payload;
  }

  @Mutation
  [Mutations.SEARCH_STATUSES]({payload, pagination, loading, error}) {
    ////console.log('mutation',payload);
    ////console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((status: any) => status.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.statusesPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_STATUSES](payload) {
    ////console.log('FILTERmutation',payload);
    this.statusesFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_STATUSES_FILTERS](payload) {
    this.statusesFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  // @Mutation
  // [Mutations.DELETE_SCRAPYARD](payload) {
  //   this.tagsPagination.pagination = this.tagsPagination.pagination.filter((scrapyard: any) => scrapyard._id !== payload)
  //   //console.log('deletemutation', payload)
  // }


  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_STATUSES](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_STATUSES, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.SEARCH_STATUSES](payload) {
    ApiService.get(`status`)
      .then(({ data }) => {
        this.context.commit(Mutations.SEARCH_STATUSES, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_STATUSES](payload) {
    this.context.commit(Mutations.FILTER_STATUSES, payload);
    this.context.dispatch(Actions.GET_STATUSES)
  }

//   @Action
//   [Actions.GET_CONTACT_BY_ID](payload) {
//     return ApiService.get(`contact`, payload)
//       .then(({ data }) => {
//         this.context.commit(Mutations.SET_CONTACT, data);
//       })
//       .catch(({ response }) => {
//         this.context.commit(Mutations.SET_ERROR, response.data.errors);
//       });
//   }

  @Action
  [Actions.GET_STATUSES]() {
    // //console.log(this.tagsFilters.searchString)
    return ApiService.get(
      `status?page=${this.statusesFilters.page}&search=${this.statusesFilters.search}${this.statusesFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_STATUSES, data)
        this.context.commit(Mutations.SET_STATUSES_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ALL_STATUSES]() {
    // //console.log(this.tagsFilters.searchString)
    return ApiService.get(`allstatus`)
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_ALL_STATUSES, {data})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_STATUS_BY_ID](payload) {
    //console.log(this.statusesFilters.searchString)
    return ApiService.get(`status/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STATUS_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_STATUS](payload) {
    const {id, status} = payload;

    return ApiService.put(`status/${id}`, status)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_STATUSES)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_STATUS](payload) {

    return new Promise((resolve, reject) => {
     ApiService.post(`status`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_STATUSES)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_STATUS](payload) {
    return ApiService.delete(`status/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_STATUSES);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_STATUSES_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_STATUSES_FILTERS, payload);
    this.context.dispatch(Actions.GET_STATUSES);
  }

  // @Action
  // [Actions.CREATE_CONTACT](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`lead`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }

  // @Action
  // [Actions.RESEND_PAYLINK](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`orders/resendLink`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }
}
