import { createI18n } from "vue-i18n";



const messages = {
  en: {
    tab_expedients : 'Expedients',
    tab_users : 'Users',
    tab_documents : 'Documents',
    tag_contact: 'Tag Consulta',
    condition: 'Condition',
    injuries: 'Injuries',
    rehabilitationDays: 'Rehabilitation Days',
    hospitalDays: 'Hospital Days',
    outWorkDays: 'Out of Work Days',
    refSinister: 'Sinister Reference',
    oppositeFault: 'Opposite Fault',
    coResponsibility: 'Co-Responsibility',
    existAtestado: 'Exist Atestado',
    friendlyReport: 'Friendly Report',
    workClearance: 'Work Clearance',
    medicalClearance: 'Medical Clearance',
    estimatedDaysOff: 'Estimated Days Off',
    rehabilitation: 'Rehabilitation',
    operated: 'Operated',
    futureOperation: 'Future Operation',
    motivatedOffer: 'Motivated Offer',
    import: 'import',
    contactWithCIA: 'Contact With CIA',
    medicalVisitCIA: 'Medical Visit CIA',
    processorAcceptPD: 'Processor Accept PD',
    processorEmail: 'Processor Email',
    processorExtension: 'Processor Extension',
    processorPhone: 'Processor Phone',
    processor: 'Processor',
    sinisterVehicle: 'Sinister Vehicle',
    estimatedDaysHospital: 'Estimated Hospital Days',
    userValoration: 'User Valoration',
    lawyerCIA: 'Lawyer CIA',
    privateLawyer: 'Private Lawyer',
    requestPermission: 'Request Permission',
    previousLawyerName: 'Previous Lawyer Name',
    previousLawyerEmail: 'Previous Lawyer Email',
    lossOfProfit: 'Loss of profit',
    policyNumber: 'Policy Number',
    insuredEnrolment: 'Insured Enrolment',
    legalDefense: 'Legal Defense',
    legalDefenseImport: 'Legal Defense Import',
    colisionType: 'Colision Type',
    validateSign: 'Sign Validate',
    Validate: 'Validate',
    tag_cti: 'Tag CTI',
    change_fra_tag: 'Change Tag',
    total: 'Total',
    FRAnum: 'FRA Num',
    billing_b2b: 'Billing B2B',
    billing_b2c: 'Billing B2C',
    partnerProfile : 'Partner profile',
    export: 'Export',
    sendLinkDoc : 'Send DOC Link',
    Link : 'Link',
    BillingB2C : 'Bill B2C',
    BillingB2B : 'Bill B2B',
    CtiPartner :  'CTI/Partner',
    massiveAssignManagerCTI : 'Massive assign Manager CTI',
    Assign:  'Assign',
    massiveChange : 'Massive complete task',
    only_manager_cti  : 'View Only Assigned to me',
    only_owner : 'View Only Assigned to me',
    only_mine: 'Assigned to me only',
    Assigned: "Assigned",
    Changed: "Changed",
    Changeds: "Changeds",
    allowedTypes : 'Departments',
    language: 'Language',
    upBillDate : 'Up Date',
    billDate : 'Bill Date',
    suppliedType : 'Supplied type',
    supplied_type : 'Supplied type',
    lsupplied : 'I. Supplied',
    lindemnization : 'I. Indemnization',
    lminuta : 'I. Minuta',
    tagFRA : 'FRA Tag',
    serie: 'Serie',
    FRA_type :  'FRA Type',
    FRAtype :  'FRA Type',

    created_date_at : 'Task Date',
    massiveComplete : 'Massive done',
    massiveReasign : 'Massive reasign',
    realized_at : 'Realized date',
    completedTasks:'Done',
    scheduledTask:'Scheduled',
    pendingTask:'Pending',
    overdueTasks:'Overdue',
    Message:'Message',
    Author:'Author',
    change_task : 'Change task status ?',
    assigned: 'Assigned',
    priority: 'Priority',
    observation: 'Observation',
    Previsualization : 'Previsualization',
    documentsPending : 'DOC',
    chooseJuzgado : 'Choose juzdged',
    Compañías : 'Companies',
   change_tag_cti : 'Change Tag CTI',
   change_tag_partner : 'Change Tag Partner',
   change_status_legal : 'Change Status Legal',
   update_owner: 'Update Owner',
   update_partner: 'Update Partner',
   customerFiles_details : 'Expedients details',
 
    tasks : 'Tasks',
    
    fiscalName : 'Fiscal name',
    comercialName : 'Comercial name',
    serieFRA: 'FRA Series',
    Taxes: 'Aplicable taxes',
    judgedType : 'Court type',
    editJuzgado: 'Edit judged',
    editContrario: 'Edit oppositor',
    tagPartner : "Tag Partner", 
    DateAsigned : "Assigned date",
    lead_details : 'Lead details',
    change_tag: 'Change tag',
    change_status : 'Change status',
    send_SMS : 'Send SMS',
    editClient : 'Edit client',
    actuations : 'Actuations',
    send_signature : 'Send signature',
    generateOrder : 'Generate Order',
    associate_contact : 'Associate contact',
    change_managerCTI : 'Assign Manager CTI',
    createActuation : 'Create actuation',
    createTaskActuation : 'Create task actuation',
    createTask : 'Create task',
    completeTask : 'Complete task',
    completeTasks : 'Complete',
    moreInfo : 'More information',
    tab_feed: 'Feed',
    tab_actuations : 'Actuations',
    tab_task: 'Task',
    tab_data: 'Data',
    tab_billing : 'Billing',
    tab_Associated : 'Show Associated',
    tab_payment: 'Payment',

    tag_updated : "Tag assigned",

    dont_have_permissions: 'Don`t have enought permissions', 
    Send_SMS: 'Send SMS',
    associate: 'Asociar ',
    managerCTI: 'Manager CTI',
    name_and_surname: 'Name and Surname',
    Yes: 'Yes',
    No: 'No',
    Select_a_date : 'Select a date', 
    Fail_msg: 'Failed to continue',
    Error: 'Failed', 
    Send_signature : 'Send signature',
    TaskStatus: "Task Status",
    relatedUserId: "Assigned",
    Associate_contact : 'Associate contact', 
    Associate_new_contact : 'Associate new contact',
    go_back: "Go back",
    Search_partners: 'Search partners',
    succesfull: "Succesfull",
    status_updated : "Status updated",
    partner_updated: "Partner updated",
    owner_updated : "Owner updated",
    Partner_no_assign : 'No assign',
    Search_contacts: 'Search contacts',
    Search_clients: 'Search expedients',
    Search_owner: 'Search owner',
    Partner_assign : 'Partner assign',
    Owner_assign : 'Owner assign',
    Responsible : 'Responsible',
    legalName: 'Legal name',
    PCPDeal : 'PCPDeal',
    ServiceTypes : 'Service type',
    are_you_sure: 'Are you sure ?',
    yes: 'Yes',
    no: 'No',
    customerInformation: 'Cliente information',
    CustomerFileProfile : 'Customer file profile',
    waiting : 'Waiting...', 
    module : 'Module',
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    Category: "Category",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
        Resend: "Resend",
    SendNew: "Send New",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    Users: "Users",
    User: "User",
    Search: "Search",
    Name: "Name",
    Surname: "Surname",
    Email: "Email",
    Phone: "Phone",
    Partner: "Partner",
    Partners: "Partners",
    Role: "Role",
    role: "Role",
    quiz : 'Quiz',
    injuriedAddress : 'Injuried Address',
    injuriedProvince : 'Injuried Province',
    injuriedPopulation : 'Injuried Population',
    injuriedZip : 'Injuried Zip',
    Country: "Country",
    Languages: "Languages",
    Department: "Department",
    Departments: "Departments",
    Actions: "Actions",
    Edit: "Edit",
    Delete: "Delete",
    Add: "Add",
    Create: "Create",
    Password: "Password",
    Close: "Close",
    valueMin: "Value Min",
    valueMax: "Value Max",
    AUDIT : 'AUDIT',
    TASK : 'TASK',
    startDate: "Start Date",
    endDate: "End Date",
    FirstName: "First name",
    LastName: "Last name",
    Address: "Address",
    Prioritary: "Prioritary",
    Asigned: "Asigned",
    enterNewEmailAddress: "Enter new email address",
    Confirm: "Confirm",
    Update: "Update",
    Apply: "Apply",
    Save: "Save",
    Changes: "Changes",
    Filter: "Filter",
    FilterBy: "Filter by",
    Filters: "Filters",
    All: "All",
    LatestFilters: "Apply Latest Filters",
    DeleteLatestFilters: "Delete Latest Filters",
    Reset: "Reset",
    ResetFilter: "Reset filter",
    Columns: "Columns",
    Dashboard: "Dashboard",
    New: "New",
    MoreInfo: "More info",
    Bill: "Bill",
    Performances: "Performances",
    Documents: "Documents",
    Document: "Document",
    DocumentCreated: "Document Created",
    Data: "Data",
    Payments: "Payments",
    MedicalCenters: "Medical Centers",
    MedicalCenter: "Medical Center",
    Group: "Group",
    Province: "Province",
    Population: "Population",
    ReferralEmail: "Referral Email",
    ReferralProtocol: "Referral Protocol",
    ContactPerson: "Contact Person",
    Observations: "Observations",
    ReferralPVP: "Referral PVP",
    Services: "Services",
    Scrapyards: "Scrapyards",
    Scrapyard: "Scrapyard",
    DerivationsEmail: "Derivations Email",
    ForgotPassword: "Forgot Password?",
    ChangePassword: "Do you want to change yout Password?",
    EnterPassword: "Enter your new password.",
    EnterEmailToRessetPassword: "Enter your email to create your new password.",
    Submit: "Submit",
    Cancel: "Cancel",
    PleaseWait: "Please Wait...",
    Number: "Number",
    Process: "Process",
    NewPassword: "New Password",
    EmailSent: "Email has been sent!",
    PasswordChanged: "Your password has been changed!",
    InvalidEmail: "Ups, invalid Email!",
    InvalidPassword: "Ups, we can't change your password!",
    RoleCreated: "New Role Created!",
    RoleCreatedError: "Something went wrong creating the role!",
    English: "English",
    Spanish: "Spanish",
    SignOut: "Sign Out",
    Description: "Description",
    Nothing: "Nothing",
    Read: "Read",
    Write: "Write",
    EraseFields: "Erase Fields",
    LegalName: "Legal Name",
    BillingEmail: "Billing Email",
    LegalEmail: "Legal Email",
    ManagementEmail: "Management Email",
    ActingZone: "Acting Zone",
    Type: "Department",
    PaymentType : 'Payment type',
    Status: "Status",
    StatusPartner: "Partner Status",
    StatusLegal: "Legal Status",
    Owner: "Manager",
    Value: "Value",
    SmsVerified: "SMS Verified",
    SmsVerifyCode: "SMS code",
    Origin: "Origin",
    PartnerOwner: "Partner Owner",
    Date: "Date",
    assigned_at: "Date Assigned",
    Fired: "Fired",
    Tags: "Tags",
    Tag: "Tag",
    TagName: "Tag Name",
    TagColor: "Color",
    StatusName: "Status Name",
    AssociatedTag: "Associated tag/s",
    AssociatedDepartment : "Landing/s",
    AssociatedStatus : "Associated status" ,
    DepartmentColor: "Color",
    Webs: 'Website',
    TypeDocuments: 'TypeDocument/s',
    Color: 'Color',
    Derivatives: "Derivatives",
    Classification: "Classification",
    Operations: "Operations",
    Files: "Files",
    Associates: "Associates",
    
    collaboratorsType : 'Colaborators type',
    permissions : 'PERMISSIONS',
    permission_atestados : 'ATESTADOS',
    permission_panelconsultas : 'LEADS',
    permission_expedientes : 'EXPEDIENT',
    permission_tasks : 'TASKS',
    permissionb2c_billing : 'BILLING B2C',
    permissionb2b_billing : 'BILLING B2B',
    permission_documentacion : 'DOCUMENTATION',
    permission_actuaciones : 'ACTUATIONS',
    permission_datos_cliente : 'CUSTOMERS',
    permission_partners : 'PARTNERS',
    permission_ip_bloqueadas : 'BANNED IPS',
    permission_vista : 'PANEL VIEW',
    permission_rutas : 'ACCESS',
    permission_directories : 'DIRECTORIES',
    permission_setup : 'SETUP',
    permission_filtro : 'FILTERS',
    permission_accion : 'ACTIONS',
    permission_menu :  'MAIN MENU',
    permission_tags :  'TAGS',
    permission_collaborators :  'COLABORATORS',
    permission_departments :  'DEPARTMENTS',
    permission_status :  'STATUS',
    permission_roles :  'ROLES',
    permission_users :  'USERS',
    permission_medicalcenter : 'MEDICAL CENTERS',
    permission_customers : 'CLIENTE',
    ChooseProvince:"Choose Province",
    ChoosePopulation:"Choose Population",
    ChooseType:"Choose Type",
    ChooseManager:"Choose Manager",
    ChoosePartner:"Choose Partner",
    ChooseOwner:"Choose Owner",
    BlockedIps:"Blocked IPs",
    
    //ROLES SECTION 
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    contacts : 'Contacts',
    expedients : 'Expedient',
    billing : 'Billing',
    directories : 'Directories',
    adminTables : 'Setup',
    clients : 'Clients',
    partners : 'Partners', 
    medicalcenter: 'Medical Center',
    departments: 'Departments',
    scrapyards: 'Scrapyards',
    users: 'Users',
    tags : 'Tags', 
    status: 'Status',
    roles: 'Roles',
    Completed: "Completed",
    mainLead: 'Main Lead',
    IminutaMin: "I. Minuta Min",
    IminutaMax: "I. Minuta Max",
    IindemnizationMin: "I. Indemnization Min",
    IindemnizationMax: "I. Indemnization Max",
    IsuppliedMin: "I. Supplied Min",
    IsuppliedMax: "I. Supplied Max",
    billDateStart: "Date Bill Start",
    billDateEnd: "Date Bill End",
    upBillDateStart: "Date up Start",
    upBillDateEnd: "Date up End ",
    mainLeadId: 'Main Lead ID',
    idAssociated: 'ID Associated',
    numId: 'ID', 
    customerId: 'Customer ID',
    name: 'Name',
    surname: 'Surname',
    dni: 'DNI',
    address: 'Address',
    city: 'City',
    province: 'Province',
    zip: 'ZIP',
    Zip: 'ZIP',

    email: 'Email',
    phone: 'Phone',
    country: 'Country',
    type: 'Type',
    classification: 'Category',
    tag: 'Tag CTI',
    statusPartner: 'Partner Status',
    statusLegal: 'Legal Status',
    owner: 'Owner',
    partner: 'Partner',
    value: 'Value',
    smsVerified: 'SMS Verified',
    smsVerifyCode: 'SMS Verify Code',
    data: 'Data',
    payment: 'Payment',
    payments: 'Payments',
    origin: 'Origin',
    id : 'ID',
    created_at : 'Created at',
    taskTypePriority:'Priority',
    valuation : 'Valuation',
    idCollaborator : 'Collaborator ID',
    Expedients :'Expedients',
    Leads :'Leads',
    Show: 'Show',
    ShowMore: 'Show More',
    ShowLess: 'Show Less',
    Lead :'Lead',
    Notes :'Notes',
    //END ROLES SECTION
    //CUSTOMER
    phone2 : 'Phone 2',
    population : 'Population',
    birthdate : 'Bith date',
    tutorName : 'Legal',
    tutorIdentification : 'Legal identification',
    tutorTypeIdentification : 'Legal identification type',
    identification : 'Identification',
    _id : 'ID',
    IBAN : 'IBAN',
    //END CUSTOMER
    Customers : 'Customers',
    Customer : 'Customer',
    Loading : 'Loading...',

     //PARTNERS
     billingEmail : 'Billing email',
     legalEmail : 'Legal email',
     managementEmail : 'Managment email',
     LegalDefenseEmail : 'Legal Defense Email',
     CollectionManagementEmail : 'Collection Management Email',
     actingZone : 'Acting zone',
     incompatibleCIAS : 'Incompatible CIAS',
     bill : 'Bill',
     PVP : 'PVP',
     //
     NIF : 'NIF',
     SaveChanges : 'Save',

     //MEDICAL CENTERS
     groupCm: 'Group', 
     referralEmail : 'Refered email',
     contactPerson : 'Person contact', 
     referralProtocol : 'Refered protocol', 
     observations : 'Observations', 
     referralPVP : 'Refered PVP', 
     services : 'Services', 
     //END MEDICAL CENTERS

  
    //COLABORATOR
      collaborator : 'Colaborator',
      collaborators : 'Colaborators',
      PaymentData : 'Payment information',
      PaymentExtra : 'Payment more information',
    //END COLABORATOR

     //MAIN MENU 
     expedientes : 'Expedients', 
     leads : 'Leads', 
     AdminTables : 'Setup', 
     ips: 'Locked IPs',
     Billing: 'Billing',   
     //END MAIN MENU
     Home: 'Click UP',
     addpayment1:'To start using tools, please',
     addpayment2:'Add Payment Method',
     GeneralData:'General Data',
     Subsidiary:'Subsidiary',
     EmptyList:'Empty List',
     Juzgados:'Courts',
     Juzgado:'Court',
     Contrario:'Contrary',
     Consultas:'Leads',
     Notarios:'Notaries',
     Min: 'Min',
     Max: 'Max',
     category: 'Category',
    Next: 'Next',
    Back: 'Back',
     Desguaces:'Scrapyards',
    Manager:'Owner',
    DateStart:'Date start',
    DateRealization:'Date of realization',
    DateEnd:'Date end',
    CUSTOMER:'CUSTOMER',
    created:'created',
    LEAD:'LEAD',
    createda:'created',
    Newa:'new',
    LeadTransferredTo:'Lead transferred to ',
    EXPEDIENT:'EXPEDIENT',
    ChangeOf:'Change of',
    AssignmentOf:'Assignment of',
    by: 'by',
    Associated: "Associated",
    CustomerData: 'Customer data',
    LeadData: 'Lead data',
    AccessExpedient: 'Access the Expedient',
    ShowInfo: 'Show Info',
    NOTASSIGNED: 'Not Assigned',
    'Contact Profile': 'Lead Profile',
    Created: 'Created',
    Added: 'Added',
    Sending: 'Sending',
    Actuation:'Actuation',
    Identification : 'Identification',
    TypeIdentification : 'Type Identification',
    ActuationType:'Actuation Type',
    Comment:'Comment',
    isSubsidiary: "Is Subsidiary",
    SubsidiaryName: "Subsidiary Name",
    PVPDeal: "PVP (Deal)",
    OtherData: "Other Data",
    Send: "Send",
    Sent: "Sent",
    to: "to",
    Tasks: "Tasks",
    Task: "Task",
    Sign: "Sign",
    Actuations: "Actuations",
    CreateTask: "Create a Task from this Actuation?",
    and: "and",
    Choose: "Choose",
    Audit: "Audit",
    AuditData: "Audit Data",
    TaskData: "Task Data",
    ActuationData: "Actuation Data",
    AircallData: "Aircall Data",
    iDPO: "Import DPO",
    periodicDPO: "Pediodic DPO",
    delationChannel: "Delation channel",
    iIndemnization: "Indemnization Import",
    iBilling: "Billing Import",
    billingDate: "Billing Date",
    contactName: "Contact Person",
    Matricula: "Enrolment",
    Matriculacion: "Enrol Date",
    Phone2: "Phone 2",
    
  },
  es: {
    PD: 'PD',
    change_grid_SEPA: 'Cambiar SEPA',
    change_grid_ijudicial : 'Cambiar I.Judicial',
    providerST : 'Proveedor ST',
    tagSt : 'Tag ST',
    offerCIA : 'Oferta CIA',
    offerST : 'Oferta ST',
    assign_provider : 'Asignar proveedor',
    change_tag_st : 'Cambiar Tag ST',

    permission_derivacionesst : "DERIVACIONES ST",
    requestDate : 'Fecha Solicitud',
    autority : 'Autoridad',
    ijudicial: 'I. Judial',
    tagATESTADO : 'Tag Atestado',
    tagFACTURA : 'Tag Factura',
    sendConfirm : 'Enviar Confirm. Solicitud',
    change_tag_atestado : 'Cambiar tag Atestado',

    update_groupCM : "Actualizar Grupo CM",
    update_medicalCenter : "Actualizar CM",
    change_tagCM : "Cambiar tag CM",
    derivatecm : "Derivar CM",
    delegatedPayment : "Pago delegado",
    recoveryPayment : "Recobro",
    reiterationRecoveryPayment : "Reiteración recobro",

    viewAllOwnDepartment: "Ver todos de la misma vertical",
    Priority : "Prioridad",
    Search_atestados:"Buscar atestados",
    Search_derivateST : "Buscar derivaciones ST",
    Search_derivateCM : "Buscar derivaciones CM",
    derivacionesst: "Derivaciones ST",
    derivacionescm: "Derivaciones CM",
    atestado: "Atestado",
    atestados: "Atestados",
    requestAtestado : "Solictar Atestado",
    derivateCM : "Derivar CM",
    derivateST : "Derivar ST",
    TaskData: "Datos de la Tarea",
    ActuationData: "Datos de la Actuación",
    AircallData: "Datos de la Aircall",
    change_grid_PVP: "Cambiar PVP(GRILLA)",
    change_grid_bill: "Cambiar FACTURA(GRILLA)",
    fra_updated : "FACTURA actualizada con éxito",
    SEPA_updated : "SEPA actualizada con éxito",
    pvp_updated: "PVP actualizado con éxito",
    actionstype : "Tipo de actuación",
    validateSign: 'Validar Firma',
    ActionType : "Tipo de actuación",
    ActionTypes : "Tipos de actuación", 
    typeIdentification : 'Tipo de identificación',
    taskstype: 'Tipos de tarea',
    customerfiles: 'Expedientes',
    TaskType : 'Tipo de tarea',
    TaskTypes: 'Tipo de tareas',
    Phone2: "Teléfono 2",
    add_clase: 'Añadir cláusula',
    remove_item : 'Quitar ítem',
    remove_file : 'Quitar archivo',
    Matricula: "Matrícula",
    Matriculacion: "Matriculación",
    contactName: "Persona de contacto",
    iBilling: "Importe de compra",
    billingDate: "Fecha de compra",
    iIndemnization: "Importe indemnización",
    delationChannel: "Canal denuncia",
    Completed: "Completada",
    periodicDPO: "DPO Pediódico",
    iDPO: "Importe DPO",
    iCerrado: 'Importe Cierre',
    Next: 'Siguiente',
    Back: 'Atras',
    carBrand: 'Marca Vehículo',
    createLead: 'Añadir ID',
    Audit : 'Auditoría',
    AuditData : 'Dato de la Tarea',
    send_SEPA: 'Enviar Firma SEPA',
    change_tag_audit : 'Cambiar Tag Auditoría',
    tagAudit: 'Tag Auditoría',
    upDate: 'Fecha Alta',
    cmderivations: 'Derivaciones CM',
    stderivations: 'Derivaciones ST',
    audits: 'Auditorías', 
    panels : 'Paneles',
    cdelation: 'C. Denuncias',
    iImplementation : 'I. Implementación',
    iMaintenance : 'I. Mantenimiento',
    DPO : 'DPO',
    auditDate : 'Fecha Auditoria',
    AUDIT : 'AUDITORÍA',
    TASK : 'TAREA',
    derivateAudit: 'Derivar Auditoría',
    cancelAudit : 'Cancelar Auditoría',
    //Collabortadores 
    add_juzgados : "Añadir juzgados",
    add_compañias : "Añadir compañías",
    add_tramitadores : "Añadir tramitadores",
    add_siniestros : "Añadir siniestros",
    add_procuradores : "Añadir procudarores",
    add_autoridadespoliciales : "Añadir aut. policiales",
    add_fabricantedeautos : "Añadir fab. de auto",
    add_notarias : "Añadir notarías",
    add_centrosmedicos : "Añadir centros médicos",

    edit_juzgados : "Editar juzgados",
    edit_compañias : "Editar compañías",
    edit_tramitadores : "Editar tramitadores",
    edit_siniestros : "Editar siniestros",
    edit_procuradores : "Editar procudarores",
    edit_autoridadespoliciales : "Editar aut. policiales",
    edit_fabricantedeautos : "Editar fab. de auto",
    edit_notarias : "Editar notarías",
    edit_centrosmedicos : "Editar centros médicos",

    delete_juzgados : "Eliminar juzgados",
    delete_compañias : "Eliminar compañías",
    delete_tramitadores : "Eliminar tramitadores",
    delete_siniestros : "Eliminar siniestros",
    delete_procuradores : "Eliminar procudarores",
    delete_autoridadespoliciales : "Eliminar aut. policiales",
    delete_fabricantedeautos : "Eliminar fab. de auto",
    delete_notarias : "Eliminar notarías",
    delete_centrosmedicos : "Eliminar centros médicos",

    view_juzgados : "Ver juzgados",
    view_compañias : "Ver compañías",
    view_tramitadores :"Ver tramitadores",
    view_siniestros : "Ver siniestros",
    view_procuradores : "Ver procudarores",
    view_autoridadespoliciales : "Ver aut. policiales",
    view_fabricantedeautos : "Ver fab. de auto",
    view_notarías : "Ver notarías",
    view_centrosmedicos : "Ver centros médicos",
    //
    canAssignTaskLead : "Asignar tareas de Leads",
    canAssignTaskCustomerFile : "Asignar tareas de Expedientes",
    editDatos : "Editar datos",
    assingLead: 'Se le puede asignar Leads',
    assingCustomerFiles: 'Se le puede asignar Expedientes',
    isJuridicCTI : 'Es Jurídico CTI',
    derivateBill: 'Derivar a Facturación',
    tab_billing: 'Panel Facturación',
    tab_expedients : 'Panel Expedientes',
    tab_users : 'Panel Usuarios',
    tab_documents : 'Panel Documentos',

    change_fra_tag: 'Cambiar Tag Fra',
    total: 'Total',
    FRAnum : 'N Factura',
    billing_b2b: 'Facturación B2B',
    billing_b2c: 'Facturación B2C',
    partnerProfile : 'Perfil del Partner',
    export: 'Exportar',
    sendLinkDoc : 'Enviar enlace DOC',
    Link : 'Enlace',
    BillingB2C : 'Factura B2C',
    BillingB2B : 'Factura B2B',
    CtiPartner :  'CTI/Partner',
    Assign:  'Asignar',
    massiveAssignManagerCTI : 'Asignar Gestor CTI Masivo',
    massiveChange : 'Realizar masivo',
    only_manager_cti  : 'Ver todos (Incluyendo los que no tiene asignados Gestor CTI)', //Leads
    only_owner : 'Ver todos (Incluyendo los que no tienen asignados el responsable)',
    only_mine: 'Ver todas',
    language: 'Idioma',
    allowedTypes : 'Verticales',
    FRA_type :  'Tipo FRA',
    FRAtype :  'Tipo FRA',
    upBillDate : 'Fecha Alta',
    Message:'Mensaje',
    Author:'Autor',
    billDate : 'Fecha Factura',
    suppliedType : 'Tipo Suplido',
    supplied_type : 'Tipo Suplido',
    lsupplied : 'I. Suplido',
    lindemnization : 'I. Indemnización',
    lminuta : 'I. Minuta',
    tagFRA : 'Tag Fra',
    serie : 'Serie',
    quiz : 'Quiz',
    injuriedAddress : 'Dirección del lesionado',
    injuriedProvince : 'Provincia del lesionado',
    injuriedPopulation : 'Población del lesionado',
    injuriedZip : 'CP del lesionado',

    createTask : 'Crear Tarea',
    createActuation : 'Crear Actuación', 
    sendSMS : 'Enviar SMS', 
    createBill : 'Crear Factura', 
    sendDocLink  : 'Enviar Enlace DOC', 
    sendPaymentLink : 'Enviar Enlace de Pago', 


    created_date_at : 'Fecha Tarea',
    massiveComplete : 'Realizar masivo',
    massiveReasign : 'Reasignar masivo',
    realized_at : 'Fecha Realización',
    completedTasks:'Realizada',
    scheduledTask:'Programada',
    pendingTask:'Pendiente',
    overdueTasks:'Fuera de plazo',
    change_task : 'Cambiar estado de tarea?',
    Previsualization : 'Previsualización',
    assigned: 'Asignado',
    priority: 'Prioridad',
    observation: 'Observación',
    documentsPending : 'DOC',
    chooseJuzgado : 'Seleccionar juzgado',
    Compañías : 'Compañías',

    change_tag_cti : 'Cambiar Tag CTI',
    change_tag_partner : 'Cambiar Tag Partner',
    change_status_legal : 'Cambiar Estado Legal',
    update_owner: 'Cambiar Responsable',
    update_partner: 'Cambiar Partner',
    customerFiles_details : 'Ver expediente',
    tasks : 'Tareas',
    fiscalName : 'Nombre fiscal',
    category: 'Categoría',
    comercialName : 'Nombre comercial',
    serieFRA: 'Serie FRA',
    Taxes: 'Impuesto aplicable',
    name_and_surname : 'Nombre completo',
    judgedType : 'Tipo de juzgado',
    editJuzgado: 'Editar juzgado',
    editContrario: 'Editar contrario',
    tagPartner : "Tag Partner", 
    DateAsigned : "Fecha Asignación ",
    lead_details : 'Detalles de consultas',
    change_tag: 'Cambiar Tag Consulta',
    change_status : 'Cambiar estado',
    send_SMS : 'Enviar SMS',
    editClient : 'Editar cliente',
    actuations : 'Actuaciones',
    send_signature : 'Enviar Firma',
    generateOrder : 'Facturación',
    associate_contact : 'Asociar contacto',
    change_managerCTI : 'Asignar Gestor CTI',
    createTaskActuation : 'Crear tarea a partir de actuación',
    completeTask : 'Realizar tarea',
    completeTasks : 'Realizar',
    relatedUserId: "Asignado",
    moreInfo : 'Mas información',
    tab_feed: 'Panel Feed',
    tab_actuations : 'Panel Actuaciones',
    tab_task: 'Panel Tareas',
    tab_data: 'Panel Datos',
    tab_Associated : 'Ver Asociados',
    tab_payment: 'Panel Facturación',
    injuries: 'Lesiones',
    rehabilitationDays: 'Días de rehabilitación',
    hospitalDays: 'Días de baja hospitalaria',
    outWorkDays: 'Días de baja media o laboral',
    refSinister: 'Referencia Siniestro',
    oppositeFault: 'Culpa Contrario',
    coResponsibility: 'Corresponsabilidad',
    existAtestado: 'Existe Atestado',
    friendlyReport: 'Existe Parte Amistoso',
    workClearance: 'Alta Laboral',
    medicalClearance: 'Alta Médica',
    estimatedDaysOff: 'Estimación días de baja',
    rehabilitation: 'Rehabilitación',
    operated: 'Ha sido intervenido',
    futureOperation: 'Futura intervención',
    motivatedOffer: 'Oferta Motivada',
    import: 'Importe',
    contactWithCIA: 'Contacto con CIA',
    medicalVisitCIA: 'Visita Médica CIA',
    processorAcceptPD: 'Tramitador Acepta PD',
    processorEmail: 'Email Tramitador',
    processorExtension: 'Extensión Tramitador',
    processorPhone: 'Teléfono Tramitador',
    sinisterVehicle: 'Vehículo Siniestro',
    estimatedDaysHospital: 'Días de baja hospitalaria',
    userValoration: 'Valoración usuario',
    processor: 'Tramitador',
    lawyerCIA: 'Abogado CIA',
    privateLawyer: 'Abogado Particular',
    requestPermission: 'Solicitar Venia',
    previousLawyerName: 'Nombre Abogado anterior',
    previousLawyerEmail: 'Email Abogado anterior',
    lossOfProfit: 'Existe lucro cesante',
    policyNumber: 'Número de póliza',
    insuredEnrolment: 'Matrícula Asegurado',
    legalDefense: 'Defensa Jurídica',
    legalDefenseImport: 'Importe Defensa Jurídica',

    dont_have_permissions: 'No tienes permisos suficientes', 
    Send_SMS: 'Enviar SMS',
    managerCTI: 'Gestor CTI',
    Yes: 'Si',
    No: 'No',
    Select_a_date : 'Seleccionar fecha', 
    Fail_msg: 'Seleccione al menos una forma de envio',
    Error: 'Error', 
    Send_signature : 'Enviar firma',
    Associate_contact : 'Asociar contacto', 
    Associate_new_contact : 'Asociar nuevo contacto',
    go_back : 'Regresar a asignar partner',
    Search_partners: 'Buscar Partners',
    Search_audits: 'Buscar Auditoría',
    succesfull: "Exito",
    partner_updated: "Partner asignado",
    owner_updated : "Responsable asignado",
    status_updated : "Estado asignado",
    tag_updated : "Tag asignada",
    Search_contacts: 'Buscar leads',
    Search_clients: 'Buscar expedientes',
    Search_owner: 'Buscar responsable',
    Partner_no_assign : 'Sin asignar',
    Partner_assign : 'Asignar Partner',
    Owner_assign : 'Asignar Responsable',
    Responsible : 'Responsable',
    legalName: 'Nombre legal',
    Process: "Procedimiento",
    PCPDeal : 'PCPDeal',
    are_you_sure: 'Esta seguro ?',
    yes: 'Si',
    no: 'No',
    ServiceTypes : 'Tipo de servicios',
    customerInformation: 'Informacion del cliente',
    CustomerFileProfile : 'Perfil de expediente',
    waiting : 'Espere...', 
    module : 'Módulo',
    //MAIN MENU 
    Home: 'Click UP',
    ips: 'Bloqueo de IPs',
    expedientes : 'Expedientes', 
    leads : 'Leads',
    AdminTables : 'Configuración', 
    Billing: 'Facturación', 
    
    //END MAIN MENU
    //COLABORATOR
    collaborator : 'Colaborador',
    collaborators : 'Colaboradores',
    PaymentData : 'Datos de pago',
    PaymentExtra : 'Datos de pago extra',
    //END COLABORATOR

    //MEDICAL CENTERS
    groupCm: 'Grupo', 
    referralEmail : 'Email referido',
    contactPerson : 'Contacto', 
    referralProtocol : 'Protocolo referido', 
    observations : 'Observaciones', 
    referralPVP : 'PVP referido', 
    services : 'Servicios', 
    //END MEDICAL CENTERS
      NIF : 'NIF',
      SaveChanges : 'Guardar', 
      //PARTNERS
      billingEmail : 'Correo de facturación',
      legalEmail : 'Correo de Legal',
      managementEmail : 'Correo de administracion',
      LegalDefenseEmail : 'Email Defensa Jurídica',
      CollectionManagementEmail : 'Email Gestión Recobro',
      actingZone : 'Zona de Actuación',
      incompatibleCIAS : 'CIAS incompatibles',
      bill : 'Factura',
      PVP : 'PVP',
      //
      Loading : 'Cargando...',
      Customers : 'Clientes',
      Customer : 'Cliente',
      //CUSTOMER
      phone2 : 'Telefono 2',
      population : 'Población',
      birthdate : 'Cumpleaños',
      tutorName : 'Tutor',
      tutorIdentification : 'Identificación Tutor',
      tutorTypeIdentification : 'Tipo de identificación del Tutor',
      identification : 'Identificación',
      Identification : 'Identificación',
      TypeIdentification : 'Tipo de Identificación',
      _id : 'ID',
      IBAN : 'IBAN',
      //END CUSTOMER

    //ROLES SECTION
    add: 'Añadir',
    edit: 'Editar',
    delete: 'Eliminar',
    contacts : 'Leads',
    expedients : 'Expedientes',
    billing : 'Facturación',
    directories : 'Directorios',
    adminTables : 'Configuracion',
    clients : 'Clientes',
    partners : 'Partners', 
    medicalcenter: 'Centros Medicos',
    departments: 'Verticales',
    scrapyards: 'Desguaces',
    users: 'Usuarios',
    tags : 'Tags', 
    status: 'Estado',
    roles: 'Roles',
    Role: 'Rol',
    valueMin: "Valor mínimo",
    valueMax: "Valor máximo",
    startDate: "Fecha de inicio",
    endDate: "Fecha de fin",
    Apply: "Aplicar",
    mainLead: 'Lead Principal',
    mainLeadId: 'ID Lead Principal',
    idAssociated: 'ID de Asociado',
    numId: 'ID', 
    customerId: 'Cliente ID',
    name: 'Nombre',
    Show: 'Enseñar',
    ShowMore: 'Mostrar más',
    ShowLess: 'Mostrar menos',
    Min: 'Mínimo',
    Max: 'Máximo',
    Prioritary: "Prioritario",
    Asigned: "Asignado",
    Assigned: "Asignado",
    Changed: "Cambiado",
    Changeds: "Cambiados",
    TaskStatus: "Estado Tarea",
    surname: 'Apellido',
    dni: 'DNI',
    address: 'Direccion',
    city: 'Ciudad',
    province: 'Provincia',
    zip: 'ZIP',
    Zip: 'ZIP',
    email: 'Email',
    phone: 'Telefono',
    country: 'Pais',
    type: 'Tipo',
    tag_contact: 'Tag Consulta',
    tag_cti: 'Tag CTI',
    classification: 'Categoria',
    tag: 'Tag Consulta',
    statusPartner: 'Partner Estado',
    statusLegal: 'Legal Estado',
    owner: 'Responsable',
    partner: 'Partner',
    value: 'Importe',
    smsVerified: 'SMS Verificado',
    smsVerifyCode: 'SMS codigo de verificacion',
    data: 'Data',
    payment: 'Pago',
    payments: 'Pagos',
    origin: 'Origen',
    id : 'ID',
    created_at : 'Fecha',
    valuation : 'Importe',
    idCollaborator : 'ID Colaborador',
    //END ROLES SECTION
    collaboratorsType : 'Tipos de colaboradores',
    derivationDate: "Fecha Derivación",
    tagCM: "Tag CM",
    groupCM: "Grupo CM",
    medicalCenter: "Centro Médico",
    permission_cmderivations : "DERIVACIONES CM",
    permissions : 'PERMISOS',
    permission_audit: 'AUDITORIA',
    permission_medicalcenter : 'CENTROS MEDICOS',
    permission_users :  'USUARIOS',
    permission_roles :  'ROLES',
    permission_panels :  'PANELES',
    permission_status :  'ESTADOS',
    permission_departments :  'VERTICALES',
    permission_collaborators :  'COLABORADORES',
    permission_tags :  'TAGS',
    permission_taskstype :  'TIPOS DE TAREA',
    permission_actionstype :  'TIPOS DE ACTUACION',
    permission_menu :  'MENU PRINCIPAL',
    permission_rutas : 'ACCESOS',
    permission_directories : 'DIRECTORIOS',
    permission_setup : 'CONFIGURACION',
    permission_panelconsultas : 'LEADS',
    permission_expedientes : 'EXPEDIENTES',
    permission_tasks : 'TAREAS',
    permissionb2c_billing : 'FACTURACIÓN B2C',
    permissionb2b_billing : 'FACTURACIÓN B2B',
    permission_documentacion : 'DOCUMENTACION',
    permission_actuaciones : 'ACTUACIONES',
    permission_datos_cliente : 'DATOS CLIENTE',
    permission_partners : 'PARTNERS',
    permission_ip_bloqueadas : 'IPS BLOQUEADAS',
    permission_vista : 'VISTA PANEL',
    permission_filtro : 'FILTROS',
    permission_accion : 'ACCIONES',
    permission_customers : 'CLIENTES',
    
    Color: 'Color',

    PaymentType : 'Método de pago',


    TypeDocuments: 'Documento/s',

    Webs: 'Sitio',
    Tags: "Tags",
    DepartmentColor: "Color",
    Tag: "Tag",
    TagName: "Nombre",
    TagColor: "Color",
    Status: "Estado",
    StatusName: "Nombre",
    AssociatedTag: "Tag/s asociada/s",
    AssociatedStatus: "Estado asociado", //"Estado/s asociado/s",
    AssociatedDepartment : "Vertical/es",
    dashboard: "DashBoard",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    Resend: "Reenviar",
    SendNew: "Enviar nuevo",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
    Users: "Usuarios",
    User: "Usuario",
    Search: "Buscar",
    Name: "Nombre",
    Surname: "Apellido",
    Email: "Email",
    Category: "Categoría",
    Phone: "Teléfono",
    Partner: "Partner",
    Partners: "Partners",
    role: "Rol",
    Country: "País",
    Languages: "Idiomas",
    Departments: "Verticales",
    Department: "Vertical",
    Actions: "Acciones",
    Edit: "Editar",
    Delete: "Eliminar",
    Add: "Añadir",
    Create: "Crear",
    Password: "Contraseña",
    Close: "Cerrar",
    FirstName: "Nombre",
    LastName: "Apellido",
    Address: "Dirección",
    enterNewEmailAddress: "Nuevo email",
    Confirm: "Confirmar",
    Update: "Actualizar",
    l: "Actualizar",
    IminutaMin: "I. Minuta Min",
    IminutaMax: "I. Minuta Max",
    IindemnizationMin: "I. Indemnización Min",
    IindemnizationMax: "I. Indemnización Max",
    IsuppliedMin: "I. Suplido Min",
    IsuppliedMax: "I. Suplido Max",
    billDateStart: "Fecha Factura Inicio",
    billDateEnd: "fecha Factura Fin",
    upBillDateStart: "Fecha Alta Inicio",
    upBillDateEnd: "Fecha Alta Fin",
    Save: "Guardar",
    Changes: "Cambios",
    Filter: "Filtro",
    Filters: "Filtros",
    Number: "Numero",
    All: "Todos",
    LatestFilters: "Aplicar Filtros Previos",
    DeleteLatestFilters: "Revocar Filtros Previos",
    Reset: "Quitar todos",
    ResetFilter: "Quitar filtro",
    Columns: "Columnas",
    Dashboard: "Inicio",
    New: "Nuevo",
    MoreInfo: "Mas Información",
    Bill: "Factura",
    Performances: "Actuaciones",
    Documents: "Documentos",
    Document: "Documento",
    DocumentCreated: "Documento Creado",
    Data: "Datos",
    Payments: "Pagos",
    MedicalCenters: "Centros Médicos",
    MedicalCenter: "Centro Médico",
    Group: "Grupo",
    Province: "Provincia",
    Population: "Ciudad",
    ReferralEmail: "Email de referencia",
    ReferralProtocol: "Protocolo de referencia",
    Validate: 'Validar',
    ContactPerson: "Persona de contacto",
    Observations: "Observaciones",
    ReferralPVP: "PVP de referencia",
    Services: "Servicios",
    Scrapyards: "Desguaces",
    Scrapyard: "Desguace",
    DerivationsEmail: "Email de derivaciones",
    condition: 'Condición',
    colisionType: 'Tipo de colisión',
    ForgotPassword: "¿No recuerdas la contraseña?",
    ChangePassword: "¿Quieres cambiar tu Contraseña?",
    EnterPassword: "Introduce tu nueva contraseña",
    EnterEmailToRessetPassword: "Introduce tu email para crear una nueva contraseña",
    Submit: "Enviar",
    Cancel: "Cancelar",
    PleaseWait: "Espera Por favor...",
    NewPassword: "Nueva Contraseña",
    EmailSent: "El Email ha sido enviado",
    PasswordChanged: "Tu contraseña se ha cambiado con éxito",
    InvalidEmail: "Ups, Email incorrecto",
    InvalidPassword: "Ups, no hemos podido cambiar la contraseña",
    RoleCreated: "Nuevo Rol Creado",
    RoleCreatedError: "Hay algún problema al crear el Rol",
    English: "Inglés",
    Spanish: "Español",
    SignOut: "Cerrar Sesión",
    Description: "Descripción",
    Nothing: "Nada",
    Read: "Lectura",
    Write: "Escritura",
    EraseFields: "Borrar Campos",
    LegalName: "Nombre legal",
    BillingEmail: "Email de Facturación",
    LegalEmail: "Email legal",
    ManagementEmail: "Email de gestión",
    ActingZone: "Zona de actuación",
    Type: "Vertical",
    StatusPartner: "Estado del Partner",
    StatusLegal: "Estado Legal",
    Owner: "Responsable",
    Value: "Importe",
    SmsVerified: "SMS Verificado",
    SmsVerifyCode: "Código SMS",
    Origin: "Origen",
    PartnerOwner: "Dueño del Partner",
    Date: "Fecha",
    assigned_at: "Fecha Asignación",
    Fired: "Despedido",
    Derivatives: "Derivados",
    Classification: "Categoria",
    Operations: "Actuaciones",
    Files: "Documentos",
    Associated: "Asociado",
    Associates: "Asociados",
    ChooseCountry:"Elige País",
    ChooseProvince:"Elige Provincia",
    ChoosePopulation:"Elige Población",
    ChooseType:"Elige Tipo",
    ChooseManager:"Elige Gestor",
    ChoosePartner:"Elige Partner",
    ChooseOwner:"Elige Responsable",
    BlockedIP:"IPs Bloqueadas",
    Expedients :'Expedientes',
    Leads :'Leads',
    Lead :'Consulta',
    addpayment1:'Para empezar, por favor,',
    addpayment2:'Añade un método de pago',
    GeneralData:'Datos Genéricos',
    Subsidiary:'Filial',
    EmptyList:'Lista Vacía',
    taskTypePriority:'Prioridad',
    Notes:'Notas',
    Juzgados:'Juzgados',
    Juzgado:'Juzgado',
    Contrario:'Contrario',
    Consultas:'Leads',
    Notarios:'Notarios',
    Desguaces:'Desguaces',
    Manager:'Responsable',
    DateStart:'Fecha de inicio',
    DateRealization:'Fecha de Realización',
    DateEnd:'Fecha de fin',
    CUSTOMER:'CLIENTE',
    created:'creado',
    LEAD:'CONSULTA',
    createda:'creada',
    Newa:'Nueva',
    LeadTransferredTo:'Consulta traspasada a ',
    EXPEDIENT:'EXPEDIENTE',
    ChangeOf:'Cambio de',
    AssignmentOf:'Asignación de',
    by: 'por',
    CustomerData: 'Datos del Customer',
    LeadData: 'Datos de la Consulta',
    AccessExpedient: 'Acceder al Expediente',
    ShowInfo: 'Ver Info',
    NOTASSIGNED: 'Sin Asignar',
    'Contact Profile': 'Perfil de la Consulta',
    Created: 'Creado',
    Added: 'Añadido',
    Sending: 'Enviando',
    Actuation: 'Actuación',
    ActuationType: 'Tipo de Actuación',
    Comment: 'Comentario',
    FilterBy: "Filtrar por",
    isSubsidiary: "Es filial",
    SubsidiaryName: "Nombre Filial",
    PVPDeal: "PVP (Acuerdo)",
    OtherData: "Otros Datos",
    Send: "Enviar",
    Sent: "Enviado",
    to: "a",
    Tasks: "Tareas",
    Task: "Tarea",
    task: "Tarea",
    Sign: "Firma",
    Actuations: "Actuaciones",
    CreateTask: "Crear una Tarea a partir de esta Actuación?",
    and: "y",
    Choose: "Elige",
  },
  de: {
    Search_contacts: 'Search leads',
    Search_clients: 'Search expedient',
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    multiStepSignUp: "Multi-Steps-Anmeldung",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    multiStepSignUp: "マルチステップサインアップ",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    multiStepSignUp: "S'Inscrire Multi-Étapes",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  warnHtmlInMessage: 'off',
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
