import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ContactsModule from "@/store/modules/ContactsModule";
import AuditsModule from "@/store/modules/AuditsModule";
import DerivacionesCMModule from "@/store/modules/DerivacionesCMModule";
import atestadosModules from "@/store/modules/atestadosModule";
import panelSTModule from "@/store/modules/panelSTModule";
import DocumentationModule from "@/store/modules/DocumentationModule";
import FeedModule from "@/store/modules/FeedModule";
import PartnersModule from "@/store/modules/PartnersModule";
import RolesModule from "@/store/modules/RolesModule";
import NotificationsModule from "@/store/modules/NotificationsModule";
import BillingModule from "@/store/modules/BillingModule";
import UsersModule from "@/store/modules/UsersModule";
import DepartmentsModule from "@/store/modules/DepartmentsModule";
import CollaboratorsModule from "@/store/modules/CollaboratorsModule";
import TagsModule from "@/store/modules/TagsModule";
import TasksTypeModule from "@/store/modules/TaskTypeModule";
import ActionTypeModule from "@/store/modules/ActionTypeModule";
import StatusModule from "@/store/modules/StatusModule";
import CustomersModule from "@/store/modules/CustomersModule";
import MedicalCentersModule from "@/store/modules/MedicalCentersModule";
import BlockedIpsModule from "@/store/modules/BlockedIpsModule";
import ColaboratorsType from "@/store/modules/ColaboratorsTypeModule";
import ServiceTypesModule from "@/store/modules/ServiceTypesModule";
import HistoryModule from "@/store/modules/HistoryModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    ContactsModule,
    AuditsModule,
    DocumentationModule,
    FeedModule,
    PartnersModule,
    RolesModule,
    NotificationsModule,
    BillingModule,
    UsersModule,
    DepartmentsModule,
    CollaboratorsModule,
    TagsModule,
    TasksTypeModule,
    ActionTypeModule,
    StatusModule,
    MedicalCentersModule,
    BlockedIpsModule,
    CustomersModule,
    ColaboratorsType,
    ServiceTypesModule,
    HistoryModule,
    DerivacionesCMModule,
    atestadosModules,
    panelSTModule,
  },
});

export default store;
