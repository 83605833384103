import { Action, Mutation, Module, VuexModule,config  } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
config.rawError = true;

interface StoreInfo {}

@Module
export default class UsersModule extends VuexModule implements StoreInfo {

  usersPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  partnerUsers = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  medicalCenterUsers = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  usersFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  filters ={
    filter: "",
    word:""
  }
  
  users = null
  user = null


  /* --- GETTERS --- */


  get getUsers() {
    return this.users;
  }

  get getPartnerUsers() {
    return this.partnerUsers;
  }

  get getMedicalCenterUsers() {
    return this.medicalCenterUsers;
  }


  get getUser() {
    return this.user;
  }

  get getUsersPagination() {
      return this.usersPagination;
  }

  get getUsersFilters() {
    return this.usersFilters;
  }
  get getFilters() {
    return this.filters;
  }

  get getPageUsers() {
    return this.usersFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_USERS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.usersPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_USERS](payload) {
    this.usersFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_USERS](payload) {
    // //console.log('mutation',payload);
    this.users = payload;
  }

  @Mutation
  [Mutations.SET_PARTNER_USERS](payload) {
    // //console.log('mutation',payload);
    this.partnerUsers = payload;
  }

  @Mutation
  [Mutations.SET_MEDICAL_CENTER_USERS](payload) {
    // //console.log('mutation',payload);
    this.medicalCenterUsers = payload;
  }

  @Mutation
  [Mutations.SET_USER_BY_ID](payload) {
    // //console.log('mutation',payload);
    this.user = payload;
  }

  @Mutation
  [Mutations.SET_LOADING_TRUE_USERS](payload) {
    // //console.log('mutation',payload);
    this.usersPagination.loading = true;
  }

  @Mutation
  [Mutations.SEARCH_USERS]({payload, pagination, loading, error}) {
    // //console.log('mutation',payload);
    // //console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((user: any) => user.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.usersPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_USERS](payload) {
    // //console.log('FILTERmutation',payload);
    this.usersFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_USERS_FILTERS](payload) {
    this.usersFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  // @Mutation
  // [Mutations.DELETE_USER](payload) {
  //   this.usersPagination.pagination = this.usersPagination.pagination.filter((user: any) => user._id !== payload)
  //   //console.log('deletemutation', payload)
  // }


  /* --- ACTIONS --- */

  // @Action
  // [Actions.SET_PAGE_PAGINATIONS_USERS](payload) {
  //   this.context.commit(Mutations.SET_PAGE_PAGINATION_USERS, payload.page);
  //   this.context.dispatch(payload.getAction);
  // }

  @Action({ rawError: true })
  [Actions.SEARCH_USERS](payload) {
    ApiService.get(`users`)
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SEARCH_USERS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_USERS](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_USERS, payload);
    this.context.dispatch(Actions.GET_USERS)
  }

//   @Action
//   [Actions.GET_CONTACT_BY_ID](payload) {
//     return ApiService.get(`contact`, payload)
//       .then(({ data }) => {
//         this.context.commit(Mutations.SET_CONTACT, data);
//       })
//       .catch(({ response }) => {
//         this.context.commit(Mutations.SET_ERROR, response.data.errors);
//       });
//   }

  @Action
  [Actions.GET_USERS]() {
    // //console.log(this.usersFilters.searchString)
    return ApiService.get(
      `users?page=${this.usersFilters.page}&search=${this.usersFilters.search}${this.usersFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_USERS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ALL_USERS]() {
    // //console.log(this.usersFilters.searchString)
    return new Promise((resolve, reject) => {
    return ApiService.get(`allusers`)
      .then(({ data }) => {
        
        this.context.commit(Mutations.SET_USERS, { pagination: {data} })
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

  @Action
  [Actions.GET_PARTNER_USERS](payload) {
    // //console.log(this.usersFilters.searchString)
    return ApiService.get(
      `users/partner/${payload.id}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_PARTNER_USERS, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_MEDICAL_CENTER_USERS](payload) {
    // //console.log(this.usersFilters.searchString)
    return ApiService.get(
      `users/collaborator/${payload.id}?page=${this.usersFilters.page}&search=${this.usersFilters.search}${this.usersFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_MEDICAL_CENTER_USERS, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  
  @Action
  [Actions.GET_COLLABORATOR_USERS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    // //console.log(this.usersFilters.searchString)
    return ApiService.get(
      `users/allcollaborator/${payload.id}`
    )
      .then(({ data }) => {
        console.log(data);
        resolve(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_USER_BY_ID](payload) {
    // //console.log(this.usersFilters.searchString)
    return ApiService.get(`users/${payload}`)
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_USER_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({rawError: true})
  [Actions.PUT_USER](payload) {
    const {id, user} = payload;
    // //console.log(user);
    return new Promise((resolve, reject) => {

    return ApiService.put(`users/${id}`, user)
      .then(({ data }) => {
        resolve(data)
        this.context.dispatch(Actions.GET_USERS)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    });
  }

  @Action
  [Actions.PUT_USER_PASSWORD](payload) {
    const {id, password, token} = payload;
    // //console.log(password);
    return new Promise((resolve, reject) => {
ApiService.put(`users/recovery/${id}/${token}`, password)
      .then(({ data }) => {
        resolve(data)
        this.context.dispatch(Actions.GET_USERS)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action({rawError: true})
  [Actions.CREATE_USER](payload) {

    return new Promise((resolve, reject) => {
      ApiService.post(`users`, payload)
      .then( data  => {
        resolve(data)
        // //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_USERS)
      })
      .catch(({ response }) => {
        reject(response);
        // //console.log('store error', response);
        //this.context.commit(Mutations.SET_ERROR, response);
      });
    });
  }

  @Action
  [Actions.DELETE_USER](payload) {
    return ApiService.delete(`users/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_USERS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_USERS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_USERS_FILTERS, payload);
    this.context.dispatch(Actions.GET_USERS);
  }

  @Action
  [Actions.SET_LOADING_TRUE_USERS]() {
    this.context.commit(Mutations.SET_LOADING_TRUE_USERS);
  }

  // @Action
  // [Actions.CREATE_CONTACT](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`lead`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }

  // @Action
  // [Actions.RESEND_PAYLINK](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`orders/resendLink`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }
}
