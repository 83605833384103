import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";


@Module
export default class AuditsModule extends VuexModule  {



  auditsPagination = {
    pagination: null,
    loading: true,
    error: null,
  };


  auditsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  auditsFilesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };



  /* --- GETTERS --- */


  get getAudits(): any {
    return this.auditsPagination;
  }

  get getAuditsPagination() {
    // //console.log('kk',this.clientsPagination);
    return this.auditsPagination;
  }

  get getAuditsFilters() {
    return this.auditsFilters;
  }

  get getPageAudits() {
    return this.auditsFilters.page;
  }


  /* --- MUTATIONS --- */


  @Mutation
  [Mutations.SET_AUDITS_PAGINATION](payload) {
    this.auditsPagination = payload;
  }

  @Mutation
  [Mutations.CLEAR_AUDITS_FILTERS](payload) {
    this.auditsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }
 
  @Mutation
  [Mutations.SET_PAGE_PAGINATION_AUDITS](payload) {
    this.auditsFilters.page = payload;
  }


  @Mutation
  [Mutations.FILTER_AUDITS](payload) {
    // //console.log('FILTERmutation',payload);
    this.auditsFilters.searchString = payload
  }


  @Action
  [Actions.CLEAR_AUDITS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_AUDITS_FILTERS, payload);
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_AUDITS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_AUDITS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
    //console.log('SET_PAGE_PAGINATIONS_CLIENTS',payload.getAction)
  }

  @Action({ rawError: true })
  [Actions.FILTER_AUDITS](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_AUDITS, payload);
    this.context.dispatch(Actions.GET_AUDITS,payload)
  }


  /* --- ACTIONS --- */

  @Action({ rawError: true })
  [Actions.UPDATE_AUDIT_TAG](payload) {
    const {id, tag} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/audits/tag/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_AUDITS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_AUDIT_SEPA](payload) {
    const {id} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/audits/SEPA/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_AUDITS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.DELETE_AUDIT](payload) {
    const {id} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.delete(`/audits/delete/${id}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_AUDITS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  

  @Action({ rawError: true })
  [Actions.GET_AUDITS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    this.auditsPagination.loading = true;
     return ApiService.get(
      `audits/getallrecords?search=${this.auditsFilesFilters.search}${this.auditsFilesFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_CLIENTS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }



  @Action({ rawError: true })
  [Actions.GET_AUDITS](payload) {
    this.auditsPagination.loading = true;
    return ApiService.get(
      `audits?limit=10&page=${this.auditsFilters.page}&search=${this.auditsFilters.search}${this.auditsFilters.searchString}`
      // `customerfiles`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUDITS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

 


}
