import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


@Module
export default class HistoryModule extends VuexModule {

    history = [];

    historyByLead= []


  /* --- GETTERS --- */

  get getHistory(): any {
    return this.history;
  }

  get getHistoryByLead(): any {
    return this.historyByLead;
  }


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_HISTORY](history: any) {
    this.history = history;
  }

  @Mutation
  [Mutations.SET_HISTORY_BY_LEAD](historyByLead: any) {
    this.historyByLead = historyByLead;
  }


    /* --- ACTIONS --- */

  @Action
  [Actions.GET_HISTORY_BY_LEAD](payload) {
    return new Promise((resolve, reject) => {
    ApiService.get(`history/lead/${payload}`).then((response) => {
      this.context.commit(Mutations.SET_HISTORY_BY_LEAD, response.data);
      resolve(response)
    }).catch((err) => reject(err));
  });
  }

  @Action
  [Actions.GET_HISTORY](): void {
    ApiService.get(`history`).then((response) => {
      this.context.commit(Mutations.SET_HISTORY, response.data);
    });
  }

  // @Action
  // [Actions.CREATE_NEW_HISTORY_ITEM](payload): any {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`history`, payload).then((response) => {
  //       if(response?.data?.id){
  //         this.context.dispatch(Actions.GET_HISTORY_ITEMS_BY_ID, payload.user);
  //         resolve(response);
  //       }else{
  //         reject(response);
  //       }
  //     });
  //   })
  // }
    
}