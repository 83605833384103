import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class ActionTypeModule extends VuexModule implements StoreInfo {

  actionstypesPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  actionstypesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  actionstypes = null
  action = null
  allActionsType = null

  /* --- GETTERS --- */

  get getAllActionsType() {
    // //console.log("getter actionstypes", this.actionstypes)
    return this.allActionsType;
  }


  get getActionsType() {
    // //console.log("getter actionstypes", this.actionstypes)
    return this.actionstypes;
  }


  get getActionType() {
    ////console.log("TASKSTYPE", this.action)
    return this.action;
  }

  get getActionsTypePagination() {
      // //console.log("getter 2", this.actionstypesPagination)
      return this.actionstypesPagination;
  }

  get getActionsTypeFilters() {
    return this.actionstypesFilters;
  }

  get getPageActionsType() {
    return this.actionstypesFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_ACTIONSTYPE_PAGINATION]({pagination, loading, error}) {
    // //console.log('departments mutation',payload);
    this.actionstypesPagination = {
      pagination: pagination,
      loading,
      error
    };
  }


  @Mutation
  [Mutations.SET_PAGE_PAGINATION_ACTIONSTYPE](payload) {
    this.actionstypesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_ACTIONSTYPE](payload) {
    ////console.log('mutation',payload);
    this.actionstypes = payload;
  }

  @Mutation
  [Mutations.SET_ACTIONSTYPE_BY_ID](payload) {
    ////console.log('mutation',payload);
    this.action = payload;
  }

  @Mutation
  [Mutations.SET_ALL_ACTIONSTYPE](payload) {
    ////console.log('mutation',payload);
    this.allActionsType = payload;
  }

  @Mutation
  [Mutations.SEARCH_ACTIONSTYPE]({payload, pagination, loading, error}) {
    ////console.log('mutation',payload);
    ////console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((action: any) => action.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.actionstypesPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_ACTIONSTYPE](payload) {
    this.actionstypesFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_ACTIONSTYPE_FILTERS](payload) {
    this.actionstypesFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }



  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_ACTIONSTYPE](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_ACTIONSTYPE, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.SEARCH_ACTIONSTYPE](payload) {
    ApiService.get(`actionstypes`)
      .then(({ data }) => {
        this.context.commit(Mutations.SEARCH_ACTIONSTYPE, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_ALL_ACTIONSTYPE](payload) {
    ApiService.get(`actionstypes/getallrecords`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_ACTIONSTYPE, 
         data
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_ACTIONSTYPE](payload) {
    this.context.commit(Mutations.FILTER_ACTIONSTYPE, payload);
    this.context.dispatch(Actions.GET_ACTIONSTYPE_WITH_PAGINATION)
  }


  @Action
  [Actions.GET_ACTIONSTYPE]() {
    // //console.log(this.actionstypesFilters.searchString)
    return ApiService.get(
      `actionstypes`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_TASKSTYPE, data)
        this.context.commit(Mutations.SET_ACTIONSTYPE, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ACTIONSTYPE_WITH_PAGINATION]() {
    // //console.log(this.actionstypesFilters.searchString)
    return ApiService.get(
      `actionstypes?page=${this.actionstypesFilters.page}&search=${this.actionstypesFilters.search}${this.actionstypesFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_TASKSTYPE, data)
        this.context.commit(Mutations.SET_ACTIONSTYPE_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_ACTIONSTYPE_BY_ID](payload) {
    //console.log(this.actionstypesFilters.searchString)
    return ApiService.get(`actionstypes/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ACTIONSTYPE_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_ACTIONSTYPE](payload) {
    const {id, action} = payload;

    return ApiService.put(`actionstypes/${id}`, action)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_ACTIONSTYPE_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_ACTIONSTYPE](payload) {

    return new Promise((resolve, reject) => {
      //console.log('crear action', payload)
     ApiService.post(`actionstypes`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_ACTIONSTYPE_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_ACTIONSTYPE](payload) {
    return ApiService.delete(`actionstypes/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_ACTIONSTYPE_WITH_PAGINATION);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_ACTIONSTYPE_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_ACTIONSTYPE_FILTERS, payload);
    this.context.dispatch(Actions.GET_ACTIONSTYPE);
  }

}
