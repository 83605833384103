import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class BillingModule extends VuexModule {
  billingItems = null;

  billingItem = null; 

  b2bbillingItemsPagination= {
    pagination: null,
    loading: true,
    error: null,
  };

  billingItemsPagination = {
    pagination: null,
    loading: true,
    error: null,
  };

  billingItemsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  billingClients = [];

  /* --- GETTERS --- */

  get getBillingItemsById(){
    return this.billingItem;
  }

  get getBillingItems(): any {
    return this.billingItemsPagination;
  }

  get getB2BBillingItems() : any {
    return this.b2bbillingItemsPagination;
  }

  get getBillingItemsFilters() {
    return this.billingItemsFilters;
  }

  get getBillingClients() {
    return this.billingClients;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_BILLING_BY_CUSTOMERFILE_ID](billingItem: any) {
    //console.log('billingItem',billingItem)
    this.billingItem = billingItem;
  }

  
  @Mutation
  [Mutations.SET_BILLING_ITEMS](billingItems: any) {
    this.billingItemsPagination = billingItems;
  }

  @Mutation
  [Mutations.SET_B2BBILLING_ITEMS_BY_PARTNER](b2bbillingClients: any){
    this.b2bbillingItemsPagination = b2bbillingClients
  }

  @Mutation
  [Mutations.SET_B2BBILLING_ITEMS_BY_MEDICAL_CENTER](b2bbillingClients: any){
    this.b2bbillingItemsPagination = b2bbillingClients
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMS](payload) {
    this.billingItemsFilters.page = payload.page;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMSB2B](payload) {
    this.billingItemsFilters.page = payload.page;
  }

  
  @Mutation
  [Mutations.FILTER_BILLING](payload) {
    // //console.log('FILTERmutation',payload);
    this.billingItemsFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_BILLINGB2B](payload) {
    // //console.log('FILTERmutation',payload);
    this.billingItemsFilters.searchString = payload
  }


  @Mutation
  [Mutations.SET_BILLING_CLIENTS](payload) {
    this.billingClients = payload;
  }

  
  @Mutation
  [Mutations.CLEAR_BILLING_FILTERS](payload) {
    this.billingItemsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }


  @Action({ rawError: true })
  [Actions.GET_B2BBILLING_ITEMS_BY_PARTNER](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `paymentsb2b/${payload.id}?limit=10&page=${this.billingItemsFilters.page}&search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}`
      ).then(({ data }) => {
        this.context.commit(Mutations.SET_B2BBILLING_ITEMS_BY_PARTNER, {
          pagination: data,
          loading: false,
          error: null,
        });
      
        resolve(data.data);
      });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_B2BBILLING_ITEMS_BY_MEDICAL_CENTER](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `paymentsb2b/${payload.id}?limit=10&page=${this.billingItemsFilters.page}&search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}`
      ).then(({ data }) => {
        this.context.commit(Mutations.SET_B2BBILLING_ITEMS_BY_MEDICAL_CENTER, {
          pagination: data,
          loading: false,
          error: null,
        });
      
        resolve(data.data);
      });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_BILLING_ITEMS](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `payments?limit=10&page=${this.billingItemsFilters.page}&search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}&b2b=false`
      ).then(({ data }) => {
        this.context.commit(Mutations.SET_BILLING_ITEMS, {
          pagination: data,
          loading: false,
          error: null,
        });
        let ids = "";
        data?.data?.forEach((element) => {
          ids += element.clientId + ",";
        });
        //console.log(ids);
        this.context.dispatch(Actions.GET_BILLING_CLIENTS, ids);
        resolve(data.data);
      });
    });
  }

  @Action({ rawError: true })
  [Actions.GET_BILLING_ITEMSB2B](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `payments?limit=10&page=${this.billingItemsFilters.page}&search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}&b2b=true`
      ).then(({ data }) => {
        //console.log('GET_BILLING_ITEMSB2B', data)
        this.context.commit(Mutations.SET_BILLING_ITEMS, {
          pagination: data,
          loading: false,
          error: null,
        });
        let ids = "";
        data?.data?.forEach((element) => {
          ids += element.clientId + ",";
        });
        //console.log(ids);
        this.context.dispatch(Actions.GET_BILLING_CLIENTS, ids);
        resolve(data.data);
      });
    });
  }

  
  @Action({ rawError: true })
  [Actions.GET_BILLING_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
     return ApiService.get(
      `orders/getallrecords?search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}&b2b=false`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_BILLING_ITEMS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }
  
  @Action({ rawError: true })
  [Actions.GET_BILLINGB2B_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
     return ApiService.get(
      `orders/getallrecords?search=${this.billingItemsFilters.search}${this.billingItemsFilters.searchString}&b2b=true`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_BILLING_ITEMS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }


  @Action({ rawError: true })
  [Actions.SET_PAGE_PAGINATIONS_BILLING_ITEMS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMS, payload);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }

  @Action({ rawError: true })
  [Actions.SET_PAGE_PAGINATIONS_BILLING_ITEMSB2B](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATIONS_BILLING_ITEMSB2B, payload);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }



  @Action
  [Actions.GET_BILLING_CLIENTS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`payments/users?id=${payload}`).then(({ data }) => {
        this.context.commit(Mutations.SET_BILLING_CLIENTS, data);
        resolve(data.data);
      });
    });
  }

  @Action
  [Actions.UPDATE_ORDER_STATUS](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`orders/updateStatus`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_FACTURADIRECTA_BY_ID](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`invoicepdf/${payload}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_ORDER_TAG](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`orders/tag`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_ORDER](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/delete`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.SET_PARTNER_ABONO](payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`orders/updateAbonadoPartner`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.CREATE_FACTURACION](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`orders/b2c`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_FACTURACION_TRAFICO](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`order/trafico/b2c`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action
  [Actions.CREATE_FACTURACIONB2B](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`createinvoice`, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.FILTER_BILLING](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_BILLING, payload);
    this.context.dispatch(Actions.GET_BILLING_ITEMS)
  }


  @Action({ rawError: true })
  [Actions.FILTER_BILLINGB2B](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_BILLINGB2B, payload);
    this.context.dispatch(Actions.GET_BILLING_ITEMSB2B)
  }


  @Action
  [Actions.GET_BILLING_BY_CUSTOMERFILE_ID](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`orders/customerfile/${payload._id}`)
      .then(({ data }) => {
          this.context.commit(Mutations.SET_BILLING_BY_CUSTOMERFILE_ID, data.data);
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  @Action
  [Actions.CLEAR_BILLING_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_BILLING_FILTERS, payload);
    // this.context.dispatch(Actions.GET_CONTACTS);
  }


}


