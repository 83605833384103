import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class CollaboratorsModule extends VuexModule implements StoreInfo {


  type = null; 

  companies = null;
  autoridades = null;
  siniestros = null;

  collaboratorsPagination = {
    pagination: [] || null,
    loading: true,
    error: null,

  };

  collaboratorsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  collaborators = null
  collaboratorsCompanies = null
  collaborator = null
  usersCollaborators = null;

  /* --- GETTERS --- */

  get getUsersCollaborators() {
    return this.usersCollaborators;
  }

  get getAutoridades() {
    return this.autoridades;
  }

  get getSiniestros() {
    return this.siniestros;
  }

  get getCompanies() {
    return this.companies
  }

  get getCollaborators() {
    return this.collaborators;
  }

  get getCollaboratorsCompanies() {
    return this.collaboratorsCompanies;
  }


  get getCollaborator() {
    return this.collaborator;
  }

  get getCollaboratorType() {
    return this.type;
  }

  get getCollaboratorsPagination() {
      return this.collaboratorsPagination;
  }

  get getCollaboratorsFilters() {
    return this.collaboratorsFilters;
  }

  get getPageCollaborators() {
    return this.collaboratorsFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_COLLABORATORS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.collaboratorsPagination = payload;
  }

  @Mutation
  [Mutations.SET_COMPANIES](payload) {
    this.companies = payload;
  }

  @Mutation
  [Mutations.SET_COLLABORATORS_TYPE](payload) {
    this.type = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_COLLABORATORS](payload) {
    this.collaboratorsFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_COLLABORATORS](payload) {
    this.collaborators = payload;
  }

  @Mutation
  [Mutations.SET_ALL_USERS_COLLABORATORS](payload) {
    this.usersCollaborators = payload;
  }

  @Mutation
  [Mutations.SET_AUTORIDADES](payload) {
    this.autoridades = payload;
  }

  @Mutation
  [Mutations.SET_SINIESTROS](payload) {
    this.siniestros = payload;
  }

  @Mutation
  [Mutations.SET_COLLABORATORS_COMPANIES](payload) {
    this.collaboratorsCompanies = payload;
  }

  @Mutation
  [Mutations.SET_SCRAPYARD_BY_ID](payload) {
    this.collaborator = payload;
  }

  @Mutation
  [Mutations.SEARCH_COLLABORATORS]({payload, pagination, loading, error}) {
    const filteredData = pagination?.data.filter((collaborator: any) => collaborator.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.collaboratorsPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_COLLABORATORS](payload) {
    this.collaboratorsFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_COLLABORATORS_FILTERS](payload) {
    this.collaboratorsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }


  /* --- ACTIONS --- */

  @Action
  [Actions.SET_COLLABORATORS_TYPE](payload) {
    if(payload.route != this.type || this.collaboratorsFilters.search != payload.search ){
      this.context.commit(Mutations.SET_COLLABORATORS_TYPE, payload.route);
      this.context.dispatch(Actions.GET_COLLABORATORS)
    } 

  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_COLLABORATORS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_COLLABORATORS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }

  @Action({ rawError: true })
  [Actions.SEARCH_COLLABORATORS](payload) {
    ApiService.get(`collaborators`)
      .then(({ data }) => {
        ////console.log('collaborators',data);
        this.context.commit(Mutations.SEARCH_COLLABORATORS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_COLLABORATORS](payload) {
    this.context.commit(Mutations.FILTER_COLLABORATORS, payload);
    this.context.dispatch(Actions.GET_COLLABORATORS)
  }


  @Action
  [Actions.GET_COLLABORATORS]() {
     ////console.log('collaboratorsFilters',this.collaboratorsFilters)
     return new Promise((resolve, reject) => {
    return ApiService.get(
      `collaborators?page=${this.collaboratorsFilters.page}&search=${this.collaboratorsFilters.search}${this.collaboratorsFilters.searchString}&type=${this.type}`
      //`collaborators?page=${this.collaboratorsFilters.page}&search=${this.collaboratorsFilters.search}${this.collaboratorsFilters.searchString}`
    )
      .then(({ data }) => {
        resolve(data)
        this.context.commit(Mutations.SET_COLLABORATORS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }


  //users/getallcollaborators
  @Action
  [Actions.GET_ALL_USERS_COLLABORATORS](type) {
    return new Promise((resolve, reject) => {
     return ApiService.get(
       `users/getallcollaborators`
     )
     .then(({ data }) => {
       this.context.commit(Mutations.SET_ALL_USERS_COLLABORATORS, data)
       resolve(data)
      // //console.log('GET_ALL_COLLABORATORS',data);
     })
     .catch(({ response }) => {
       this.context.commit(Mutations.SET_ERROR, response.data.errors);
       reject(response)
     });
   });
  }

  @Action
  [Actions.GET_ALL_COLLABORATORS](type) {
     return new Promise((resolve, reject) => {
      return ApiService.get(
        `collaborators/getallrecords?search=${this.collaboratorsFilters.search}${this.collaboratorsFilters.searchString}&type=${type}`
      )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COLLABORATORS, data)
        resolve(data)
       // //console.log('GET_ALL_COLLABORATORS',data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    });
  }

  @Action
  [Actions.GET_ALL_COLLABORATORS_COMPANIES]() {
     return new Promise((resolve, reject) => {
      return ApiService.get(
        `collaborators/getallrecords?search=${this.collaboratorsFilters.search}${this.collaboratorsFilters.searchString}&type=Compañias`
      )
      .then(({ data }) => {
        resolve(data)
       // //console.log('GET_ALL_COLLABORATORS',data);
        this.context.commit(Mutations.SET_COLLABORATORS_COMPANIES, data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_COLLABORATORS_TO_DOWNLOAD](type) {
     return new Promise((resolve, reject) => {
      return ApiService.get(
        `collaborators/getallrecords?search=${this.collaboratorsFilters.search}${this.collaboratorsFilters.searchString}&type=${type}`
      )
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_ALL_COMPANIES]() {
     return new Promise((resolve, reject) => {
    return ApiService.get(
      `collaborators/type/64cb781b75920c37575a1198`
    )
      .then(({ data }) => {
        ////console.log('GET_ALL_COMPANIES',data);
        this.context.commit(Mutations.SET_COMPANIES, data)
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_ALL_AUTORIDADES]() {
     return new Promise((resolve, reject) => {
    return ApiService.get(
      `collaborators/type/650ac8ca985e9d3be6969951`
    )
      .then(({ data }) => {
        ////console.log('GET_ALL_COMPANIES',data);
        this.context.commit(Mutations.SET_AUTORIDADES, data)
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }
  @Action
  [Actions.GET_ALL_SINIESTROS]() {
     return new Promise((resolve, reject) => {
    return ApiService.get(
      `collaborators/type/650ac308985e9d3be696994d`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SINIESTROS, data)
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.GET_COLLABORATOR_BY_ID](payload) {
    ////console.log(this.collaboratorsFilters.searchString)
    return ApiService.get(`collaborators/${payload}`)
      .then(({ data }) => {
        ////console.log('collaboratorsFilters',data);
        this.context.commit(Mutations.SET_SCRAPYARD_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_COLLABORATOR](payload) {
    return new Promise((resolve, reject) => {
    const {id, collaborator} = payload;
    const typeCollaborator =  payload.type 
    ////console.log('collaborator',collaborator);

    return ApiService.put(`collaborators/${id}`, collaborator)
      .then(({ data }) => {

        //console.log('typeCollaborator',payload)
       

          // this.context.dispatch(Actions.GET_ALL_COLLABORATORS, typeCollaborator)
          this.context.dispatch(Actions.GET_COLLABORATORS, typeCollaborator)
       
        resolve(data)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.PUT_CUSTOMERFILES_COLLABORATOR](payload) {
    return ApiService.put(`/customerfiles/collaborator`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_CUSTOMERFILES_BY_ID, payload.customerFileId)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_LEAD_COLLABORATOR](payload) {
    return ApiService.put(`/leads/collaborator`, payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_LEAD_BY_ID, payload.leadId)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_SCRAPYARD](payload) {

    ////console.log(payload);
    return new Promise((resolve, reject) => {
     ApiService.post(`collaborators`, payload)
      .then(({ data }) => {
        resolve(data)
        ////console.log('store gooood',data);
        this.context.dispatch(Actions.GET_COLLABORATORS)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        ////console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_SCRAPYARD](payload) {
    return ApiService.delete(`collaborators/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_COLLABORATORS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

    
  @Action({ rawError: true })
  [Actions.UPDATE_OBSERVATION_COLLABORATOR](payload) {
    const {id, observation} = payload;
    //console.log('payloadI',id)
    //console.log('payloadO',observation)
    return new Promise((resolve, reject) => {
      ApiService.put(`/collaborators/observation/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_COLLABORATOR_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

    
  @Action({ rawError: true })
  [Actions.DELETE_OBSERVATION_COLLABORATOR](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`collaborators/deleteobservation/${id}`,payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_COLLABORATOR_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

    
  @Action({ rawError: true })
  [Actions.GET_EXPEDIENT_COLLABORATORS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/collaborators/allcustomerfiles/${payload}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action
  [Actions.CLEAR_COLLABORATORS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_COLLABORATORS_FILTERS, payload);
    this.context.dispatch(Actions.GET_COLLABORATORS);
  }

 
}
