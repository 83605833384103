import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import Swal from "sweetalert2";

interface StoreInfo {

}

@Module
export default class DocumentationModule extends VuexModule implements StoreInfo {

  documents = {}

  documentsCount = 0
  delegatedPaymentDocuments = {}
  customerDocuments = {}

  /* --- GETTERS --- */

  get getDocuments(): any {
    return this.documents;
  }

  get getDelegatedPaymentDocuments(): any {
    return this.delegatedPaymentDocuments;
  }

  get getCustomerDocuments(): any {
    return this.customerDocuments;
  }

  get getDocumentsCount(): any {
    return this.documentsCount;
  }


  /* --- MUTATIONS --- */


  @Mutation
  [Mutations.SET_DOCUMENTS](payload) {
    this.documents = payload;
  }

  @Mutation
  [Mutations.SET_DOCUMENTS_COUNT](payload) {
    this.documentsCount = payload;
  }

  @Mutation
  [Mutations.SET_DELEGATED_PAYMENT_DOCUMENTS](payload) {
    this.delegatedPaymentDocuments = payload;
  }

  @Mutation
  [Mutations.SET_CUSTOMER_DOCUMENTS](payload) {
    this.customerDocuments = payload;
  }


  /* --- ACTIONS --- */

  @Action
  [Actions.GET_LEADS_DOCUMENTS_BY_ID](payload) {
    return ApiService.get(`lead/countdocumentation/${payload._id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENTS_COUNT, data);
      })
      .catch(({ response }) => {
        //console.log("error retrieving documents")
      });
  }

  @Action
  [Actions.GET_CUSTOMERFILES_DOCUMENTS_BY_ID](payload) {
    return ApiService.get(`customerfiles/countdocumentation/${payload._id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENTS_COUNT, data);
      })
      .catch(({ response }) => {
        //console.log("error retrieving documents")
      });
  }


  @Action
  [Actions.GET_CUSTOMERFILES_DOCUMENTS_DELEGATED_PAYMENT_BY_ID](payload) {
    return ApiService.get(`customerfiles/delegated-document/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DELEGATED_PAYMENT_DOCUMENTS, data);
      })
      .catch(({ response }) => {
        //console.log("error retrieving documents")
      });
  }

  @Action
  [Actions.GET_CUSTOMER_DOCUMENTS](payload) {
    return ApiService.get(`/documents/customer/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CUSTOMER_DOCUMENTS, data);
      })
      .catch(({ response }) => {
        //console.log("error retrieving documents")
      });
  }
  

  @Action({ rawError: true })
  [Actions.CREATE_RECOVERY_PAYMENT](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/customerfiles/recovery/${id}`, payload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_DERIVACIONES_ST);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_REITERATIONRECOVERY_PAYMENT](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/customerfiles/reiterationrecovery/${id}`, payload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_DERIVACIONES_ST);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_DELEGATED_PAYMENT](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`/customerfiles/delegatedpayment/${id}`, payload)
        .then(({ data }) => {
          //this.context.dispatch(Actions.GET_DERIVACIONES_ST);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action
  [Actions.GET_DOCUMENTS](payload) {
    return ApiService.get(`documents/get-by-client`, payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENTS, data);
      })
      .catch(({ response }) => {
        //console.log("error retrieving documents")
      });
  }

  @Action
  [Actions.DOWNLOAD_DOCUMENT](payload) {
    ApiService.get(`documents/download/${payload.id}/${payload._id}/${payload.documentName}/${payload.download}`)
      .then(({ data }) => {
        if(payload.download){
          window.open(
            data, "_blank");
        }else{
          window.open(
            data, "_blank");
        }
      })
      .catch(({ response }) => {
        //console.log("error downloading document")
      }
      );
  }

  @Action
  [Actions.PREVIEW_DOCUMENT](payload) {
    ApiService.get(`documents/preview/${payload.id}/${payload._id}/${payload.documentName}`)
      .then(({ data }) => {
        //console.log(data)
      })
      .catch((err) => {
        //console.log(err)
      }
      );
  }

  @Action
  [Actions.UPLOAD_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', payload.file);
      formData.append('_id', payload.id);
      formData.append('payload', payload);
      formData.append('origin', "internal");
      axios.post(process.env.VUE_APP_API_URL + '/documents/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
        resolve(response);
      })
        .catch(function () {
          reject();
        });
    }
    )
  }

 
  @Action
  [Actions.SEND_SHORTLINK](payload): any {
    const { customerId, ...restPayload } = payload
    //console.log(module)
    //console.log(restPayload)
    return new Promise((resolve, reject) => {
        ApiService.post(`/documents/send-shortlink`, restPayload).then((response) => {
          this.context.dispatch(Actions.GET_CUSTOMER_BY_ID, customerId);
          this.context.dispatch(Actions.GET_CONTACTS);
          this.context.dispatch(Actions.GET_CLIENTS);
          resolve(response);
        }).catch((error => {
          reject(error)
        }));
      
      
    })
  }

  
  @Action
  [Actions.RESEND_SHORTLINK](payload): any {
    const { customerId, ...restPayload } = payload
    //console.log(module)
    //console.log(restPayload)
    return new Promise((resolve, reject) => {
        ApiService.post(`/documents/resend-shortlink`, restPayload).then((response) => {
          this.context.dispatch(Actions.GET_CUSTOMER_BY_ID, customerId);
          this.context.dispatch(Actions.GET_CONTACTS);
          this.context.dispatch(Actions.GET_CLIENTS);
          resolve(response);
        }).catch((error => {
          reject(error)
        }));
      
      
    })
  }


  @Action
  [Actions.SEND_SHORTLINK_CUSTOMERFILES](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/shortlinkcustomerfiles`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }

  @Action
  [Actions.RENAME_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/documents/rename`, payload)
        .then(({ data }) => {
          if (data?.code == 1) {
            Swal.fire({
              title: 'Un documento con ese nombre ya existe',
              text: "¿Quieres sobreescribirlo?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sobreescribir'
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  'Guardado!',
                  'Se ha cambiado el nombre con éxito',
                  'success'
                )
                this.context.dispatch(Actions.RENAME_DOCUMENT, { ...payload, force: true })
              }
            });
          }
          this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }

  @Action
  [Actions.DELETE_DOCUMENT](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/documents/delete`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_DOCUMENTS, payload.id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  }
}