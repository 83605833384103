import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class MedicalCentersModule extends VuexModule implements StoreInfo {

  medicalCentersPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  medicalCentersFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  medicalCenters = null
  medicalCenter = null
  grupoCM = null;

  /* --- GETTERS --- */


  get getMedicalCenters() {
    return this.medicalCenters;
  }

  get getAllMedicalCenters() {
    return this.medicalCenters;
  }

  get getGrupoCM() {
    return this.grupoCM;
  }


  get getMedicalCenter() {
    return this.medicalCenter;
  }

  get getMedicalCentersPagination() {
      // //console.log("getter 2", this.medicalCentersPagination)
      return this.medicalCentersPagination;
  }

  get getMedicalCentersFilters() {
    return this.medicalCentersFilters;
  }

  get getPageMedicalCenters() {
    return this.medicalCentersFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_MEDICAL_CENTERS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.medicalCentersPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_MEDICAL_CENTERS](payload) {
    this.medicalCentersFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_MEDICAL_CENTERS](payload) {
    ////console.log('mutation',payload);
    this.medicalCenters = payload;
  }

  @Mutation
  [Mutations.SET_MEDICAL_CENTER_BY_ID](payload) {
    ////console.log('mutation',payload);
    this.medicalCenter = payload;
  }

  @Mutation
  [Mutations.SET_ALL_MEDICAL_CENTERS](payload) {
    ////console.log('mutation',payload);
    this.medicalCenters = payload;
  }


  @Mutation
  [Mutations.SEARCH_MEDICAL_CENTERS]({payload, pagination, loading, error}) {
    ////console.log('mutation',payload);
    ////console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((medicalCenter: any) => medicalCenter.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.medicalCentersPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_MEDICAL_CENTERS](payload) {
    ////console.log('FILTERmutation',payload);
    this.medicalCentersFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_MEDICAL_CENTERS_FILTERS](payload) {
    this.medicalCentersFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Mutation
  [Mutations.SET_GRUPOCM](payload) {
    this.grupoCM = payload
  }
  // @Mutation
  // [Mutations.DELETE_MEDICAL_CENTER](payload) {
  //   this.medicalCentersPagination.pagination = this.medicalCentersPagination.pagination.filter((medicalCenter: any) => medicalCenter._id !== payload)
  //   //console.log('deletemutation', payload)
  // }


  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_MEDICAL_CENTERS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_MEDICAL_CENTERS, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.GET_GRUPOCM](payload) {
    return new Promise((resolve, reject) => {
      // //console.log(this.medicalCentersFilters.searchString)
      return ApiService.get(
        `healthcares/groupcm`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GRUPOCM, data);
          resolve(data)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject(response)
        });
      })
  }

  @Action({ rawError: true })
  [Actions.SEARCH_MEDICAL_CENTERS](payload) {
    ApiService.get(`healthcares`)
      .then(({ data }) => {
        ////console.log(data);
        this.context.commit(Mutations.SEARCH_MEDICAL_CENTERS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_MEDICAL_CENTERS](payload) {
    this.context.commit(Mutations.FILTER_MEDICAL_CENTERS, payload);
    this.context.dispatch(Actions.GET_MEDICAL_CENTERS)
  }

//   @Action
//   [Actions.GET_CONTACT_BY_ID](payload) {
//     return ApiService.get(`contact`, payload)
//       .then(({ data }) => {
//         this.context.commit(Mutations.SET_CONTACT, data);
//       })
//       .catch(({ response }) => {
//         this.context.commit(Mutations.SET_ERROR, response.data.errors);
//       });
//   }

  @Action
  [Actions.GET_MEDICAL_CENTERS_TO_DOWNLOAD]() {
    return new Promise((resolve, reject) => {
    // //console.log(this.medicalCentersFilters.searchString)
    return ApiService.get(
      `healthcares/getallrecords?search=${this.medicalCentersFilters.search}${this.medicalCentersFilters.searchString}`
    )
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

  @Action
  [Actions.GET_ALL_MEDICAL_CENTERS]() {
    return new Promise((resolve, reject) => {
    // //console.log(this.medicalCentersFilters.searchString)
    return ApiService.get(
      `healthcares/getallrecords?search=${this.medicalCentersFilters.search}${this.medicalCentersFilters.searchString}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_MEDICAL_CENTERS, data);

        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

  @Action
  [Actions.GET_MEDICAL_CENTERS]() {
    // //console.log(this.medicalCentersFilters.searchString)
    return ApiService.get(
      `healthcares?page=${this.medicalCentersFilters.page}&search=${this.medicalCentersFilters.search}${this.medicalCentersFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_MEDICAL_CENTERS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_MEDICAL_CENTER_BY_ID](payload) {
    //console.log(this.medicalCentersFilters.searchString)
    return ApiService.get(`healthcares/${payload}`)
      .then(({ data }) => {
        //console.log(data);
        this.context.commit(Mutations.SET_MEDICAL_CENTER_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_MEDICAL_CENTER](payload) {
    return new Promise((resolve, reject) => {
    const {id, medicalCenter} = payload;
    //console.log(medicalCenter);

    return ApiService.put(`healthcares/${id}`, medicalCenter)
      .then(({ data }) => {
        resolve(data)
        this.context.dispatch(Actions.GET_MEDICAL_CENTERS)
      })
      .catch(({ response }) => {
        reject(response)
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    })
  }

  @Action
  [Actions.CREATE_MEDICAL_CENTER](payload) {

    //console.log(payload);
    return new Promise((resolve, reject) => {
     ApiService.post(`healthcares`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_MEDICAL_CENTERS)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  
  @Action({ rawError: true })
  [Actions.GET_EXPEDIENT_MEDICAL_CENTERS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/healthcares/allcustomerfiles/${payload}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  
  @Action({ rawError: true })
  [Actions.UPDATE_OBSERVATION_MEDICAL_CENTER](payload) {
    const {id, observation} = payload;
    //console.log('payloadI',id)
    //console.log('payloadO',observation)
    return new Promise((resolve, reject) => {
      ApiService.put(`/healthcares/observation/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_MEDICAL_CENTER_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  
  @Action({ rawError: true })
  [Actions.DELETE_OBSERVATION_MEDICAL_CENTER](payload) {
    const {id} = payload;
    return new Promise((resolve, reject) => {
      ApiService.put(`healthcares/deleteobservation/${id}`,payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_MEDICAL_CENTER_BY_ID, id);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action
  [Actions.DELETE_MEDICAL_CENTER](payload) {
    return ApiService.delete(`healthcares/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_MEDICAL_CENTERS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_MEDICAL_CENTERS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_MEDICAL_CENTERS_FILTERS, payload);
    this.context.dispatch(Actions.GET_MEDICAL_CENTERS);
  }

  // @Action
  // [Actions.CREATE_CONTACT](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`lead`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }

  // @Action
  // [Actions.RESEND_PAYLINK](payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`orders/resendLink`, payload)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         reject(response.data.errors);
  //       });
  //   });
  // }
}
