enum Actions {
  SEND_RP = "sendRPAction",
  SEND_RRP = "sendReiteracionRPAction",
  GET_ALL_USERS_COLLABORATORS = "getAllUsersCollaboratorsAction", 
  SEND_SIGN_TRAFICO = "sendSignTrafico",
  CREATE_FACTURACION_TRAFICO = "createOrderTraficoAction",
  UPDATE_IJUDICIAL = "updateIjudicialAction",
  GET_CUSTOMER_DOCUMENTS = "getCustomerDocumentsAction",
  CREATE_DELEGATED_PAYMENT = "createDelegatedPaymentAction",
  CREATE_RECOVERY_PAYMENT = "createRecoveryPaymentAction",
  CREATE_REITERATIONRECOVERY_PAYMENT = "createReiterationRecoveryAction",
  ASSIGN_DERIVACIONES_ST = "AssignDerivationAction",
  GET_CUSTOMERFILES_DOCUMENTS_DELEGATED_PAYMENT_BY_ID ="getDelegatedPaymentDocumentsAction", 
  SET_OFFER_ST = "setOfferSTAction",
  UPDATE_DERIVACIONES_ST_PROVIDER = "updateDerivacionesStProviderAction",
  REQUEST_DERIVACIONES_ST = "requestDerivacionesStAction",
  UPDATE_DERIVACIONES_ST_TAG = "updateDerivacionesStTagAction",
  DELETE_DERIVACIONES_ST = "deleteDerivacionesStActionAction", 
  CLEAR_DERIVACIONES_ST_FILTERS = "clearDerivacionesSTFiltersAction", 
  SET_PAGE_PAGINATIONS_DERIVACIONES_ST = "setPagePaginationDerivacionesSTAction", 
  FILTER_DERIVACIONES_ST = "filterDerivacionesSTAction", 
  GET_DERIVACIONES_ST = "getDerivacionesSTAction", 
  GET_DERIVACIONES_ST_TO_DOWNLOAD = "getDerivacionesToDownloadAction", 
  GET_DERIVACIONES_ST_BY_CUSTOMERFILE_ID = "getDerivacionesSTByCustomerFilesAction", 
  GET_DERIVACIONES_ST_BY_ID = "getDerivacionesStById", 

  GET_ALL_SINIESTROS = "getAllSiniestrosAction",

  GET_ATESTADOS_BY_ID = "getAtestadosByIdAction",
  CLEAR_ATESTADOS_FILTERS = "clearAtestadosFilterAction",
  GET_ATESTADOS = "getAtestadosAction",
  SET_PAGE_PAGINATIONS_ATESTADOS = "setPageAtestadosAction",
  FILTER_ATESTADOS = "filterAtestadosAction",
  DELETE_ATESTADO = "deleteAtestadoAction",
  GET_ATESTADOS_TO_DOWNLOAD = "getAtestadoToDownloadAction",
  REQUEST_ATESTADO = "requestAtestadoAction",
  UPDATE_ATESTADO_TAG = "updateAtestadoTagAction",
  GET_DERIVACIONES_CM_BY_ID = "getDerivacionesCmByIdAction",
  DERIVATE_CM = "postDerivacionesCmAction",
  GET_GRUPOCM = "getGrupoCMAction",
  UPDATE_DERIVACIONES_CM_GROUP = "updateDerivacionesCmGroupAction",
  ASSIGN_DERIVACIONES_CM = "updateDerivacionesCmAction",
  UPDATE_DERIVACIONES_CM_TAG = "updateDerivacionesCmTagAction",
  DELETE_DERIVACIONCM = "deleteDerivacionCmAction",
  FILTER_DERIVACIONES_CM = "filterDerivacionesCmAction",
  GET_DERIVACIONES_CM = "getDerivacionesCmAction",
  GET_DERIVACIONES_CM_TO_DOWNLOAD = "getDerivacionesCmDownloadAction",
  SET_PAGE_PAGINATIONS_DERIVACIONES_CM = "setPageDerivacionesCmAction",
  GET_ATESTADOS_BY_CUSTOMERFILE_ID = "atestadoByCustomerFileIdAction",

  CLEAR_DERIVACIONES_CM_FILTERS = "clearDerivacionesCmFilter",
  // action types
  GET_ALL_MEDICAL_CENTERS = "getAllMedicalCentersAction",
  GET_ALL_TASKSTYPE = "getAllTasksTypeAction",
  SET_PAGE_PAGINATIONS_TASKSTYPE = "setPagePaginationsTasksTypeAction",
  GET_TASKSTYPE = "getTasksTypeAction",
  CLEAR_TASKSTYPE_FILTERS = "clearTasksTypeFiltersAction",
  SEARCH_TASKSTYPE ="searchTasksTypeAction",
  FILTER_TASKSTYPE ="filterTasksTypeAction",
  GET_TASKSTYPE_WITH_PAGINATION="getTasksTypeWithPagination",
  DELETE_TASKSTYPE = "deleteTasksTypeAction",
  CREATE_TASKSTYPE = "createTasksTypeAction", 
  PUT_TASKSTYPE = "putTasksTypeByIdAction",
  GET_TASKSTYPE_BY_ID = "getTasksTypeByIdAction", 
  SET_TASKSTYPE_BY_ID = "setTasksTypeByIdAction",

  GET_ALL_ACTIONSTYPE = "getAllActionsTypeAction",
  SET_PAGE_PAGINATIONS_ACTIONSTYPE = "setPagePaginationsActionsTypeAction",
  GET_ACTIONSTYPE = "getActionsTypeAction",
  CLEAR_ACTIONSTYPE_FILTERS = "clearActionsTypeFiltersAction",
  SEARCH_ACTIONSTYPE ="searchActionsTypeAction",
  FILTER_ACTIONSTYPE ="filterActionsTypeAction",
  GET_ACTIONSTYPE_WITH_PAGINATION="getActionsTypeWithPagination",
  DELETE_ACTIONSTYPE = "deleteActionsTypeAction",
  CREATE_ACTIONSTYPE = "createActionsTypeAction", 
  PUT_ACTIONSTYPE = "putActionsTypeByIdAction",
  GET_ACTIONSTYPE_BY_ID = "getActionsTypeByIdAction", 
  SET_ACTIONSTYPE_BY_ID = "setActionsTypeByIdAction",

  SEND_SEPA = "sendSepaAction",
  GET_ALL_TASKTYPES = "getAllTaskTypesAction",
  GET_ALL_ACTIONSTYPES = "getAllActionsTypesAction",
  DELETE_AUDIT = "deleteAuditAction",
  UPDATE_AUDIT_TAG = "updateAuditTagAction",
  UPDATE_AUDIT_SEPA = "updateAuditSepaAction",
  FILTER_AUDITS = "getFilterAuditsAction",
  GET_AUDITS_TO_DOWNLOAD = "getAuditsToDownloadAction",
  GET_COLLABORATOR_USERS_TO_DOWNLOAD = "getCollaboratorUsersToDownloadAction",
  CLEAR_AUDITS_FILTERS = "clearAudtisFiltersAction",
  SET_PAGE_PAGINATIONS_AUDITS = "setPagePaginationAuditAction",
  GET_AUDITS = 'getAuditsAction',
  SEND_AUDIT_DERIVATION = 'sendDerivationAuditAction',
  SET_AUDIT_DATE = 'setAuditDateAction',
  FILTER_BILLINGB2B =  'filterBillingB2BAction', 
  GET_PERMISSION_BY_ID = 'getPermissionById',
  GET_CUSTOMERFILE_BY_LEAD_ID = 'getCustomerFileByLeadIdAction',
  GET_ALL_COMPANIES = "getAllCompaniesAction", 
  GET_ALL_AUTORIDADES = "getAllAutoridadesAction", 
  GET_FACTURADIRECTA_BY_ID = "getFacturaDirectaByOrederIdAction", 
  GET_B2BBILLING_ITEMS_BY_PARTNER = "getb2bBillingItemsByPartner",
  GET_B2BBILLING_ITEMS_BY_MEDICAL_CENTER = "getb2bBillingItemsByMedicalCenter",
  CREATE_FACTURACIONB2B = "setFacturacionB2Baction",
  GET_BILLING_ITEMSB2B = "getBillingItemsb2bAction",
  SET_PAGE_PAGINATIONS_PARTNER ="setPagePartnerExpedientesAction",
  SET_PAGE_PAGINATIONS_MEDICAL_CENTER ="setPageMedicalCenterExpedientesAction",
  SET_PAGE_PAGINATIONS_COLLABORATOR ="setPageCollaboratorExpedientesAction",
  FILTER_CUSTOMERFILES_PARTNER = "setCustomerFilesFilterPartnerAction", 
  FILTER_CUSTOMERFILES_MEDICAL_CENTER = "setCustomerFilesFilterMedicalCenterAction", 
  FILTER_CUSTOMERFILES_COLLABORATOR = "setCustomerFilesFilterCollaboratorAction",
  GET_ALL_TAGS = "getAllTagsAction",
  GET_PARTNER_USERS = "getPartnerUsersAction",
  GET_ALL_USERS = "getAllUsersAction",
  GET_ALL_PARTNERS = "getAllPartnersAction",
  UPDATE_DATOS_LEAD="putDatosLead",
  SIGN_VALIDATE_LEAD="putSignValidateLead",
  GET_CONTACTS_TO_DOWNLOAD="getContactsToDownloadAction",
  GET_EXPEDIENT_PARTNERS_TO_DOWNLOAD="getExpedientPartnersToDownloadAction",
  GET_EXPEDIENT_MEDICAL_CENTERS_TO_DOWNLOAD="getExpedientMedicalCentersToDownloadAction",
  GET_EXPEDIENT_COLLABORATORS_TO_DOWNLOAD="getExpedientCollaboratorsToDownloadAction",
  GET_PARTNERS_TO_DOWNLOAD="getPartnersToDownload",
  GET_COLLABORATORS_TO_DOWNLOAD="getCollaboratorsToDownload",
  GET_MEDICAL_CENTERS_TO_DOWNLOAD="getMedicalCentersToDownload",
  GET_CLIENTS_TO_DOWNLOAD="getClientsToDownload",
  GET_TASKS_TO_DOWNLOAD="getTasksToDownload",
  GET_BILLING_TO_DOWNLOAD="getBillingToDownload",
  GET_BILLINGB2B_TO_DOWNLOAD="getBillingB2bToDownload",
  UPDATE_MASSIVE_LEAD="putMassiveLeadAction",
  CREATE_LEAD="postCreateLeadAction",
  CANCEL_AUDIT="cancelAudit",
  UPDATE_AUDIT_DATA="updateAuditData",
  CREATE_TASK_AND_ACTION_ASSOCIATEDS="createTasksActionsAssociateds",


  GET_LEADS_DOCUMENTS_BY_ID = "getLeadsDocumentsCountByIdAction",
  GET_CUSTOMERFILES_DOCUMENTS_BY_ID = "getCustoimerFilesDocumentsCountByIdAction",
  GET_CUSTOMERFILES_PARTNER = "getCustomerFilesPartnerAction",
  GET_CUSTOMERFILES_MEDICAL_CENTER = "getCustomerFilesMedicalCenterAction",
  GET_CUSTOMERFILES_COLLABORATOR = "getCustomerFilesCollaboratorAction",
  GET_BILLING_BY_CUSTOMERFILE_ID = "getBillingItemAction",
  CREATE_FACTURACION = "createFacturacionAction",
  CLEAR_TASKS_FILTERS = "clearTaskFilterAction",
  TODO_TASK = "todoTasksAction",
  SET_PAGE_PAGINATIONS_TASKS = "setPagePaginationTasksAction",
  SEARCH_TASKS ="searchTasksAction", 
  FILTER_TASKS = "filterTasksAction",
  FILTER_TASKS_BY_ID = "filterTasksByIdAction",
  GET_TASKS_WITH_PAGINATION = "getTasksWithPaginationAction",
  GET_TASK_WIDGET = "getTaskWidgetsAction",

  SET_COLLABORATORS_TYPE = "setCollaboratorTypeAction",
  GET_COLABORATORS_TYPE_BY_ID = "getColabotarorsById",
  GET_COLABORATORS_TYPE_WITH_PAGINATION = "getColabotarorsWithPagination",
  GET_ACTIONSTYPES = "getActionsTypesAction",
  EDITTING_PERMISSIONS = "edittingPermissions",
  CLONE_PERMISSIONS = "clonePermissions",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  UPDATE_COLLABORATOR = "updateCollaborator",
  PUT_CUSTOMERFILES_COLLABORATOR= "putCustomerfilesCollaborator",
  PUT_LEAD_COLLABORATOR= "putLeadCollaborator",
  ADD_PAYMENT = "addPayment",
  UPDATE_OBSERVATION_LEAD = "updateObservationLead",
  DELETE_OBSERVATION_LEAD = "deleteObservationLead",
  UPDATE_OBSERVATION_PARTNER="updateObservationPartner",
  DELETE_OBSERVATION_PARTNER="deleteObservationPartner",
  UPDATE_OBSERVATION_MEDICAL_CENTER="updateObservationMedicalCenter",
  UPDATE_OBSERVATION_COLLABORATOR="updateObservationCollaborator",
  DELETE_OBSERVATION_MEDICAL_CENTER="deleteObservationMedicalCenter",
  DELETE_OBSERVATION_COLLABORATOR="deleteObservationCollaborator",
  UPDATE_OBSERVATION_CUSTOMERFILES = "updateObservationCustomerFiles",
  DELETE_OBSERVATION_CUSTOMERFILES = "deleteObservationCustomerFiles",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  SET_AUTH = "setAuthAction",
  GET_CONTACTS = "getContactsAction",
  GET_CONTACTS_BY_MANAGERCTI = "getContactsManagerctiAction",
  GET_CUSTOMERFILES_BY_OWNER = "getCustomerfilesOwnerAction",
  DELETE_NEWS_FEED = "deleteNewsFeedAction",
  SET_PAGE_PAGINATIONS_CONTACTS = "setPagePaginationsContactsAction",
  SET_PAGE_PAGINATIONS_CLIENTS = "setPagePaginationsClientsAction",
  SET_PAGE_PAGINATIONS_USERS = "setPagePaginationsUsersAction",
  SET_PAGE_PAGINATIONS_CUSTOMERS = "setPagePaginationsCustomersAction",
  SET_PAGE_PAGINATIONS_COLLABORATORS = "setPagePaginationsCollaboratorsAction",
  SET_PAGE_PAGINATIONS_BLOCKEDIPS = "setPagePaginationsBlockedIpsAction",
  SET_PAGE_PAGINATIONS_SERVICE_TYPES = "setPagePaginationsServiceTypesAction",
    SET_PAGE_PAGINATIONS_TAGS = "setPagePaginationsTagsAction",
    SET_PAGE_PAGINATIONS_ROLES = "setPagePaginationsRolesAction",
    SET_PAGE_PAGINATIONS_STATUSES = "setPagePaginationsStatusesAction",
  SET_PAGE_PAGINATIONS_MEDICAL_CENTERS = "setPagePaginationsMedicalCentersAction",
  SET_PAGE_PAGINATIONS_PARTNERS = "setPagePaginationsPartnersAction",
  GET_DOCUMENTS = "getDocumentsAction",
  PREVIEW_DOCUMENT = "previewDocumentAction",
  DOWNLOAD_DOCUMENT = "downloadDocumentAction",
  DELETE_DOCUMENT = "deleteDocumentAction",
  UPLOAD_DOCUMENT = "uploadDocumentAction",
  GET_CUSTOMER_BY_ID = "getCustomerByIdAction",
  GET_SERVICE_TYPE_BY_ID = "getServiceTypesByIdAction",
  GET_LEAD_BY_ID = "getLeadByIdAction",
  GET_CUSTOMERFILES= "getCustomerFileAction",
  GET_INTERN_USERS = "getInternUsersAction",
  GET_USERS = "getUsersAction", 
  GET_CUSTOMERS = "getCustomersAction", 
  GET_COLLABORATOR_TYPESNAMES = "getCollaboratorTypesnamesAction", 
  GET_COLLABORATORS = "getCollaboratorsAction",
  GET_ALL_COLLABORATORS_COMPANIES = "getAllCollaboratorsCompaniesAction",
  GET_ALL_COLLABORATORS = "getAllCollaboratorsAction",
  GET_HISTORY = "getHistoryAction",
  GET_HISTORY_BY_LEAD = "getHistoryByLeadAction",
  GET_BLOCKEDIPS = "getBlockedIpsAction",
  GET_ASSOCIATEDS = "getAssociatedsAction",
  GET_DESIGNA_CONTACT = "getDesignaContact",
  GET_SERVICE_TYPES = "getServiceTypesAction",
    GET_TAGS = "getTagsAction",
    GET_STATUSES = "getStatusesAction",
    GET_ALL_STATUSES = "getAllStatusesAction",
    SET_PAGE_PAGINATIONS_DEPARTMENTS = "setPagePaginationsDepartmentsAction",
  GET_CUSTOMERFILES_BY_ID = "getCustomerFilesByIdAction",
  GET_MEDICAL_CENTERS = "getMedicalCentersAction",
  GET_MEDICAL_CENTER_USERS = "getMedicalCentersUsersAction",
  GET_TASKTYPES = "getTasktypessAction",
  GET_FEED_ITEMS_BY_ID = "getFeedItemsByIdAction",
  FILTER_FEED_ITEMS_BY_ID = "filterFeedItemsByIdAction",
  CREATE_NEW_FEED_ITEM = "createNewFeedItemAction",
  UPDATE_STATUS = "updateStatusAction",
  UPDATE_TAG = "updateTagAction",
  UPDATE_TAG_MASSIVE = "updateTagMassiveAction",
  UPDATE_OWNER = "updateOwnerAction",
  UPDATE_LEAD_OWNER = "updateLeadOwnerAction",
  UPDATE_LEAD_MANAGER_CTI = "updateManagerCTIAction",
  UPDATE_LEAD_MANAGER_CTI_MASSIVE = "updateManagerCTIMassiveAction",
  SEND_CUSTOM_SMS = "sendCustomSmsAction",
  DELETE_LEAD = "deleteLeadAction",
  DELETE_CUSTOMERFILES = "deleteCustomerfilesAction",
  SEND_DESIGNA_SMS = "sendDesignaSmsAction",
  UPDATE_CONTACT = "updateContactAction",
  GET_PARTNERS = "getPartnersAction",
  GET_ALL_DEPARTMENTS = "getAllDepartmentsAction",
  GET_DEPARTMENT = "getDepartmentAction",
  GET_ROLES = "getRolesAction",
  GET_PERMISSIONS = "getPermissionsAction",
  CLEAR_ROLES = "deletePermissionsAction",
  SET_PERMISSIONS = "setPermissionsAction",
  UPDATE_PARTNER = "updatePartnerAction",
  UPDATE_LEAD_PARTNER = "updateLeadPartnerAction",
  UPDATE_LEAD_OPPOSITE ="updateLeadOppositeAction",
  UPDATE_CUSTOMERFILES_OPPOSITE ="updateCustomerFilesOppositeAction",
  UPDATE_LEAD_PROCESS ="updateLeadProcessAction",
  UPDATE_LEAD_QUIZ ="updateLeadQuizAction",
  UPDATE_CUSTOMERFILES_PROCESS ="updateCustomerFilesProcessAction",
  GET_CLIENTS = "getClientsAction",
  CREATE_ORDER = "sendPaylinkAction",
  SEND_SHORTLINK = "sendShortlinkAction",
  RESEND_SHORTLINK = "resendShortlinkAction",
  SEND_SHORTLINK_CUSTOMERFILES = "sendShortlinkCustomerFilesAction",
  GET_PAYMENTS_BY_ID = "getPaymentsByIdAction",
  RENAME_DOCUMENT = "renameDocumentAction",
  GET_NOTIFICATIONS = "getNotificationsAction",
  RESEND_PAYLINK = "resendPaylinkAction",

  UPDATE_STATUS_PARTNER = "updatePartnerStatusAction",
  UPDATE_OWNER_PARTNER = "updatePartnerOwnerAction",

  UPDATE_STATUS_LEGAL = "updateLegalStatusAction",

  GET_BILLING_ITEMS = "getBillingItemsAction",
  SET_PAGE_PAGINATIONS_BILLING_ITEMS = "setPagePaginationsBillingItemsAction",
  SET_PAGE_PAGINATIONS_BILLING_ITEMSB2B = "setPagePaginationsBillingItemsB2BAction",
  DELETE_ORDER = "deleteOrderAction",
  SET_PARTNER_ABONO = "setPartnerAbonoAction",

  GET_BILLING_CLIENTS = "getBillingUsersAction",
  UPDATE_ORDER_STATUS = "setOrderStatusAction",
  UPDATE_ORDER_TAG = "setOrderTagAction",

  CLEAR_CONTACTS_FILTERS = "clearContactsFiltersAction",
  CLEAR_CLIENTS_FILTERS = "clearClientsFiltersAction",
  CLEAR_USERS_FILTERS = "clearUsersFiltersAction",
  CLEAR_CUSTOMERS_FILTERS = "clearCustomersFiltersAction",
  CLEAR_BILLING_FILTERS = "clearBillingFiltersAction",
  CLEAR_COLLABORATORS_FILTERS = "clearCollaboratorsFiltersAction",
  CLEAR_BLOCKEDIPS_FILTERS = "clearBlockedIpsFiltersAction",
  CLEAR_ROLES_FILTERS = "clearRolesFiltersAction",
  CLEAR_TAGS_FILTERS = "clearTagsFiltersAction",
  CLEAR_DEPARTMENTS_FILTERS = "clearDepartmentsFiltersAction",
  CLEAR_STATUSES_FILTERS = "clearStatusesFiltersAction",
  CLEAR_PARTNERS_FILTERS = "clearPartnersFiltersAction",
  CLEAR_SERVICE_TYPES_FILTERS = "clearServiceTypesFiltersAction",
  CLEAR_MEDICAL_CENTERS_FILTERS = "clearMedicalCentersFiltersAction",

  CREATE_CONTACT = "createContactAction",
  CREATE_ASSOCIATED_CONTACT = "createAssociatedContactAction",

  DELETE_USER = "deleteUserAction",
  DELETE_CUSTOMER = "deleteCustomerAction",
  DELETE_SCRAPYARD = "deleteCollaboratorAction",
  DELETE_BLOCKEDIP = "deleteBlockedIpAction",
  DELETE_SERVICE_TYPE = "deleteServiceTypeAction",
    DELETE_TAG = "deleteTagAction",
    DELETE_ROL = "deleteRolAction",
    DELETE_DEPARTMENT = "deleteDepartmentAction",
    DELETE_STATUS = "deleteStatusAction",
  DELETE_MEDICAL_CENTER = "deleteMedicalCenterAction",
  DELETE_PARTNER = "deletePartnerAction",
  PUT_USER = "putUserAction",
  PUT_CUSTOMER = "putCustomerAction",
  PUT_COLLABORATOR = "putCollaboratorAction",
  PUT_SERVICE_TYPE = "putServiceTypeAction",
    PUT_TAG = "putTagAction",
    PUT_ROL = "putRolAction",
    PUT_STATUS = "putStatusAction",
  PUT_MEDICAL_CENTER = "putMedicalCenterAction",
  PUT_PARTNER = "putPartnerAction",
  CREATE_USER= "postUserAction",
  CREATE_CUSTOMER= "postCustomerAction",
  CREATE_PARTNER= "postPartnerAction",
  CREATE_SCRAPYARD= "postCollaboratorAction",
  CREATE_BLOCKEDIP= "postBlockedIpAction",
  CREATE_SERVICE_TYPE= "postServiceTypeAction",
    CREATE_TAG= "postTagAction",
    CREATE_STATUS= "postStatusAction",
  CREATE_MEDICAL_CENTER= "postMedicalCenterAction",
  CREATE_ROLE= "postRoleAction",
  SEARCH_USERS ="searchUsersAction",
  SEARCH_CUSTOMERS ="searchCustomersAction",
  SEARCH_COLLABORATORS ="searchCollaboratorsAction",
  SEARCH_BLOCKEDIPS ="searchBlockedIpsAction",
  SEARCH_SERVICE_TYPES ="searchServiceTypesAction",
    SEARCH_TAGS ="searchTagsAction",
    SEARCH_STATUSES ="searchStatusesAction",
  SEARCH_MEDICAL_CENTERS ="searchMedicalCentersAction",
  FILTER_USERS ="filterUsersAction",
  FILTER_CONTACTS ="filterContactsAction",
  FILTER_CUSTOMERS ="filterCustomersAction",
  FILTER_BILLING ="filterBillingAction",
  FILTER_CUSTOMERFILES ="filterCustomerfilesAction",
  FILTER_PARTNERS ="filterPartnersAction",
  FILTER_COLLABORATORS ="filterCollaboratorsAction",
  FILTER_BLOCKEDIPS ="filterBlockedIpsAction",
  FILTER_SERVICE_TYPES ="filterServiceTypesAction",
    FILTER_TAGS ="filterTagssAction",
    FILTER_STATUSES ="filterStatusessAction",
  FILTER_MEDICAL_CENTERS ="filterMedicalCenterAction",
  GET_USER_BY_ID ="getUserByIdAction",
  GET_PARTNER_BY_ID ="getPartnerByIdAction",
  GET_COLLABORATOR_BY_ID ="getCollaboratorByIdAction",
    GET_TAG_BY_ID ="getTagByIdAction",
    GET_STATUS_BY_ID ="getStatusByIdAction",
  GET_MEDICAL_CENTER_BY_ID ="getMedicalCenterByIdAction",
  GET_PARTNERS_WITH_PAGINATION="getPartnersWithPagination",
  GET_DEPARTMENTS_WITH_PAGINATION="getDepartmentsWithPagination",
  GET_TAGS_WITH_PAGINATION="getTagsWithPagination",
  GET_SERVICE_TYPES_WITH_PAGINATION="getServiceTypesWithPagination",
  GET_ROLES_WITH_PAGINATION="getRolesWithPagination",
  SET_LOADING_TRUE_USERS= "setLoadingTrueUsersAction",
  SET_LOADING_TRUE_CONTACTS= "setLoadingTrueContactsAction",
  SET_LOADING_TRUE_CUSTOMERS= "setLoadingTrueCustomersAction",
  SET_LOADING_TRUE_CUSTOMERFILES= "setLoadingTrueCustomerfilesAction",
  SET_LOADING_TRUE_PARTNERS= "setLoadingTruePartnersAction",
  PUT_USER_PASSWORD="putUserPasswordAction",
  UPDATE_CUSTOMERFILE="updateCustomerFileAction",
  GET_TASKS_LEAD_BY_ID="getTasksLeadByIDAction",
  GET_TASKS_CUSTOMERFILE_BY_ID="getTaskscustomerFileByIDAction",
  GET_TASKS="getTasksAction",
  CREATE_NEW_TASK="createNewTaskAction",
  EDIT_TASK="editTaskAction",
}

enum Mutations {
  SET_ALL_USERS_COLLABORATORS = "setAllUsersCollaboratorsMutation", 
  SET_CUSTOMER_DOCUMENTS = "setCustomerDocumentsMutation",
  SET_DELEGATED_PAYMENT_DOCUMENTS = "setDelegatedPaymentDocumentsMutation",
  SET_SINIESTROS = "setSinistrosMutation",
  FILTER_DERIVACIONES_ST = "filterDerivacionesStMutation",
  SET_DERIVACIONES_ST = "setDerivacionesSTMutation",
  SET_DERIVACIONES_ST_PAGINATION = "setDerivacionesStPaginationMutation",
  CLEAR_DERIVACIONES_ST_FILTERS = "clearDerivacionesSTFiltersMutation",
  SET_PAGE_PAGINATION_DERIVACIONES_ST = "setPagePaginationSTMutation", 

  CLEAR_ATESTADOS_FILTERS = "clearAtestadosFiltersMutation",
  SET_ATESTADOS_PAGINATION = "setAtestadosPaginationMutation",
  SET_PAGE_PAGINATION_ATESTADOS = "setPageAtestadosMutation",
  SET_ATESTADO = "setAtestadoMutation", 
  FILTER_ATESTADO = "filterAtestadoMutation",
  
  SET_DERIVACION_CM = "setDerivacionCmMutation",
  SET_GRUPOCM = "setGrupoCMMutation",
  FILTER_DERIVACIONES_CM = "filterDerivacionesCmMutation",
  SET_DERIVACIONES_CM_PAGINATION = "setDerivacionesCmPaginationMutation",
  CLEAR_DERIVACIONES_CM_FILTERS = "clearDerivacionesCmMutation",
  SET_PAGE_PAGINATION_DERIVACIONES_CM = "setPageDerivacionesCmMutation",

  SET_ALL_MEDICAL_CENTERS = "getAllMedicalCentersMutation",
  SET_ALL_TASKSTYPE = "SetAllTasksTypeMutation",
  SET_PAGE_PAGINATION_TASKSTYPE = "setPagePaginationTasksTypeMutation",
  SET_TASKSTYPE_PAGINATION = "setTasksTypePaginationMutation",
  SET_TASKSTYPE = "setTasksTypeMutation",
  CLEAR_TASKSTYPE_FILTERS = "clearTasksTypeFiltersMutation",
  SEARCH_TASKSTYPE ="searchTasksTypeMutation",
  FILTER_TASKSTYPE ="filterTasksTypeMutation",
  SET_TASKSTYPE_BY_ID = "setTasksTypeByIdMutation",

  SET_ALL_ACTIONSTYPE = "SetAllActionsTypeMutation",
  SET_PAGE_PAGINATION_ACTIONSTYPE = "setPagePaginationActionsTypeMutation",
  SET_ACTIONSTYPE_PAGINATION = "setActionsTypePaginationMutation",
  SET_ACTIONSTYPE = "setActionsTypeMutation",
  CLEAR_ACTIONSTYPE_FILTERS = "clearActionsTypeFiltersMutation",
  SEARCH_ACTIONSTYPE ="searchActionsTypeMutation",
  FILTER_ACTIONSTYPE ="filterActionsTypeMutation",
  SET_ACTIONSTYPE_BY_ID = "setActionsTypeByIdMutation",


  SET_CONTACTS_TO_DOWNLOAD="setContactsToDownloadMutation",

  SET_ALL_TASKTYPES = "setAllTaskTypesMutation",
  SET_ALL_ACTIONSTYPES = "setAllActionsTypesMutation",
  FILTER_AUDITS = "filterAuditsMutation",
  CLEAR_AUDITS_FILTERS = "clearAudtisFiltersMutation",
  SET_PAGE_PAGINATION_AUDITS = "setPagePaginationAuditsMutation",
  SET_AUDITS_PAGINATION = "setAuditsPaginationMutation", 
  CLEAR_AUDIT_FILTERS = "clearAuditsFiltersMutation",
  FILTER_BILLINGB2B = "filterBillingB2BMutation",
  SET_COMPANIES = "setCompaniesMutation",
  SET_B2BBILLING_ITEMS_BY_PARTNER = "setb2bBillingItemsPartnerPagination",
  SET_B2BBILLING_ITEMS_BY_MEDICAL_CENTER = "setb2bBillingItemsMedicalCenterPagination",
  SET_PAGE_PAGINATIONS_PARTNER = "setPagePartnerExpedientesMutation",
  SET_PAGE_PAGINATIONS_MEDICAL_CENTER = "setPageMedicalCenterExpedientesMutation",
  SET_PAGE_PAGINATIONS_COLLABORATOR ="setPageCollaboratorExpedientesMutation",
  SET_CUSTOMERFILES_PARTNER_PAGINATION = "setCustomerFilesPartnerPaginationMutation",
  SET_CUSTOMERFILES_MEDICAL_CENTER_PAGINATION = "setCustomerFilesMedicalCenterPaginationMutation",
  SET_CUSTOMERFILES_COLLABORATOR_PAGINATION = "setCustomerFilesCollaboratorPaginationMutation",
  FILTER_CUSTOMERFILES_PARTNER = "setCustomerFilesFilterPartnerMutation",
  FILTER_CUSTOMERFILES_MEDICAL_CENTER = "setCustomerFilesFilterMedicalCenterMutation",
  FILTER_CUSTOMERFILES_COLLABORATOR = "setCustomerFilesFilterCollaboratorMutation",
  // mutation types
  SET_PARTNER_USERS = "setPartnerUsersMutation",
  SET_MEDICAL_CENTER_USERS = "setMedicalCenterUsersMutation",

  SET_ALL_TAGS = "SetAllTagsMutation",
  SET_DOCUMENTS_COUNT = "SetDocumentsCountMutation",
  SET_BILLING_BY_CUSTOMERFILE_ID = "setBillingItemMutation",
  SET_TASKS_PAGINATION = "setTasksPaginationMutation",
  SET_PAGE_PAGINATION_TASKS = "setPagePaginationTasksMutation",
  SEARCH_TASKS = "searchTasksMutation",
  FILTER_TASKS = "filterTasksMutation",
  FILTER_TASKS_BY_ID = "filterTasksByIdMutation",
  CLEAR_TASKS_FILTERS = "clearTasksFilterMutation",
  SET_TASK_WIDGET = "setTaksWidgetMutation",
  SET_COLLABORATORS_TYPE = "setCollaboratorTypeMutation",
  SET_COLABORATORS_TYPE_PAGINATION = "setColaboratorsPaginationMutation",
  SET_COLABORATORS_TYPE = "setColaboratorsMutation",
  SET_COLLABORATOR_TYPESNAMES = "setCollaboratorTypesnamesMutation", 
  SET_ACTIONSTYPES = "setActionsTypesMutation", 
  SET_HISTORY = "setHistoryMutation", 
  SET_ALL_PARTNERS = "getAllPartnersMutation",
  SET_ALL_STATUSES = "getAllStatusesMutation",
  SET_HISTORY_BY_LEAD = "setHistoryByLeadMutation", 
  EDITTING_PERMISSIONS = "edittingPermissionsMutations",
    FILTER_FEED_ITEMS_BY_ID = "filterFeedItemsByIdMutation",
  CLEAR_ROLES = "deletePermissionsMutations",
  CLONE_PERMISSIONS = "clonePermissionsMutation",
  CLEAR_DEPARTMENTS_FILTERS = "clearDepartmentsFiltersMutation",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_CONTACTS = "setContactsMutation",
  SET_CLIENTS = "setClientsMutation",
  SET_CONTACTS_BY_MANAGERCTI = "setContactsManagerctiMutation",
  SET_CUSTOMERFILES_BY_OWNER = "setCustomerfilesOwnerMutation",
  SET_PAGE_PAGINATION_CONTACTS = "setPagePaginationContactsMutation",
  SET_PAGE_PAGINATION_CLIENTS = "setPagePaginationClientsMutation",
  SET_PAGE_PAGINATION_USERS = "setPagePaginationUsersMutation",
  SET_PAGE_PAGINATION_CUSTOMERS = "setPagePaginationCustomersMutation",
  SET_PAGE_PAGINATION_COLLABORATORS = "setPagePaginationCollaboratorsMutation",
  SET_PAGE_PAGINATION_BLOCKEDIPS = "setPagePaginationBlockedIpsMutation",
    SET_PAGE_PAGINATION_TAGS = "setPagePaginationTagsMutation",
    SET_PAGE_PAGINATION_SERVICE_TYPES = "setPagePaginationServiceTypesMutation",
    SET_PAGE_PAGINATION_ROLES = "setPagePaginationRolesMutation",
    SET_PAGE_PAGINATION_STATUSES = "setPagePaginationStatusesMutation",
  SET_PAGE_PAGINATION_MEDICAL_CENTERS = "setPagePaginationMedicalCentersMutation",
  SET_PAGE_PAGINATION_PARTNERS = "setPagePaginationPartnersMutation",
  SET_CONTACTS_PAGINATION = "setContactsPaginationMutation",
  SET_CLIENTS_PAGINATION = "setClientsPaginationMutation",
  SET_USERS_PAGINATION = "setUsersPaginationMutation",
  SET_CUSTOMERS_PAGINATION = "setCustomersPaginationMutation",
  SET_COLLABORATORS_PAGINATION = "setCollaboratorsPaginationMutation",
  SET_BLOCKEDIPS_PAGINATION = "setBlockedIpsPaginationMutation",
    SET_TAGS_PAGINATION = "setTagsPaginationMutation",
    SET_SERVICE_TYPES_PAGINATION = "setServiceTypesPaginationMutation",
    SET_STATUSES_PAGINATION = "setStatusesPaginationMutation",
    SET_ROLES_PAGINATION = "setRolesPaginationMutation",

  SET_PARTNERS_PAGINATION = "setPartnersPaginationMutation",
  SET_DEPARTMENTS_PAGINATION = "setDepartmentsPaginationMutation",
  SET_MEDICAL_CENTERS_PAGINATION = "setMedicalCentersPaginationMutation",
  SET_DOCUMENTS = "setDocumentsMutation",
  SET_CUSTOMER = "setCustomerMutation",
  SET_CUSTOMERFILE = "setCustomerFileMutation",
  SET_LEAD = "setLeadMutation",
  SET_ASSOCIATEDS = "setAssociatedsMutation",
  SET_INTERN_USERS = "setInternUsersMutation",
  SET_USERS = "setUsersMutation",
  SET_CUSTOMERS = "setCustomersMutation",
  SET_FEED_ITEMS = "setFeedItemsMutation",
  SET_PARTNERS = "setPartnersMutation",
  SET_COLLABORATORS = "setCollaboratorsMutation",
  SET_AUTORIDADES = "setAutoridadesMutation",
  SET_COLLABORATORS_COMPANIES = "setCollaboratorsCompaniesMutation",
  SET_BLOCKEDIPS = "setBlockedIpsMutation",
  SET_SERVICE_TYPES = "setServiceTypesMutation",
    SET_TAGS = "setTagsMutation",
    SET_STATUSES = "setStatusesMutation",
  SET_MEDICAL_CENTERS = "setMedicalCentersMutation",
  SET_DEPARTMENTS = "setDepartmentsMutation",
  SET_ROLES = "setRolesMutation",
  SET_PERMISSIONS = "setPermissionsMutation",
  SET_PAYMENTS = "setPaymentsMutation",
  SET_TASKTYPES = "setTaskTypesMutation",
  SET_NOTIFICATIONS = "setNotificationsMutation",
  SET_BILLING_ITEMS = "setBillingItemsMutation",
  SET_PAGE_PAGINATIONS_BILLING_ITEMS = "setPagePaginationsBillingItemsMutation",
  SET_PAGE_PAGINATIONS_BILLING_ITEMSB2B = "setPagePaginationsBillingItemsB2BMutation",
  SET_BILLING_CLIENTS = "setBillingUsersMutation",
  CLEAR_CONTACTS_FILTERS = "clearContactsFiltersMutation",
  CLEAR_CLIENTS_FILTERS = "clearClientsFiltersMutation",
  CLEAR_USERS_FILTERS = "clearUsersFiltersMutation",
  CLEAR_SERVICE_TYPES_FILTERS = "clearServiceTypesFiltersMutation",
  CLEAR_CUSTOMERS_FILTERS = "clearCustomersFiltersMutation",
  CLEAR_PARTNERS_FILTERS = "clearPartnersFiltersMutation",
  CLEAR_COLLABORATORS_FILTERS = "clearCollaboratorsFiltersMutation",
  CLEAR_BLOCKEDIPS_FILTERS = "clearBlockedIpsFiltersMutation",
  CLEAR_ROLES_FILTERS = "clearRolesFiltersMutation",

    CLEAR_TAGS_FILTERS = "clearTagsFiltersMutation",
    CLEAR_STATUSES_FILTERS = "clearStatusesFiltersMutation",
    CLEAR_BILLING_FILTERS = "clearBillingFiltersMutation",
  CLEAR_MEDICAL_CENTERS_FILTERS = "clearMedicalCentersFiltersMutation",
  DELETE_USER = "deleteUser",
  DELETE_CUSTOMER = "deleteCustomer",
  DELETE_SCRAPYARD = "deleteCollaborator",
  DELETE_BLOCKEDIP = "deleteBlockedIp",
    DELETE_TAG = "deleteTag",
    DELETE_ROL = "deleteRol",
    DELETE_STATUS = "deleteStatus",
  DELETE_MEDICAL_CENTER = "deleteMedicalCenter",
  SEARCH_USERS ="searchUsersMutation",
  SEARCH_CUSTOMERS ="searchCustomersMutation",
  SEARCH_COLLABORATORS ="searchCollaboratorsMutation",
  SEARCH_BLOCKEDIPS ="searchBlockedIpsMutation",
  SEARCH_SERVICE_TYPES ="searchServiceTypesMutation",
    SEARCH_TAGS ="searchTagsMutation",
    SEARCH_STATUSES ="searchStatusesMutation",
  SEARCH_MEDICAL_CENTERS ="searchMedicalCentersMutation",
  FILTER_USERS ="filterUsersMutation",
  FILTER_CONTACTS ="filterContactsMutation",
  FILTER_CUSTOMERS ="filterCustomersMutation",
  FILTER_BILLING ="filterBillingMutation",
  FILTER_CUSTOMERFILES ="filterCustomersMutation",
  FILTER_PARTNERS ="filterPartnersMutation",
  FILTER_SERVICE_TYPES ="filterServiceTypesMutation",
  FILTER_COLLABORATORS ="filterCollaboratorsMutation",
  FILTER_BLOCKEDIPS ="filterBlockedIpsMutation",
    FILTER_TAGS ="filterTagsMutation",
    FILTER_STATUSES ="filterStatusesMutation",
  FILTER_MEDICAL_CENTERS ="filterMedicalCentersMutation",
  SET_USER_BY_ID ="setUserByIdMutation",
  SET_CUSTOMER_BY_ID ="setCustomerByIdMutation",
  SET_PARTNER_BY_ID ="setPartnerByIdMutation",
  SET_SCRAPYARD_BY_ID ="setCollaboratorByIdMutation",
  SET_SERVICE_TYPE_BY_ID ="setServiceTypeByIdMutation",
    SET_TAG_BY_ID ="setTagByIdMutation",
    SET_STATUS_BY_ID ="setStatusByIdMutation",
  SET_MEDICAL_CENTER_BY_ID ="setMedicalCenterByIdMutation",
  SET_LOADING_TRUE_USERS ="setLoadingTrueUsersMutation",
  SET_LOADING_TRUE_CONTACTS ="setLoadingTrueContactsMutation",
  SET_LOADING_TRUE_CUSTOMERS ="setLoadingTrueCustomersMutation",
  SET_LOADING_TRUE_CUSTOMERFILES ="setLoadingTrueCustomerfilesMutation",
  SET_LOADING_TRUE_PARTNERS ="setLoadingTruePartnersMutation",
  SET_CUSTOMERFILES = "setCustomerFilesMutation",
  SET_CUSTOMERFILES_PAGINATION = "setCustomerFilesPaginationMutation",
  SET_TASKS_LEAD_BY_ID ="setTasksLeadByIdMutation",
  SET_TASKS_CUSTOMERFILE_BY_ID ="setTasksCustomerFileByIdMutation",
  SET_TASKS ="setTasksMutation",
}

export { Actions, Mutations };
