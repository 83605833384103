import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class ColaboratorsTypeModule extends VuexModule {
    
    colaboratorsType = {
        pagination: [] || null,
        loading: true,
        error: null,
      };

      colaboratorsFiltersType = {
        search: "",
        page: 1,
        searchString: "",
        limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
      };
  
      
    get getColaboratorsType() {
        return this.colaboratorsType;
    }


    @Mutation
    [Mutations.SET_COLABORATORS_TYPE](payload: any) {
        this.colaboratorsType = payload;
    }

    @Mutation
    [Mutations.SET_COLABORATORS_TYPE_PAGINATION]({pagination, loading, error}) {
      // //console.log('departments mutation',payload);
      this.colaboratorsType = {
        pagination: pagination,
        loading,
        error
      };
    }

    @Action
    [Actions.GET_COLABORATORS_TYPE_BY_ID](id): void {
    ApiService.get(`collaboratortypes/${id}`).then((response) => {
        this.context.commit(Mutations.SET_COLABORATORS_TYPE, response.data);
    });
    }

    @Action
    [Actions.GET_COLABORATORS_TYPE_WITH_PAGINATION](): any {
     // //console.log(this.tagsFilters.searchString)
     return ApiService.get(
        `collaboratortypes?page=${this.colaboratorsFiltersType.page}&search=${this.colaboratorsFiltersType.search}${this.colaboratorsFiltersType.searchString}`
      )
        .then(({ data }) => {
          // //console.log(data);
          //this.context.commit(Mutations.SET_TAGS, data)
          this.context.commit(Mutations.SET_COLABORATORS_TYPE_PAGINATION, {
            pagination: data,
            loading: false,
            error: null,
          })
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    }

}