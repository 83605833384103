import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class DepartmentsModule extends VuexModule {


  departmentsFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  departmentsPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };


  departments = null

  /* --- GETTERS --- */

  get getDepartmentsPagination() {
    // //console.log("getter 2", this.tagsPagination)
    return this.departmentsPagination;
}
 
  get getDepartments() {
    // //console.log("getter departments", this.departments)
    return this.departments;
}

get getDepartmentsFilters() {
return this.departmentsFilters;
}

get getPageDepartments() {
return this.departmentsFilters.page;
}


// get getDepartmentsFilters() {
//   return this.departmentsFilters;
// }

  /* --- MUTATIONS --- */
  //setDepartmentsPaginationMutation
  
  @Mutation
  [Mutations.SET_DEPARTMENTS](payload) {
    // //console.log('departments mutation',payload);
    this.departments = payload;
  }


  @Mutation
  [Mutations.SET_DEPARTMENTS_PAGINATION]({pagination, loading, error}) {
    // //console.log('departments mutation',payload);
    this.departmentsPagination = {
      pagination: pagination,
      loading,
      error
    };
  }

  @Mutation
  [Mutations.CLEAR_DEPARTMENTS_FILTERS](payload) {
    this.departmentsFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Action
  [Actions.GET_ALL_DEPARTMENTS]() {
    // //console.log(this.tagsFilters.searchString)
    return ApiService.get(
      `alldepartments`
    )
      .then(({ data }) => {
        // //console.log(data);
        this.context.commit(Mutations.SET_DEPARTMENTS, {data})
        // this.context.commit(Mutations.SET_DEPARTMENTS_PAGINATION, {
        //   pagination: data,
        //   loading: false,
        //   error: null,
        // })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_DEPARTMENTS_WITH_PAGINATION]() {
    // //console.log(this.tagsFilters.searchString)
    return ApiService.get(
      `departments?page=${this.departmentsFilters.page}&search=${this.departmentsFilters.search}${this.departmentsFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
     //   this.context.commit(Mutations.SET_DEPARTMENTS, data)
        this.context.commit(Mutations.SET_DEPARTMENTS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  
  @Action
  [Actions.CLEAR_DEPARTMENTS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_DEPARTMENTS_FILTERS, payload);
    this.context.dispatch(Actions.GET_DEPARTMENTS_WITH_PAGINATION);
  }
  



}