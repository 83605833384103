import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


@Module
export default class FeedModule extends VuexModule {

  feedItems = [];
  tasks = [];
  allTasks = [];
  allActionsTypes = [];
  actionsTypes = [];
  tasksWidgets = [];

  /* --- GETTERS --- */

  tasksPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  tasksFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  tasksFiltersById = {
    // search: "",
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  actuationsFiltersById = {
    // search: "",
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  get getTasksWidgets(){
    return this.tasksWidgets;
  }

  get getPageTasks() {
    return this.tasksFilters.page;
  }

  get getTasksPagination() {
    // //console.log("getter 2", this.medicalCentersPagination)
    return this.tasksPagination;
  }

  get getTasksFilters() {
    return this.tasksFilters;
  }

  get getTasksFiltersById() {
    return this.tasksFiltersById;
  }

  get getActuationsFiltersById() {
    return this.actuationsFiltersById;
  }


  get getFeedItems(): any {
    return this.feedItems;
  }

  get getTasks(): any {
    ////console.log('tasls',this.tasks)
    return this.tasks;
  }

  get getAllTasks(): any {
    ////console.log('tasls',this.tasks)
    return this.allTasks;
  }

  get getAllActionsTypes(): any {
    ////console.log('tasls',this.tasks)
    return this.allActionsTypes;
  }

  get getActionsTypes(): any {
    ////console.log('tasls',this.actionsTypes)
    return this.actionsTypes;
  }


  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_TASKS_PAGINATION](payload) {
    // //console.log('mutation_pagination',payload);
    this.tasksPagination = payload;
  }

  @Mutation
  [Mutations.SET_PAGE_PAGINATION_TASKS](payload) {
    this.tasksFilters.page = payload;
  }

  @Mutation
  [Mutations.SEARCH_TASKS]({payload, pagination, loading, error}) {
    //console.log('mutation',payload);
    //console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((tasks: any) => tasks.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.tasksPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_TASKS](payload) {
    //console.log('FILTERmutation',payload);
    this.tasksFilters.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_TASKS_BY_ID](payload) {
    //console.log('FILTERmutation',payload);
    this.tasksFiltersById.searchString = payload
  }

  @Mutation
  [Mutations.FILTER_FEED_ITEMS_BY_ID](payload) {
    //console.log('FILTERmutation',payload);
    this.actuationsFiltersById.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_TASKS_FILTERS](payload) {
    this.tasksFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }

  @Mutation
  [Mutations.SET_FEED_ITEMS](feedItems: any) {
    this.feedItems = feedItems;
  }

  @Action
  [Actions.CLEAR_TASKS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_TASKS_FILTERS, payload);
  }

  @Action
  [Actions.GET_FEED_ITEMS_BY_ID](id): void {
    ApiService.get(`feed/${id}?search=${this.actuationsFiltersById.searchString}`).then((response) => {
      this.context.commit(Mutations.SET_FEED_ITEMS, response.data);
    });
  }

  @Action({ rawError: true })
  [Actions.FILTER_FEED_ITEMS_BY_ID](payload) {
    this.context.commit(Mutations.FILTER_FEED_ITEMS_BY_ID, payload.string)
    this.context.dispatch(Actions.GET_FEED_ITEMS_BY_ID, payload.id)
    

  }


  @Action
  [Actions.GET_TASK_WIDGET](): void{
    ApiService.get( `task/widget`).then((response) => {
      this.context.commit(Mutations.SET_TASK_WIDGET, response.data);
    });
  }

  @Mutation
  [Mutations.SET_TASK_WIDGET](tasks: any) {
    this.tasksWidgets = tasks;
  }

  @Mutation
  [Mutations.SET_TASKS_LEAD_BY_ID](tasks: any) {
    this.tasks = tasks;
  }

  @Mutation
  [Mutations.SET_TASKS](tasks: any) {
    this.allTasks = tasks;
  }

  @Mutation
  [Mutations.SET_ACTIONSTYPES](actionsTypes: any) {
    this.actionsTypes = actionsTypes;
  }

  @Mutation
  [Mutations.SET_ALL_ACTIONSTYPES](actionsTypes: any) {
    this.allActionsTypes = actionsTypes;
  }


  @Action
  [Actions.GET_TASKS_LEAD_BY_ID](id): void {
    ApiService.get(`tasks/leads/${id}?search=${this.tasksFiltersById.searchString}`).then((response) => {
      this.context.commit(Mutations.SET_TASKS_LEAD_BY_ID, response.data);
    });
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_TASKS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_TASKS, payload.page);
    this.context.dispatch(payload.getAction, payload.string ? payload.string : '');
  }

  @Action({ rawError: true })
  [Actions.SEARCH_TASKS](payload) {
    ApiService.get(`tasks`)
      .then(({ data }) => {
        //console.log(data);
        this.context.commit(Mutations.SEARCH_TASKS, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_TASKS](payload) {
    this.context.commit(Mutations.FILTER_TASKS, payload);
    this.context.dispatch(Actions.GET_TASKS_WITH_PAGINATION)
  }

  @Action({ rawError: true })
  [Actions.FILTER_TASKS_BY_ID](payload) {
    this.context.commit(Mutations.FILTER_TASKS_BY_ID, payload.string);

    try {
      this.context.dispatch(Actions.GET_TASKS_LEAD_BY_ID, payload.id)
    } catch (error) {
      this.context.dispatch(Actions.GET_TASKS_CUSTOMERFILE_BY_ID, payload.id)
      //console.log('it was a CUSTOMERFILE ')
    }

    
  }

  @Action
  [Actions.GET_TASKS_WITH_PAGINATION]() {
    // //console.log(this.medicalCentersFilters.searchString)
    return ApiService.get(
      `taskstable?page=${this.tasksFilters.page}&search=${this.tasksFilters.search}${this.tasksFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('dddattta',data);
        this.context.commit(Mutations.SET_TASKS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_TASKS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    this.tasksPagination.loading = true;
     return ApiService.get(
      `tasks/getallrecords?search=${this.tasksFilters.search}${this.tasksFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_TASKS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }

  @Action
  [Actions.GET_TASKS](): void {
    ApiService.get(`tasks`).then((response) => {
      this.context.commit(Mutations.SET_TASKS, response.data);
    });
  }

  @Mutation
  [Mutations.SET_TASKS_CUSTOMERFILE_BY_ID](tasks: any) {
    this.tasks = tasks;
  }

  @Action
  [Actions.GET_TASKS_CUSTOMERFILE_BY_ID](id): void {
    ApiService.get(`tasks/customerFiles/${id}?search=${this.tasksFiltersById.searchString}`).then((response) => {
      this.context.commit(Mutations.SET_TASKS_CUSTOMERFILE_BY_ID, response.data);
    });
  }

  @Action
  [Actions.GET_ACTIONSTYPES](): void {
    ApiService.get(`actionstypes`).then((response) => {
      this.context.commit(Mutations.SET_ACTIONSTYPES, response.data);
    });
  }

  @Action
  [Actions.GET_ALL_ACTIONSTYPES](): void {
    ApiService.get(`actionstypes/getallrecords`).then((response) => {
      this.context.commit(Mutations.SET_ALL_ACTIONSTYPES, response.data);
    });
  }

  @Action({ rawError: true })
  [Actions.CREATE_NEW_FEED_ITEM](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.post(`feed`, payload).then(({data}) => {
        if (data?.id) {
          this.context.dispatch(Actions.GET_FEED_ITEMS_BY_ID, payload.user);
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response }) => {
        //console.log(response)
        reject(response);
      });
    })
  }

  @Action
  [Actions.CREATE_NEW_TASK](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.post(`tasks/${payload.user}`, payload).then((response) => {
        this.context.dispatch(Actions.GET_TASKS_LEAD_BY_ID, payload.user);
        this.context.dispatch(Actions.GET_TASKS_WITH_PAGINATION);
        resolve(response);
      }).catch((error => {
        reject(error)
      }));
    })
  }


  @Action
  [Actions.CREATE_TASK_AND_ACTION_ASSOCIATEDS](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.post(`tasks/associated/${payload.user}`, payload).then((response) => {
        this.context.dispatch(Actions.GET_TASKS_LEAD_BY_ID, payload.user);
        this.context.dispatch(Actions.GET_TASKS_WITH_PAGINATION);
        resolve(response);
      }).catch((error => {
        reject(error)
      }));
    })
  }


  @Action
  [Actions.EDIT_TASK](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.put(`tasks/${payload.id}`, payload).then((response) => {
        this.context.dispatch(Actions.GET_TASKS_LEAD_BY_ID, payload.user);
        resolve(response);
      }).catch((error => {
        reject(error)
      }));
    })
  }

  @Action
  [Actions.TODO_TASK](payload): any {
    return new Promise((resolve, reject) => {
      ApiService.put(`tasks/${payload.id}`, payload).then((response) => {
        this.context.dispatch(Actions.GET_TASKS_WITH_PAGINATION, payload.user);
        resolve(response);
      }).catch((error => {
        reject(error)
      }));
    })
  }

}