import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface StoreInfo {}

@Module
export default class ServiceTypesModule extends VuexModule implements StoreInfo {

  serviceTypesPagination = {
    pagination: [] || null,
    loading: true,
    error: null,
  };

  serviceTypesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };
  
  serviceTypes = null
  serviceType = null
  
  collaboratorTypesNames = null

  /* --- GETTERS --- */


  get getServiceTypes() {
    // //console.log("getter serviceTypes", this.serviceTypes)
    return this.serviceTypes;
  }

  get getCollaboratorTypesNames() {
    // //console.log("getter collaboratorTypesNames", this.collaboratorTypesNames)
    return this.collaboratorTypesNames;
  }


  get getServiceType() {
    ////console.log("SERVICE_TYPES", this.serviceType)
    return this.serviceType;
  }

  get getServiceTypesPagination() {
      // //console.log("getter 2", this.serviceTypesPagination)
      return this.serviceTypesPagination;
  }

  get getServiceTypesFilters() {
    return this.serviceTypesFilters;
  }

  get getPageServiceTypes() {
    return this.serviceTypesFilters.page;
  }

  /* --- MUTATIONS --- */

  @Mutation
  [Mutations.SET_SERVICE_TYPES_PAGINATION]({pagination, loading, error}) {
    // //console.log('departments mutation',payload);
    this.serviceTypesPagination = {
      pagination: pagination,
      loading,
      error
    };
  }


  @Mutation
  [Mutations.SET_PAGE_PAGINATION_SERVICE_TYPES](payload) {
    this.serviceTypesFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_SERVICE_TYPES](payload) {
    ////console.log('mutation',payload);
    this.serviceTypes = payload;
  }

  @Mutation
  [Mutations.SET_COLLABORATOR_TYPESNAMES](payload) {
    ////console.log('mutation',payload);
    this.collaboratorTypesNames = payload;
  }

  @Mutation
  [Mutations.SET_SERVICE_TYPE_BY_ID](payload) {
    ////console.log('mutation',payload);
    this.serviceType = payload;
  }

  @Mutation
  [Mutations.SEARCH_SERVICE_TYPES]({payload, pagination, loading, error}) {
    ////console.log('mutation',payload);
    ////console.log('mutation',pagination);

    const filteredData = pagination?.data.filter((serviceType: any) => serviceType.name.toLowerCase().includes(payload.toLowerCase()));
    const newPagination = {
      ...pagination,
      data: filteredData,
    };

    this.serviceTypesPagination = {
      pagination: newPagination,
      loading,
      error
    };

  }

  @Mutation
  [Mutations.FILTER_SERVICE_TYPES](payload) {
    //console.log('FILTERmutation',payload);
    this.serviceTypesFilters.searchString = payload
  }

  @Mutation
  [Mutations.CLEAR_SERVICE_TYPES_FILTERS](payload) {
    this.serviceTypesFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }



  /* --- ACTIONS --- */

  @Action
  [Actions.SET_PAGE_PAGINATIONS_SERVICE_TYPES](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_SERVICE_TYPES, payload.page);
    this.context.dispatch(payload.getAction);
  }

  @Action({ rawError: true })
  [Actions.SEARCH_SERVICE_TYPES](payload) {
    ApiService.get(`servicetypes`)
      .then(({ data }) => {
        this.context.commit(Mutations.SEARCH_SERVICE_TYPES, {
          payload: payload,
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.FILTER_SERVICE_TYPES](payload) {
    this.context.commit(Mutations.FILTER_SERVICE_TYPES, payload);
    this.context.dispatch(Actions.GET_SERVICE_TYPES_WITH_PAGINATION)
  }


  @Action
  [Actions.GET_SERVICE_TYPES]() {
    // //console.log(this.serviceTypesFilters.searchString)
    return ApiService.get(
      `servicetypes`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_SERVICE_TYPES, data)
        this.context.commit(Mutations.SET_SERVICE_TYPES, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_COLLABORATOR_TYPESNAMES]() {
    // //console.log(this.serviceTypesFilters.searchString)
    return ApiService.get(
      `collaboratortypesnames`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_SERVICE_TYPES, data)
        this.context.commit(Mutations.SET_COLLABORATOR_TYPESNAMES, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_SERVICE_TYPES_WITH_PAGINATION]() {
    // //console.log(this.serviceTypesFilters.searchString)
    return ApiService.get(
      `servicetypes?page=${this.serviceTypesFilters.page}&search=${this.serviceTypesFilters.search}${this.serviceTypesFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log(data);
        //this.context.commit(Mutations.SET_SERVICE_TYPES, data)
        this.context.commit(Mutations.SET_SERVICE_TYPES_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        })
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_SERVICE_TYPE_BY_ID](payload) {
    //console.log(this.serviceTypesFilters.searchString)
    return ApiService.get(`servicetypes/${payload}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SERVICE_TYPE_BY_ID, data)
        return data
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.PUT_SERVICE_TYPE](payload) {
    const {id, serviceType} = payload;

    return ApiService.put(`servicetypes/${id}`, serviceType)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_SERVICE_TYPES_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_SERVICE_TYPE](payload) {

    return new Promise((resolve, reject) => {
      //console.log('crear servicetype', payload)
     ApiService.post(`servicetypes`, payload)
      .then(({ data }) => {
        resolve(data)
        //console.log('store gooood',data);
        this.context.dispatch(Actions.GET_SERVICE_TYPES_WITH_PAGINATION)
      })
      .catch(({ response }) => {
        reject(response.data.errors);
        //console.log('store error', response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
    });
  }

  @Action
  [Actions.DELETE_SERVICE_TYPE](payload) {
    return ApiService.delete(`servicetypes/delete/${payload}`)
      .then(() => {
        this.context.dispatch(Actions.GET_SERVICE_TYPES_WITH_PAGINATION);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CLEAR_SERVICE_TYPES_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_SERVICE_TYPES_FILTERS, payload);
    this.context.dispatch(Actions.GET_SERVICE_TYPES);
  }

}
