import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";


@Module
export default class atestadosModule extends VuexModule  {



  atestado = {}

  atestadosPagination = {
    pagination: null,
    loading: true,
    error: null,
  };


  atestadosFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };

  atestadosFilesFilters = {
    search: "",
    page: 1,
    searchString: "",
    limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
  };




  /* --- GETTERS --- */

  get getAtestado() {
    return this.atestado;
  }

  get getAtestados(): any {
    return this.atestadosPagination;
  }

  get getAtestadosPagination() {
    // //console.log('kk',this.clientsPagination);
    return this.atestadosPagination;
  }

  get getAtestadosFilters() {
    return this.atestadosFilters;
  }

  get getPageAtestados() {
    return this.atestadosFilters.page;
  }


  /* --- MUTATIONS --- */


  @Mutation
  [Mutations.SET_ATESTADOS_PAGINATION](payload) {
    this.atestadosPagination = payload;
  }

  @Mutation
  [Mutations.CLEAR_ATESTADOS_FILTERS](payload) {
    this.atestadosFilters = {
      search: "",
      page: 1,
      searchString: "",
      limit: parseInt(process.env.VUE_APP_PAGINATION || "20"),
    };
  }
 
  @Mutation
  [Mutations.SET_PAGE_PAGINATION_ATESTADOS](payload) {
    this.atestadosFilters.page = payload;
  }

  @Mutation
  [Mutations.SET_ATESTADO](payload) {
    this.atestado = payload
  }


  @Mutation
  [Mutations.FILTER_ATESTADO](payload) {
    // //console.log('FILTERmutation',payload);
    this.atestadosFilters.searchString = payload
  }



  @Action
  [Actions.CLEAR_ATESTADOS_FILTERS](payload) {
    this.context.commit(Mutations.CLEAR_ATESTADOS_FILTERS, payload);
  }

  @Action
  [Actions.SET_PAGE_PAGINATIONS_ATESTADOS](payload) {
    this.context.commit(Mutations.SET_PAGE_PAGINATION_ATESTADOS, payload.page);
    this.context.dispatch(payload.getAction);
    //console.log('SET_PAGE_PAGINATIONS_CLIENTS',payload.getAction)
  }

  @Action({ rawError: true })
  [Actions.FILTER_ATESTADOS](payload) {
    // this.context.commit(Mutations.SET_LOADING_TRUE);
    this.context.commit(Mutations.FILTER_ATESTADO, payload);
    this.context.dispatch(Actions.GET_ATESTADOS,payload)
  }


  /* --- ACTIONS --- */

  @Action({ rawError: true })
  [Actions.REQUEST_ATESTADO](payload) {
    const {id, tag} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.post(`/atestado`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_ATESTADOS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_ATESTADO_TAG](payload) {
    const {id, tag} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/atestados/tag/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_ATESTADOS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_IJUDICIAL](payload) {
    const {id, tag} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/atestados/ijudicial/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_ATESTADOS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.ASSIGN_DERIVACIONES_CM](payload) {
    const {id} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/medicalcenter/assign/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_DERIVACIONES_CM);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.UPDATE_DERIVACIONES_CM_GROUP](payload) {
    const {id, tag} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.put(`/medicalcenter/groupcmassign/${id}`, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_DERIVACIONES_CM);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.DELETE_ATESTADO](payload) {
    const {id} = payload;
   
    return new Promise((resolve, reject) => {
      ApiService.delete(`/atestados/delete/${id}`)
        .then(({ data }) => {
          this.context.dispatch(Actions.GET_ATESTADOS);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data.errors);
        });
    });
  }


  @Action({ rawError: true })
  [Actions.GET_ATESTADOS_TO_DOWNLOAD](payload) {
    return new Promise((resolve, reject) => {
    this.atestadosPagination.loading = true;
     return ApiService.get(
      `atestados/getallrecords?search=${this.atestadosFilesFilters.search}${this.atestadosFilters.searchString}`
    )
      .then(({ data }) => {
        // //console.log('data ', data)
        // this.context.commit(Mutations.SET_CLIENTS, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(resolve)
      });
    })
  }



  @Action({ rawError: true })
  [Actions.GET_ATESTADOS](payload) {
    this.atestadosPagination.loading = true;
    return ApiService.get(
      `atestados?limit=10&page=${this.atestadosFilters.page}&search=${this.atestadosFilters.search}${this.atestadosFilters.searchString}`
      // `customerfiles`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ATESTADOS_PAGINATION, {
          pagination: data,
          loading: false,
          error: null,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action({ rawError: true })
  [Actions.GET_ATESTADOS_BY_CUSTOMERFILE_ID](id) {
    this.atestadosPagination.loading = true;
    return new Promise((resolve, reject) => {
    return ApiService.get(
      `atestados/customerfilesid/${id}`
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ATESTADO, data);
        resolve(data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        reject(response)
      });
    })
  }

 


}
